import UserIcon from "@components/icons/UserIcon";
import useFeatureFlags from "@lib/feature/featureFlags/useFeatureFlags";
import { Box } from "@mui/material";
import palette from "@styles/palette";
import { AvatarProps } from "stream-chat-react";

export function Avatar(props: AvatarProps) {
	const blurMapPins = useFeatureFlags()?.blur_map_pins ?? false;
	const { size = 32 } = props;

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexBasis: `${size}px`,
				width: `${size}px`,
				height: `${size}px`,
				color: palette.Primary,
				background: palette.SurfaceContainerMid,
				overflow: "hidden",
				"& img": {
					filter: blurMapPins ? "blur(4px)" : undefined,
					width: "100%",
					height: "100%",
				},
			}}
			component="span"
		>
			{/* `alt` is an empty string because this is contained in a button with an aria label */}
			{/* eslint-disable-next-line @next/next/no-img-element */}
			{props.image != null ? <img src={props.image} alt="" /> : <UserIcon sx={{ height: 32, width: 32 }} />}
		</Box>
	);
}
