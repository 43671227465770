// Tested in @feature/itmc/ITMCMediaRecorder/useMediaHandler.test.tsx
import { CameraDirection } from "@lib/models";

export type RequestedMediaStream = null | MediaStream | Error;

export const UserMediaErrorMap = {
	AbortError: "video_capture.failed_to_start_camera_error",
	NotAllowedError: "video_capture.permission_denied_error",
	NotFoundError: "video_capture.no_camera_error",
	NotReadableError: "video_capture.failed_to_start_camera_error",
	NotSupportedError: "video_capture.recording_error",
	TypeError: "video_capture.failed_to_start_camera_error",
} as const;

export async function requestUserMedia(cameraDirection: CameraDirection = "user") {
	return navigator.mediaDevices.getUserMedia({
		// constraints - only video needed for this app
		video: {
			facingMode: cameraDirection,
			aspectRatio: 1,
			width: 720,
			height: 720,
		},
	});
}

export function makeMediaRecorder(
	stream: MediaStream,
	outputRef: { current: BlobEvent["data"][] },
	videoFinished: (blob: Blob) => void,
) {
	const mimeType = MediaRecorder.isTypeSupported("video/webm;codecs=vp8") ? "video/webm;codecs=vp8" : "video/mp4";

	const mediaRecorder = new MediaRecorder(stream, { mimeType });
	mediaRecorder.ondataavailable = (event) => {
		if (event.data.size > 0) {
			outputRef.current.push(event.data);
		}
	};

	mediaRecorder.onstop = () => {
		const blob = new Blob(outputRef.current, {
			type: mimeType,
		});
		videoFinished(blob);
	};
	return mediaRecorder;
}
