import { UserAccount } from "@lib/models";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "./useAuth";
import useUser from "./useUser";

export default function useAccount(): UserAccount | undefined {
	const { onRedirectToLogin } = useAuth();
	const ownUser = useUser();
	const navigate = useNavigate();
	const pathname = useLocation().pathname;

	useEffect(() => {
		if (ownUser == null) {
			onRedirectToLogin(pathname);
			navigate("/login");
		}
	}, [navigate, pathname, ownUser, onRedirectToLogin]);

	return ownUser;
}
