import CloseButton from "@components/CloseButton";
import useViewportSize from "@hooks/useViewportSize";
import { Box, Dialog } from "@mui/material";
import { ReactNode } from "react";

export default function LightboxDialog({
	renderContent,
	setOpen,
	open,
}: {
	setOpen: (val: boolean) => void;
	open: boolean;
	renderContent: (isLightbox: boolean) => ReactNode;
}) {
	const viewportSize = useViewportSize();
	const isPortrait = viewportSize === "mobile" || viewportSize === "tablet_portrait";

	return (
		<>
			{renderContent(false)}
			<Dialog
				open={open}
				className="swiper-zoom-container"
				fullScreen
				PaperProps={{
					sx: {
						background: "transparent",
						pt: "env(safe-area-inset-top, 0)",
						pb: "env(safe-area-inset-bottom, 0)",
						pl: "env(safe-area-inset-left, 0)",
						pr: "env(safe-area-inset-right, 0)",
						"& .swiper-zoom-target, .swiper-zoom-target img, .swiper-zoom-target canvas": {
							height: isPortrait ? "auto" : "100%",
						},
						"& .profile-picture-gradient-overlay": {
							display: "none",
						},
					},
				}}
			>
				<Box
					sx={{
						position: "relative",
						width: "100%",
					}}
				>
					<CloseButton
						onClick={() => {
							setOpen(false);
						}}
						sx={{
							top: "4px",
							right: "20px",
						}}
					/>
				</Box>
				{renderContent(true)}
			</Dialog>
		</>
	);
}
