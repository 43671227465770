import safeSx from "@lib/util/safeSx";
import { Delete } from "@mui/icons-material";
import { ButtonProps, IconButton } from "@mui/material";

type TrashButtonRequiredProps = {
	"aria-label": string;
	onClick: () => void;
};

export default function TrashButton({ sx, ...props }: ButtonProps & TrashButtonRequiredProps) {
	return (
		<IconButton color="secondary" size="small" {...props} sx={safeSx({ color: "common.white" }, sx)}>
			<Delete />
		</IconButton>
	);
}
