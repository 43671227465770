import useIdify from "@hooks/useIdify";
import errorHelper from "@lib/util/errorHelper";
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import palette from "@styles/palette";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { ReactNode } from "react";
import { Field } from "react-final-form";

type CheckboxFieldProps = {
	name: string;
	label: string;
	validate?: (value: boolean | undefined) => { t: string }[] | undefined;
	helperText?: string;
} & ({ helperId: string; helperElement: ReactNode } | { helperId?: never; helperElement?: never });

export default function CheckboxField({
	name,
	helperText,
	label,
	validate,
	helperElement,
	helperId,
}: CheckboxFieldProps) {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const localHelperId = idify("local_helper");

	return (
		<Field
			name={name}
			type="checkbox"
			validate={validate}
			render={({ input, meta }) => {
				const { error, helperText: errorHelperText } = errorHelper(t, helperText ?? null, meta);
				return (
					<FormControl error={error}>
						<FormControlLabel
							slotProps={{
								typography: {
									sx: {
										fontSize: "14px",
										color: palette.BKDRCoreBrandWhite,
									},
								},
							}}
							control={
								<Checkbox
									{...input}
									size="small"
									inputProps={{
										"aria-describedby": clsx(localHelperId, helperId),
									}}
								/>
							}
							label={label}
						/>
						<FormHelperText
							error={error}
							id={localHelperId}
							sx={{ color: palette.BKDRCoreBrandWhite, ml: "26px", mt: "-10px", fontSize: "12px" }}
						>
							{errorHelperText}
						</FormHelperText>
						{helperElement == null ? null : helperElement}
					</FormControl>
				);
			}}
		/>
	);
}
