import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ArrowLeftTrayIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRArrowLeftTrayIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M21.0002 0H10.0002V7.00147L17.9993 6.99906L18.0011 12.9991L10.0002 13.0015V20H21.0002V0Z"
				fill="currentColor"
			/>
			<path
				d="M0.585938 10L6.00015 4.58582L7.41436 6.00003L4.41118 9.00321L15.9999 8.99973L16.0005 10.9997L4.41755 11.0032L7.41436 14L6.00015 15.4142L0.585938 10Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
