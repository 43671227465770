import { styled } from "@mui/material";
import palette from "@styles/palette";
import { PANE_VERTICAL_SPACING } from "./PaneSection";

const Pane = styled("div")({
	width: "100%",
	maxWidth: "600px",
	backgroundColor: palette.surfaceContainer,
	position: "relative",
	display: "flex",
	flexDirection: "column",
	padding: `${PANE_VERTICAL_SPACING} 24px`,
	gap: PANE_VERTICAL_SPACING,

	"& :where(p)": {
		fontSize: "14px",
		margin: 0,
	},
});

export default Pane;
