import { LocaleCode } from "@lib/models";

export default <LocaleCode[]>[
	// prettier
	"de",
	"en",
	"es",
	"fr",
	"it",
	"nl",
	"pt",
	"vi",
	"el",
	"ru",
	"ar",
	"th",
	"ko",
	"ja",
	"zh-CN",
	"zh-TW",
];
