import { MaybeFieldErrors } from "../formErrors";

const validationExpression =
	/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

function emailValidator(value: string | undefined): MaybeFieldErrors {
	if (typeof value !== "string" || value.length === 0 || validationExpression.test(value)) {
		return undefined;
	}
	return [{ t: "email.invalid_error" }];
}

export default emailValidator;
