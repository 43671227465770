import { Button } from "@components/Button";
import AudioIcon from "@components/icons/AudioIcon";
import { useTranslation } from "next-i18next";
import React from "react";

type StartRecordingAudioButtonProps = React.ComponentProps<"button">;

export default function StartRecordingAudioButton(props: StartRecordingAudioButtonProps) {
	const { t } = useTranslation("common");
	return (
		<Button
			icon
			variant={"tertiary ghost"}
			aria-label={t("chat.audio_record_screen_reader_button")}
			type="button"
			{...props}
		>
			<AudioIcon />
		</Button>
	);
}
