import { LocaleCode } from "@lib/models";

export type DateFormat = {
	helper: string;
	mask: string;
	format: string;
};

const formats: Record<string, DateFormat> = {
	dmy_slash: { helper: "date_format.dd_mm_yyyy_slash", mask: "__/__/____", format: "dd/MM/yyyy" },
	dmy_dot: { helper: "date_format.dd_mm_yyyy_dot", mask: "__.__.____", format: "dd.MM.yyyy" },
	dmy_dash: { helper: "date_format.dd_mm_yyyy_dash", mask: "__-__-____", format: "dd-MM-yyyy" },
	ymd_slash: { helper: "date_format.yyyy_mm_dd_slash", mask: "____/__/__", format: "yyyy/MM/dd" },
	ymd_dotspace: { helper: "date_format.yyyy_mm_dd_dot_space", mask: "____. __. __.", format: "yyyy. MM. dd." },
	mdy_slash: { helper: "date_format.mm_dd_yyyy_slash", mask: "__/__/____", format: "MM/dd/yyyy" },
};

const DEFAULT_FORMAT = formats.dmy_slash;

// **READ BEFORE EDITING**
// If you add a language variant with a different date format, you must also add handling for that
// case to get country code, similar to en-US vs en-GB
const supportedLocales: Record<string, DateFormat> = {
	"ar-EG": formats.dmy_slash,
	"de-DE": formats.dmy_dot,
	"el-GR": formats.dmy_slash,
	"en-GB": formats.dmy_slash,
	"en-US": formats.mdy_slash,
	"es-ES": formats.dmy_slash,
	"es-MX": formats.dmy_slash,
	"fr-FR": formats.dmy_slash,
	"it-IT": formats.dmy_slash,
	"ja-JA": formats.ymd_slash,
	"ko-KR": formats.ymd_dotspace,
	"nl-NL": formats.dmy_dash,
	"pt-BR": formats.dmy_slash,
	"pt-PT": formats.dmy_slash,
	"ru-RU": formats.dmy_dot,
	"th-TH": formats.dmy_slash,
	"vi-VN": formats.dmy_slash,
	"zh-CN": formats.ymd_slash,
	"zh-TW": formats.ymd_slash,
};

const localeByLanguage: Record<string, DateFormat> = {
	ar: supportedLocales["ar-EG"],
	de: supportedLocales["de-DE"],
	el: supportedLocales["el-GR"],
	en: supportedLocales["en-GB"],
	es: supportedLocales["es-MX"],
	fr: supportedLocales["fr-FR"],
	it: supportedLocales["it-IT"],
	ja: supportedLocales["ja-JA"],
	ko: supportedLocales["ko-KR"],
	nl: supportedLocales["nl-NL"],
	pt: supportedLocales["pt-BR"],
	ru: supportedLocales["ru-RU"],
	th: supportedLocales["th-TH"],
	vi: supportedLocales["vi-VN"],
};

export default function getDateFormat(locale: LocaleCode): DateFormat {
	if (locale === "en") {
		if (navigator.language === "en-US") {
			return supportedLocales["en-US"];
		}
	}

	const languageAndCountry = locale.length == 2 ? locale + navigator.language.slice(2) : locale;

	if (supportedLocales[languageAndCountry] != null) {
		return supportedLocales[languageAndCountry];
	}

	if (localeByLanguage[locale] != null) {
		return localeByLanguage[locale];
	}

	return DEFAULT_FORMAT;
}
