import useRegionBlocked from "@hooks/useRegionBlocked";
import { Box, Link, Typography } from "@mui/material";
import palette from "@styles/palette";
import { Trans, useTranslation } from "next-i18next";

const perryStreetLink = [
	// eslint-disable-next-line react/jsx-key
	<Link href="https://www.perrystreet.com" />,
];
const externalLinks = [
	// it's not that kind of array
	// eslint-disable-next-line react/jsx-key
	<Link href="https://www.perrystreet.com/privacy" />,
	// eslint-disable-next-line react/jsx-key
	<Link href="https://www.perrystreet.com/tos" />,
];

export default function Footer() {
	const { t } = useTranslation("common");
	const isRegionBlocked = useRegionBlocked();

	return (
		<Box
			component={"footer"}
			sx={{
				fontSize: "11px",
				lineHeight: 1.2,
				display: "flex",
				justifyContent: "flex-end",
				alignItems: "center",
				flexDirection: "column",
				color: isRegionBlocked ? palette.onContainerTertiary : palette.Surface,
				"a, p": {
					color: "inherit",
					textDecorationColor: "currentcolor",
					fontSize: "inherit",
				},
				"a:hover, a:visited": {
					color: palette.BKDRProductFadedPink,
					textDecorationColor: "currentcolor",
				},
			}}
		>
			<Typography>
				<Trans
					t={t}
					i18nKey="landing.copyright"
					components={perryStreetLink}
					tOptions={{ year: new Date().getFullYear() }}
				/>
			</Typography>
			<Box>
				<Trans t={t} i18nKey="landing.links" components={externalLinks} />
			</Box>
		</Box>
	);
}
