import { memo } from "react";
import {
	DefaultStreamChatGenerics,
	MESSAGE_ACTIONS,
	MessageContextValue,
	ReactionIcon,
	ThreadIcon,
	showMessageActionsBox,
	useMessageContext,
	useTranslationContext,
} from "stream-chat-react";
import MessageActions from "../MessageActions";

/**
 * Code forked from https://github.com/GetStream/stream-chat-react/blob/master/src/components/Message/MessageOptions.tsx
 * Unused functionality commented out.
 * MessageActions replaced by our own menu.
 * Unused props removed.
 */

export type MessageOptionsProps<StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics> =
	Partial<Pick<MessageContextValue<StreamChatGenerics>, "handleOpenThread">>;

const displayReplies = true;
const theme = "simple";

const UnMemoizedMessageOptions = <
	StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics,
>() => {
	const {
		customMessageActions,
		getMessageActions,
		handleOpenThread: contextHandleOpenThread,
		initialMessage,
		message,
		onReactionListClick,
		showDetailedReactions,
		threadList,
	} = useMessageContext<StreamChatGenerics>("MessageOptions");

	const { t } = useTranslationContext("MessageOptions");

	const handleOpenThread = contextHandleOpenThread;

	const messageActions = getMessageActions();
	const showActionsBox = showMessageActionsBox(messageActions, threadList) || !!customMessageActions;

	const shouldShowReactions = messageActions.indexOf(MESSAGE_ACTIONS.react) > -1;
	const shouldShowReplies = messageActions.indexOf(MESSAGE_ACTIONS.reply) > -1 && displayReplies && !threadList;

	if (
		!message.type ||
		message.type === "error" ||
		message.type === "system" ||
		message.type === "ephemeral" ||
		message.status === "failed" ||
		message.status === "sending" ||
		initialMessage
	) {
		return null;
	}

	const rootClassName = `str-chat__message-${theme}__actions str-chat__message-options`;

	return (
		<div className={rootClassName} data-testid="message-options">
			{showActionsBox && <MessageActions />}
			{shouldShowReplies && (
				<button
					aria-label={t("aria/Open Thread")}
					className={`str-chat__message-${theme}__actions__action str-chat__message-${theme}__actions__action--thread str-chat__message-reply-in-thread-button`}
					data-testid="thread-action"
					onClick={handleOpenThread}
				>
					<ThreadIcon className="str-chat__message-action-icon" />
				</button>
			)}
			{shouldShowReactions && (
				<button
					aria-expanded={showDetailedReactions}
					aria-label={t("aria/Open Reaction Selector")}
					className={`str-chat__message-${theme}__actions__action str-chat__message-${theme}__actions__action--reactions str-chat__message-reactions-button`}
					data-testid="message-reaction-action"
					onClick={onReactionListClick}
				>
					<ReactionIcon className="str-chat__message-action-icon" />
				</button>
			)}
		</div>
	);
};

export const MessageOptions = memo(UnMemoizedMessageOptions);
