import vcrOsdMono from "@lib/fonts/vcrOsdMono";
import { styled } from "@mui/material";
import palette from "@styles/palette";
import { ComponentProps, ForwardedRef, forwardRef } from "react";
import { Link } from "react-router-dom";
import { SizeVariant, Variant, sizeVariants, variantStyles } from "./variants";

type BaseButtonProps = {
	variant: Variant;
	size?: SizeVariant;
	active?: boolean;
};

type IconButtonProps = {
	icon: true;
	"aria-label": string;
};

type RegularButtonProps = {
	icon?: never;
};

export type ButtonProps = BaseButtonProps & (IconButtonProps | RegularButtonProps);

const ButtonBase = forwardRef(function ButtonBase(
	props: ComponentProps<"button">,
	ref: ForwardedRef<HTMLButtonElement>,
) {
	return <button ref={ref} type="button" {...props} />;
});

function shouldForwardProp(prop: string) {
	return !["active", "icon", "variant", "size", "sx", "theme", "as", "ownerState"].includes(prop);
}

export const Button = styled(ButtonBase, { shouldForwardProp })<ButtonProps>(({
	theme,
	icon,
	size = "md",
	variant,
	active = false,
}) => {
	const variantStyle = variantStyles[variant];
	const sizeStyle = sizeVariants[size];

	const { backgroundColor, borderColor, color, hoverBG, hoverBorderColor, hoverColor } = active
		? variantStyle.active
		: variantStyle;
	return {
		backgroundColor: backgroundColor,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: theme.spacing(sizeStyle.gap),
		border: "none",
		height: icon ? theme.spacing(sizeStyle.iconButtonSize) : theme.spacing(sizeStyle.height),
		width: icon ? theme.spacing(sizeStyle.iconButtonSize) : undefined,
		padding: icon ? 0 : theme.spacing(0, sizeStyle.paddingX),
		color: color,
		borderRadius: "0px",
		boxShadow: borderColor === "none" ? "none" : `inset 0 0 0px 1px ${borderColor}`,
		fontSize: sizeStyle.fontSize,
		cursor: "pointer",
		textDecoration: "none",
		fontFamily: vcrOsdMono.style.fontFamily,

		svg: {
			display: "block",
			height: theme.spacing(icon ? sizeStyle.iconButtonIconSize : sizeStyle.iconSize),
			width: theme.spacing(icon ? sizeStyle.iconButtonIconSize : sizeStyle.iconSize),
		},

		"&:hover, &:focus": {
			backgroundColor: hoverBG,
			boxShadow: hoverBorderColor === "none" ? "none" : `inset 0 0 0px 1px ${hoverBorderColor}`,
			color: hoverColor,
		},

		"&:disabled": {
			opacity: 0.4,
			backgroundColor: palette.surfaceContainer60,
			color: variantStyle.disabledColor,
			boxShadow: variantStyle.disabledBorder ? `inset 0 0 0px 1px ${variantStyle.disabledColor}` : "none",
		},
	};
});

export const LinkButton = Button.withComponent(Link);
