import { UserMediaErrorMap } from "@feature/itmc/stages/ITMCMediaRecorder/mediaDevices";
import useAsyncInstance from "@hooks/useAsyncInstance";
import assertType from "@lib/util/assertType";
import reportError from "@lib/util/reportError";
import { useTranslation } from "next-i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import useEventCallback from "use-event-callback";

export class MediaRecorderError extends Error {
	event?: Event;
}
export class MediaSetupError extends Error {}

export default function useMediaHandler(
	cameraDirection: "user" | "environment",
	recording: boolean,
	onVideoFinished: (file: Blob) => void,
	onError: (error: Error) => void,
) {
	const { t } = useTranslation("common");
	const videoRef = useRef<HTMLVideoElement>();
	const onVideoFinishedStable = useEventCallback(onVideoFinished);
	const onErrorStable = useEventCallback(onError);

	const [recordReadyObject, setRecordReadyObject] = useState<{
		mediaRecorder: MediaRecorder;
	} | null>(null);

	const outputRef = useRef<BlobEvent["data"][]>([]);

	const makeMediaInstance = useCallback(async () => {
		const stream = await navigator.mediaDevices.getUserMedia(
			// constraints - only video needed for this app
			{
				video: {
					facingMode: cameraDirection,
					aspectRatio: 1,
					width: 720,
					height: 720,
				},
			},
		);

		const videoEl = assertType(videoRef.current);
		videoEl.srcObject = stream;
		videoEl.onloadedmetadata = () => {
			videoEl.play();
		};

		const mimeType = MediaRecorder.isTypeSupported("video/webm;codecs=vp8") ? "video/webm;codecs=vp8" : "video/mp4";

		const mediaRecorder = new MediaRecorder(stream, { mimeType });
		mediaRecorder.ondataavailable = (event) => {
			if (event.data.size > 0) {
				outputRef.current.push(event.data);
			}
		};
		mediaRecorder.onstop = () => {
			const blob = new Blob(outputRef.current, {
				type: mimeType,
			});
			onVideoFinishedStable(blob);
		};
		setRecordReadyObject({
			mediaRecorder,
		});

		return async () => {
			videoEl.srcObject = null;
			mediaRecorder.onstop = null;
			outputRef.current = [];
			mediaRecorder.stop();
			stream.getTracks().forEach((track) => {
				track.stop();
			});
			setRecordReadyObject(null);
		};
	}, [onVideoFinishedStable, cameraDirection]);

	const { error } = useAsyncInstance(makeMediaInstance);

	useEffect(() => {
		if (error) {
			const canHandle = error.name in UserMediaErrorMap;
			const errorMessage = canHandle
				? t(UserMediaErrorMap[error.name as keyof typeof UserMediaErrorMap])
				: t("unknown_error");
			onErrorStable(new MediaSetupError(errorMessage));
			if (!canHandle || error.name === "NotSupportedError") {
				// eslint-disable-next-line no-console
				console.error(error);
				reportError(error);
			}
		}
	}, [t, error, onErrorStable]);

	useEffect(() => {
		if (recordReadyObject != null && recording) {
			outputRef.current = [];
			recordReadyObject.mediaRecorder.start();
		} else if (recordReadyObject != null) {
			recordReadyObject.mediaRecorder.stop();
		}
	}, [recordReadyObject, recording]);

	return videoRef;
}
