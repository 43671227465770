import useFeatureFlags from "@feature/featureFlags/useFeatureFlags";
import useUser from "./useUser";

export function useRegionBlockedFlag() {
	return useFeatureFlags()?.region_blocked ?? false;
}

export default function useRegionBlocked() {
	const isAdmin = useUser()?.is_admin ?? false;
	// allowed here because it's a subset of the check we should use
	// eslint-disable-next-line no-restricted-syntax
	const regionBlocked = useRegionBlockedFlag();
	return regionBlocked === true && isAdmin === false;
}
