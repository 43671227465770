import DialogButton from "@components/DialogButton";
import useUser from "@hooks/useUser";
import SnackbarProvider from "@lib/feature/alert/SnackbarProvider";
import InTheMoment from "@lib/feature/inTheMoment";
import { track } from "@lib/util/trackAnalytics";
import Bolt from "@mui/icons-material/Bolt";
import Edit from "@mui/icons-material/Edit";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "next-i18next";
import { Link } from "react-router-dom";

export default function UserBottomActions() {
	const { t } = useTranslation("common");
	const user = useUser();
	const hasITMC = user?.in_the_moment_status != null;
	const labelTitle = hasITMC ? t("in_the_moment_view_button_label") : t("in_the_moment_button_label");

	return (
		<>
			<SnackbarProvider>
				<DialogButton
					renderDialog={(open, onClose) => {
						if (open) {
							track("itm_button_tapped", { source: "profile" });
						}
						return <InTheMoment open={open} onClose={onClose} />;
					}}
					dialogRoutingName="right-now"
					renderButton={(onOpen) => {
						return (
							<Tooltip arrow placement="left" title={labelTitle}>
								<IconButton
									type="button"
									onClick={onOpen}
									color="secondary"
									aria-label={labelTitle}
									sx={{
										marginRight: 1,
										p: 0,
										borderRadius: "50%",
										height: (theme) => theme.spacing(5),
										width: (theme) => theme.spacing(5),
									}}
								>
									<Bolt
										fontSize="medium"
										sx={{
											color: "common.black",
										}}
									/>
								</IconButton>
							</Tooltip>
						);
					}}
				/>
			</SnackbarProvider>
			{user?.is_anon ? null : (
				<Button
					sx={{ borderRadius: 5 }}
					variant="contained"
					size="medium"
					component={Link}
					to="/account/profile"
					startIcon={<Edit />}
				>
					{t("edit_button")}
				</Button>
			)}
		</>
	);
}
