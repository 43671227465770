import { blockedUsersPath, getBlockedUsers } from "@api/profile";
import Alert from "@components/Alert";
import { Button } from "@components/Button";
import Dialog from "@components/Dialog";
import DialogButton from "@components/DialogButton";
import useGlobalLoader from "@hooks/useGlobalLoader";
import { Card, CardContent, CardHeader, DialogActions } from "@mui/material";
import { useTranslation } from "next-i18next";
import useSWRImmutable from "swr/immutable";
import BlockedUsers from "./BlockedUsers";

export default function BlockManager() {
	const { t } = useTranslation("common");

	const {
		data: blockedUsers,
		error,
		isValidating,
		mutate,
	} = useSWRImmutable(blockedUsersPath, getBlockedUsers, {
		revalidateOnMount: true,
	});
	useGlobalLoader(isValidating, "BlockManager loading");

	return (
		<Card variant="outlined" sx={(theme) => ({ marginTop: theme.spacing(3), flexShrink: 0 })}>
			<CardHeader
				sx={{ pb: 0 }}
				title={t("account_settings.manage_blocks_title")}
				titleTypographyProps={{ component: "h2" }}
			/>
			<CardContent>
				{error != null ? (
					<Alert severity="error" body={t("unknown_error")} />
				) : blockedUsers?.length === 0 ? (
					<Button variant="primary" disabled sx={{ width: "100%" }}>
						{t("account_settings.no_blocks_label")}
					</Button>
				) : (
					<DialogButton
						dialogRoutingName="settings-manage-blocks"
						onClose={() => {
							mutate();
						}}
						renderDialog={(open, handleClose) => {
							return (
								<Dialog open={open} onClose={handleClose} title={t("account_settings.block_manager_title")}>
									<BlockedUsers blockedUsers={blockedUsers ?? []} />
									<DialogActions>
										<Button variant="primary" onClick={handleClose}>
											{t("done_button")}
										</Button>
									</DialogActions>
								</Dialog>
							);
						}}
						renderButton={(onOpen) => (
							<Button variant="primary" onClick={onOpen} sx={{ width: "100%" }}>
								{t("account_settings.view_list_button")}
							</Button>
						)}
					/>
				)}
			</CardContent>
		</Card>
	);
}
