import Landing from "@feature/landing";
import useAuth from "@hooks/useAuth";
import useRegionBlocked from "@hooks/useRegionBlocked";
import useUser from "@hooks/useUser";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

// TODO these routes are replaced by loaders once we have global auth loading

export function LandingRoute() {
	const { isUser } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		if (isUser) {
			navigate("/map", { replace: true });
		}
	}, [isUser, navigate]);

	if (isUser) {
		return null;
	}

	return <Landing />;
}

export function RequireUser() {
	const authUser = useUser();
	const navigate = useNavigate();

	useEffect(() => {
		if (authUser == null) {
			navigate("/", { replace: true });
		}
	}, [authUser, navigate]);

	if (authUser == null) {
		return null;
	}
	return <Outlet />;
}

export function RequireNonAnon() {
	const authUser = useUser();
	const navigate = useNavigate();

	useEffect(() => {
		if (authUser?.is_anon) {
			navigate("/map", { replace: true });
		}
	}, [authUser?.is_anon, navigate]);

	if (authUser?.is_anon) {
		return null;
	}
	return <Outlet />;
}

export function RequireNoUser() {
	const authUser = useUser();
	const navigate = useNavigate();

	useEffect(() => {
		if (authUser != null) {
			navigate("/map", { replace: true });
		}
	}, [authUser, navigate]);

	if (authUser != null) {
		return null;
	}
	return <Outlet />;
}

type RequireNotRegionBlockedProps = { route: string };

export function RequireNotRegionBlocked({ route }: RequireNotRegionBlockedProps) {
	const isRegionBlocked = useRegionBlocked();
	const navigate = useNavigate();

	useEffect(() => {
		if (isRegionBlocked) {
			navigate(route, { replace: true });
		}
	}, [isRegionBlocked, navigate, route]);

	if (isRegionBlocked) {
		return null;
	}

	return <Outlet />;
}
