import { chatFriendPath, getChatFriend } from "@api/users";
import useAccount from "@hooks/useAccount";
import { OtherUser } from "@lib/models";
import assertType from "@lib/util/assertType";
import { ReactElement, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

export default function withChatFriend(Component: {
	(props: { channelId: string; friend: OtherUser }): ReactElement;
	displayName?: string;
}): {
	(): ReactElement | null;
	displayName?: string;
} {
	return function WithChatFriend() {
		const { uuid } = useParams();
		const navigate = useNavigate();
		const user = assertType(useAccount());
		const isSelf = user.uuid === uuid;
		const { data, error } = useSWR(isSelf ? null : (`${chatFriendPath}/${uuid}` as string), getChatFriend);

		useEffect(() => {
			if (error != null || isSelf) {
				navigate("/chat", { replace: true });
			}
		}, [error, navigate, isSelf]);

		if (data == null || error != null) {
			return null;
		}
		const p = { channelId: data.channel_id, friend: data.user };
		return <Component {...p} />;
	};
}
