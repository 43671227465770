import makeAction from "@lib/util/frp/makeAction";
import reportError from "@lib/util/reportError";
import updateState from "./util/updateState";

const [openItmc, openActionSubject] = makeAction<"text" | "media">();

export { openItmc };

export const openActionObservable = openActionSubject.pipe(
	updateState((type, state) => {
		if (state.view !== "closed") {
			reportError(new Error("Tried to open ITMC flow while already open!"));
			return state;
		}
		return {
			...state,
			view: type === "text" ? "text input" : "media recorder",
		};
	}),
);
