import makeAction from "@lib/util/frp/makeAction";
import { Observable } from "rxjs";
import { Alert } from "./types";

const [setGlobalAlert, globalAlertActionSubject] = makeAction<Alert | void>();

export { setGlobalAlert };

// prefer to expose non-Subject Observable type, and this will also likely
// have additional handling when alerts are moved to a model as well.
export const globalAlertActionObervable: Observable<Alert | void> = globalAlertActionSubject;
