import MultiSelectField from "@components/MultiSelectField";
import { MultiSelectConfig } from "@components/MultiSelectField/configTypes";
import renderValueDefaultChips from "@components/MultiSelectField/renderValueDefaultChips";
import { Divider } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Field } from "react-final-form";
import options from "../options.json";

export default function VaccinationsField() {
	const { t } = useTranslation("common");
	const config = useMemo((): MultiSelectConfig => {
		return {
			label: t("profile.vaccinations_field_label"),
			renderValue: renderValueDefaultChips,
			options: Object.fromEntries(options.vaccinations.map((v) => [v, t(`vaccinations.${v}`)])),
			disableFilter: true,
		};
	}, [t]);
	return (
		<Field<string[]>
			name="vaccinations"
			render={({ input, meta }) => {
				return (
					<>
						<MultiSelectField input={input} meta={meta} config={config} />
						<Divider />
					</>
				);
			}}
		/>
	);
}
