import { SvgIcon, SvgIconProps } from "@mui/material";

export default function GearIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRGearIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.7209 0H8.27936L7.46088 2.45543L5.14629 1.2979L1.29851 5.14568L2.45604 7.46074L0 8.27935V13.721L2.45598 14.5394L1.29863 16.8541L5.14633 20.7018L7.46083 19.5447L8.27926 22H13.7208L14.5392 19.5446L16.854 20.7021L20.7018 16.8544L19.5444 14.5395L22.0002 13.721V8.27952L19.5454 7.46106L20.703 5.14575L16.8553 1.29806L14.5395 2.45577L13.7209 0ZM11 15C13.2091 15 15 13.2091 15 11C15 8.79086 13.2091 7 11 7C8.79086 7 7 8.79086 7 11C7 13.2091 8.79086 15 11 15Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
