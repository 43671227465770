import { Button } from "@components/Button";
import CheckSquareIcon from "@components/icons/CheckSquareIcon";
import { DialogActions, Link, Typography } from "@mui/material";
import palette from "@styles/palette";
import { Trans, useTranslation } from "next-i18next";

const externalLinks = [
	// it's not that kind of array
	// eslint-disable-next-line react/jsx-key
	<Link href="https://www.perrystreet.com/privacy" />,
	// eslint-disable-next-line react/jsx-key
	<Link href="https://www.perrystreet.com/tos" />,
];

type ModalContentProps = {
	onClose: () => void;
};

export default function ModalContent({ onClose }: ModalContentProps) {
	const { t } = useTranslation("common");

	return (
		<>
			<Typography
				sx={{
					fontSize: "14px",
				}}
			>
				{t("privacy_check.dialog_body")}
			</Typography>
			<Typography sx={{ fontSize: "13px", color: palette.Primary }}>
				<Trans t={t} i18nKey="landing.links" components={externalLinks} />
			</Typography>
			<DialogActions>
				<Button size="md" variant="primary" onClick={onClose}>
					<CheckSquareIcon sx={{ color: palette.onPrimary }} />
					{t("accept_button")}
				</Button>
			</DialogActions>
		</>
	);
}
