import Alert from "@components/Alert";
import { RenderableAlertType } from "@lib/feature/alert/types";
import safeSx from "@lib/util/safeSx";
import { Box, Card, CardProps } from "@mui/material";
import { ElementType } from "react";

type FormCardProps = {
	alert?: RenderableAlertType | null;
};

export default function FormCard<C extends ElementType>(props: CardProps<C, { component?: C }> & FormCardProps) {
	const { alert, sx, ...cardProps } = props;
	return (
		<Box
			sx={{
				maxWidth: "480px",
				width: "100%",
				mx: "auto",
				minHeight: "100%",
				pt: "var(--safe-top)",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				alignItems: "stretch",
				position: "relative",
			}}
		>
			<Box
				sx={{
					position: "absolute",
					top: "80px",
					left: "20px",
					right: "20px",
					display: "flex",
					flexDirection: "column",
				}}
			>
				{alert == null ? null : <Alert key={alert.key} severity={alert.severity} body={alert.children} />}
			</Box>
			<Card
				{...cardProps}
				component={cardProps.component}
				sx={safeSx(
					{
						mx: 2.5,
						my: "auto",
						display: "flex",
						flexDirection: "column",
						alignItems: "stretch",
						overflow: "visible",
					},
					sx,
				)}
				variant="outlined"
			>
				{cardProps.children}
			</Card>
		</Box>
	);
}
