import FieldSubmitError from "@components/FieldSubmitError";
import useIdify from "@hooks/useIdify";
import errorHelper from "@lib/util/errorHelper";
import { requiredStringValidator } from "@lib/util/validation/requiredValidators";
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from "@mui/material";
import { useTranslation } from "next-i18next";
import { Field } from "react-final-form";

export default function DisplayNameField() {
	const { t } = useTranslation("common");
	const idify = useIdify();

	return (
		<Field
			name="display_name"
			type="text"
			validate={requiredStringValidator}
			render={({ input, meta }) => {
				const { error, helperText } = errorHelper(t, t("profile.display_name_field_helper"), meta);
				const inputId = idify("profile_display_name");
				const helperId = idify("profile_display_name_helper");
				const submitErrorId = idify("profile_display_name_submit_error");
				return (
					<FormControl variant="outlined" fullWidth error={error}>
						<InputLabel required variant="outlined" htmlFor={inputId}>
							{t("profile.display_name_field_label")}
						</InputLabel>
						<OutlinedInput
							autoComplete="name"
							id={inputId}
							aria-describedby={`${helperId} ${submitErrorId}`}
							required
							label={t("profile.display_name_field_label")}
							error={error}
							inputProps={{
								maxLength: "30",
							}}
							{...input}
						/>
						<FormHelperText id={helperId}>{helperText}</FormHelperText>
						<FieldSubmitError id={submitErrorId} meta={meta} />
					</FormControl>
				);
			}}
		/>
	);
}
