import reportError from "./reportError";
export class LocalStorageError extends Error {}

function makeLocalStorageHelper<T>(localStorageKey: string) {
	const localStorageHelperFactoryObject = {
		set(value: T) {
			try {
				window.localStorage.setItem(localStorageKey, JSON.stringify(value));
			} catch (error) {
				reportError(new LocalStorageError(`localStorage set error: ${localStorageKey}: '${error}'`));
			}
		},
		get() {
			const stringyValue = window.localStorage.getItem(localStorageKey);
			if (stringyValue == null) {
				return null;
			}
			try {
				return JSON.parse(stringyValue);
			} catch (error) {
				reportError(new LocalStorageError(`localStorage parse error: ${localStorageKey}: '${error}'`));
				localStorageHelperFactoryObject.remove();
				return null;
			}
		},
		remove() {
			window.localStorage.removeItem(localStorageKey);
		},
	};
	return localStorageHelperFactoryObject;
}

export default makeLocalStorageHelper;
