import MessageSquareLinesIcon from "@components/icons/MessageSquareLinesIcon";
import { Box, Typography } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";

export default function EmptyList() {
	const { t } = useTranslation("common");
	return (
		<Box
			sx={(theme) => ({
				height: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				gap: "12px",
				backgroundColor: theme.palette.background.default,
			})}
		>
			{
				<>
					<MessageSquareLinesIcon
						sx={(theme) => ({
							color: palette.Primary,
							width: theme.spacing(6.5),
							height: theme.spacing(6.5),
						})}
					/>
					<Typography component="p" variant="body1">
						{t("chat.empty_conversation_list_message")}
					</Typography>
					<Typography component="p" variant="body1">
						{t("feed.no_content_body")}
					</Typography>
				</>
			}
		</Box>
	);
}
