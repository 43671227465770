import Alert from "@components/Alert";
import { Button } from "@components/Button";
import Pane from "@components/Pane";
import PaneHeading from "@components/Pane/PaneHeading";
import { PaneSection } from "@components/Pane/PaneSection";
import useAlertState from "@lib/feature/alert/useAlertState";
import { UploadPhotoDataType } from "@lib/models";
import { Checkbox, DialogActions, FormControlLabel } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import useProfilePhotoUpload from "../useProfilePhotoUpload";

type SaveViewProps = {
	cropData: UploadPhotoDataType;
	onClose: () => void;
	hasPinnedPhoto: boolean;
};

export default function SaveView({ cropData, onClose, hasPinnedPhoto }: SaveViewProps) {
	const { t } = useTranslation("common");
	const [alert, updateAlert] = useAlertState();
	const [shouldPin, setShouldPin] = useState<boolean>(!hasPinnedPhoto);
	const { saving, handleSubmit } = useProfilePhotoUpload(updateAlert);

	return (
		<Pane
			sx={{
				alignSelf: "center",
			}}
		>
			<PaneHeading title={t("profile.save_step_heading")} />
			<PaneSection>
				<FormControlLabel
					control={<Checkbox checked={shouldPin} onChange={(_, checked) => setShouldPin(checked)} />}
					label={t("profile.pin_this_photo_checkbox")}
				/>
				<DialogActions>
					<Button
						variant="primary"
						disabled={saving}
						onClick={async () => {
							const error = await handleSubmit({ ...cropData, pinned: shouldPin });
							if (error == null) {
								onClose();
							}
						}}
					>
						{t("profile.save_photo_button")}
					</Button>
				</DialogActions>
				{alert == null ? null : <Alert key={alert.key} severity={alert.severity} body={alert.children} />}
			</PaneSection>
		</Pane>
	);
}
