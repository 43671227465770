import { SvgIcon, SvgIconProps } from "@mui/material";

export default function VideoIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRVideoIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1 5H17V8.75896L23 5.25896V18.741L17 15.241V19H1V5Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
