import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ArrowRightLeftIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRArrowRightLeftIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M14.9998 2.15625L18.6783 5.83476L14.9998 9.51327L13.8213 8.33476L15.488 6.6681H1.66647L1.66647 5.00143H15.488L13.8213 3.33476L14.9998 2.15625Z"
				fill="currentColor"
			/>
			<path
				d="M6.17831 11.6681L4.51164 13.3348L18.3331 13.3348V15.0014L4.51164 15.0014L6.17831 16.6681L4.9998 17.8466L1.32129 14.1681L4.9998 10.4896L6.17831 11.6681Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
