import CameraIcon from "@components/icons/CameraIcon";
import VideoIcon from "@components/icons/VideoIcon";
import vcrOsdMono from "@lib/fonts/vcrOsdMono";
import { MediaType } from "@lib/models";
import { Box, LinearProgress, Typography } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";

type ITMCProcessingMediaProps = {
	mediaType: Omit<MediaType, "text">;
};

export default function ITMCProcessingMedia({ mediaType }: ITMCProcessingMediaProps) {
	const { t } = useTranslation("common");

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				alignItems: "center",
				margin: "0 auto 8px",
				bgcolor: palette.SurfaceContainerMid,
				width: "100%",
				aspectRatio: "1 / 1",
			}}
		>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					alignItems: "center",
				}}
			>
				<LinearProgress
					sx={{
						width: "100%",
						backgroundColor: palette.PrimaryOpacity25,
						"& > .MuiLinearProgress-bar": { backgroundColor: palette.Primary },
					}}
				/>
			</Box>
			<Box
				sx={{
					textAlign: "center",
					flex: "1 1 0",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{mediaType === "video" ? (
					<VideoIcon fontSize="large" sx={{ color: palette.Primary }} />
				) : (
					<CameraIcon fontSize="large" sx={{ color: palette.Primary }} />
				)}
				<Typography sx={{ fontFamily: vcrOsdMono.style.fontFamily, textAlign: "center", fontSize: "20px" }}>
					{mediaType === "video"
						? t("in_the_moment.video_processing_placeholder")
						: t("in_the_moment.photo_processing_placeholder")}
				</Typography>
			</Box>
		</Box>
	);
}
