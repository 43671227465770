import DotsVerticalIcon from "@components/icons/DotsVerticalIcon";
import EyeSlashIcon from "@components/icons/EyeSlashIcon";
import { track } from "@lib/util/trackAnalytics";
import { IconButton, Menu, MenuItem } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { useRef, useState } from "react";

type ChannelPreviewMoreMenuProps = {
	hideChannel: () => void;
};

export default function ChatPreviewMoreMenu({ hideChannel }: ChannelPreviewMoreMenuProps) {
	const { t } = useTranslation("common");
	const anchorEl = useRef<HTMLButtonElement | null>(null);
	const [menuOpen, setMenuOpen] = useState(false);

	function handleOpenMenu() {
		setMenuOpen(true);
		track("chat_channel_preview_more_menu_clicked");
	}

	function handleClose() {
		setMenuOpen(false);
	}

	function handleHideChannel() {
		hideChannel();
		handleClose();
	}

	return (
		<>
			<IconButton
				aria-label={t("more_button")}
				size="small"
				onClick={handleOpenMenu}
				ref={anchorEl}
				sx={{ marginLeft: "auto", borderRadius: "4px" }}
			>
				{<DotsVerticalIcon sx={(theme) => ({ width: theme.spacing(2.5), height: theme.spacing(2.5) })} />}
			</IconButton>
			<Menu
				anchorEl={anchorEl.current}
				open={menuOpen}
				onClose={handleClose}
				onClick={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
			>
				<MenuItem onClick={handleHideChannel}>
					{
						<EyeSlashIcon
							sx={(theme) => ({ color: palette.Destructive, width: theme.spacing(2), height: theme.spacing(2) })}
						/>
					}
					{t("chat.hide_button")}
				</MenuItem>
			</Menu>
		</>
	);
}
