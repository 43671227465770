import { locationObservable } from "@feature/locationModel";
import { Location } from "@lib/models";
import { BehaviorSubject, distinctUntilChanged, of, switchMap } from "rxjs";

export const exploreLocationOverrideBehavior = new BehaviorSubject<null | Location>(null);

export function setExploreLocationOverride(location: null | Location) {
	exploreLocationOverrideBehavior.next(location);
}

// if an admin becomes a non-admin, this can get stuck with a set value.
// we are ok with this for now, they can refresh the page to get back to a reasonable state.

export const exploreLocationObservable = exploreLocationOverrideBehavior
	.pipe(
		switchMap((override) => {
			if (override == null) {
				return locationObservable;
			}
			return of(override);
		}),
	)
	.pipe(
		distinctUntilChanged((prev: Location | null, current: Location | null) => {
			if (current === prev) {
				return true;
			}
			if (current === null || prev === null) {
				return false;
			}
			return prev.lng === current.lng && prev.lat === current.lat;
		}),
	);
