import safeSx from "@lib/util/safeSx";
import { Link, LinkProps } from "@mui/material";
import { useTranslation } from "next-i18next";
import ArrowUpRightIcon from "./icons/ArrowUpRightIcon";

// NOT A GENERAL PURPOSE EXTERNAL LINK COMPONENT

/*
This component is specifically for injecting an external link into translation text. It only supports string
children for the links, and *must not* be passed non-string children, as the string is used in an
aria-label for screen reader support.

Refer to usage in:
- lib/auth/Signup/AcceptLegalField.tsx
- lib/components/MultiSelectField/SuggestionLink.ts
*/

export default function TranslationExternalLink({ children, sx, ...props }: LinkProps & { children?: string }) {
	const { t } = useTranslation("common");
	return (
		<Link
			{...props}
			sx={safeSx(sx, { display: "inline-block" })}
			target="_blank"
			aria-label={`${children}, ${t("new_tab_link_screen_reader_text")}`}
		>
			{children}{" "}
			{
				<ArrowUpRightIcon
					sx={(theme) => ({ verticalAlign: "text-bottom", width: theme.spacing(2), height: theme.spacing(2) })}
				/>
			}
		</Link>
	);
}
