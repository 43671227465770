import assertType from "@lib/util/assertType";
import isIOSNativeWrapper from "@lib/util/isIOSNativeWrapper";
import reportError from "@lib/util/reportError";
import { track } from "@lib/util/trackAnalytics";
import { UserLocationState } from ".";
const userLocationEvent = "user-location-event";

const initialPositionOptions = {
	maximumAge: 60000, // allowed position cache time in ms. 1 minute
	enableHighAccuracy: false, // false for initial request
	timeout: 60000, // allowed getCurrentPosition time out in ms. 1 minute
};

const continuousPositionOptions = {
	maximumAge: 30000, // allowed position cache time in ms. 30 seconds
	enableHighAccuracy: true,
};

const UserLocation: {
	position: GeolocationPosition | null;
	status: UserLocationState;
	permissionsArePrompted: boolean;
	watchId: number | null;
	watchers: Set<Record<string, never>>;
	permissionStateSubscribed: boolean;
	emitEvent: () => void;
	getStatus: () => UserLocationState;
	setStatus: (status: UserLocationState) => void;
	getPosition: () => GeolocationPosition | null;
	setPosition: (position: GeolocationPosition | null) => void;
	getPermissionsArePrompted: () => boolean;
	setPermissionsArePrompted: (value?: boolean) => void;
	subscribe: (callback: () => void) => () => void;
	oniOSNativeWrapperDidUpdateLocation: (event: CustomEvent<GeolocationPosition>) => void;
	watchPosition: () => () => void;
	onLocation: (position: GeolocationPosition) => void;
	onLocationError: (error: GeolocationPositionError) => void;
	getCurrentPosition: () => void;
	reset: () => void;
	subscribeToPermissionState: () => void;
} = {
	position: null,
	status: "firstRender",
	permissionsArePrompted: false,
	watchId: null,
	watchers: new Set(),
	permissionStateSubscribed: false,
	emitEvent() {
		document.dispatchEvent(new Event(userLocationEvent));
	},
	getStatus() {
		return UserLocation.status;
	},
	setStatus(status: UserLocationState) {
		UserLocation.status = status;
		UserLocation.emitEvent();
	},
	getPosition() {
		return UserLocation.position;
	},
	setPosition(position: GeolocationPosition | null) {
		UserLocation.position = position;
		UserLocation.emitEvent();
	},
	getPermissionsArePrompted() {
		return UserLocation.permissionsArePrompted;
	},
	setPermissionsArePrompted(value = true) {
		UserLocation.permissionsArePrompted = value;
	},
	subscribe(callback: () => void) {
		document.addEventListener(userLocationEvent, callback);
		return () => {
			document.removeEventListener(userLocationEvent, callback);
		};
	},
	watchPosition() {
		const instance = {};
		if (UserLocation.watchers.size === 0) {
			if (isIOSNativeWrapper()) {
				window.webkit?.messageHandlers.startUpdatingPosition.postMessage([]);
				document.addEventListener(
					"ios-native-wrapper.location-update",
					UserLocation.oniOSNativeWrapperDidUpdateLocation,
				);
				UserLocation.watchId = Math.random();
			} else {
				UserLocation.watchId = navigator.geolocation.watchPosition(
					UserLocation.onLocation,
					UserLocation.onLocationError,
					continuousPositionOptions,
				);
			}
		}
		UserLocation.watchers.add(instance);
		return () => {
			UserLocation.watchers.delete(instance);
			if (UserLocation.watchers.size === 0 && UserLocation.watchId != null) {
				if (isIOSNativeWrapper()) {
					window.webkit?.messageHandlers.stopUpdatingPosition.postMessage([]);
					document.removeEventListener(
						"ios-native-wrapper.location-update",
						UserLocation.oniOSNativeWrapperDidUpdateLocation,
					);
				} else {
					navigator.geolocation.clearWatch(UserLocation.watchId);
				}
				UserLocation.watchId = null;
			}
		};
	},
	onLocation(position: GeolocationPosition) {
		UserLocation.setPosition(position);
		UserLocation.setStatus("success");
		if (UserLocation.permissionsArePrompted) {
			track("location_enabled");
			UserLocation.setPermissionsArePrompted(false);
		}
	},
	onLocationError(error: GeolocationPositionError) {
		if (error.code === GeolocationPositionError.PERMISSION_DENIED) {
			UserLocation.setStatus("denied");
			if (UserLocation.permissionsArePrompted) {
				track("location_disabled");
			}
		} else {
			UserLocation.setStatus("failed");
			track("location_error_received", { error: error.message });
		}
		UserLocation.setPermissionsArePrompted(false);
	},
	getCurrentPosition() {
		UserLocation.setStatus("querying");

		if (isIOSNativeWrapper()) {
			window.webkit?.messageHandlers.getCurrentPosition
				.postMessage([])
				.then((position) => {
					UserLocation.onLocation(position);
				})
				.catch((error) => {
					UserLocation.onLocationError({
						code: GeolocationPositionError.POSITION_UNAVAILABLE,
						message: error,
						PERMISSION_DENIED: 1,
						POSITION_UNAVAILABLE: 2,
						TIMEOUT: 3,
					});
				});
		} else {
			navigator.geolocation.getCurrentPosition(
				UserLocation.onLocation,
				UserLocation.onLocationError,
				initialPositionOptions,
			);
		}
	},
	subscribeToPermissionState() {
		if (UserLocation.permissionStateSubscribed) {
			return;
		}
		if (isIOSNativeWrapper()) {
			window.webkit?.messageHandlers.requestPermission
				.postMessage([])
				.then((authorizationStatus) => {
					if (UserLocation.permissionStateSubscribed) {
						return;
					}
					if (authorizationStatus === "granted") {
						UserLocation.setStatus("resolvingGrant");
					}
					UserLocation.permissionStateSubscribed = true;
				})
				.catch((error) => {
					reportError(error);
				});
		} else if ("geolocation" in navigator) {
			if ("permissions" in navigator) {
				navigator.permissions
					.query({ name: "geolocation" })
					.then((permissionStatus) => {
						if (UserLocation.permissionStateSubscribed) {
							return;
						}
						permissionStatus.onchange = () => {
							if (permissionStatus.state === "granted") {
								UserLocation.setStatus("resolvingGrant");
							}
						};
						UserLocation.permissionStateSubscribed = true;
					})
					.catch((error) => {
						reportError(error);
					});
			}
		}
	},
	oniOSNativeWrapperDidUpdateLocation(event) {
		UserLocation.onLocation(event.detail);
	},
	reset() {
		UserLocation.setStatus("firstRender");
		UserLocation.setPosition(null);
		UserLocation.setPermissionsArePrompted(false);
		UserLocation.watchers = new Set();
		UserLocation.permissionStateSubscribed = false;
		if (UserLocation.watchId != null) {
			if (isIOSNativeWrapper()) {
				window.webkit?.messageHandlers.stopUpdatingPosition.postMessage([]);
				document.removeEventListener(
					"ios-native-wrapper.location-update",
					UserLocation.oniOSNativeWrapperDidUpdateLocation,
				);
			} else {
				navigator.geolocation.clearWatch(assertType(UserLocation.watchId));
			}
			UserLocation.watchId = null;
		}
	},
};

export default UserLocation;
