import AppLayout from "@components/AppLayout";
import PresenceBadge from "@components/PresenceBadge";
import ProfileAvatar from "@components/ProfileAvatar";
import FriendProfileDialog from "@components/ProfileDialog/FriendProfileDialog";
import PlusSquareIcon from "@components/icons/PlusSquareIcon";
import useAsyncInstance from "@hooks/useAsyncInstance";
import useDisplayName from "@hooks/useDisplayName";
import useGlobalLoader from "@hooks/useGlobalLoader";
import { OtherUser } from "@lib/models";
import { getOnlineStatus } from "@lib/util/getOnlineStatus";
import reportError from "@lib/util/reportError";
import { track } from "@lib/util/trackAnalytics";
import { Box, IconButton } from "@mui/material";
import palette from "@styles/palette";
import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Channel as StreamChannel } from "stream-chat";
import {
	Channel,
	DateSeparatorProps,
	DateSeparator as DefaultDateSeparator,
	MessageInput,
	MessageList,
	useChatContext,
} from "stream-chat-react";
import ChatHeader from "./ChatHeader";
import { MessageOptions } from "./streamOverride/MessageOptions";
import SendButton from "./streamOverride/SendButton";
import StartRecordingAudioButton from "./streamOverride/StartRecordingAudioButton";
import withChatFriend from "./withChatFriend";

// "reply" is for threads. "quote" is for replies. Found in stream's source code.
const allowedMessageActions = ["edit", "delete", "quote" /* "flag", "mute", "react", "reply" */];

function DateSeparator(props: DateSeparatorProps) {
	return <DefaultDateSeparator {...props} position="center" />;
}

function EmptyComponent() {
	return null;
}

type ChatChannelProps = {
	channelId: string;
	friend: OtherUser;
};

function ChatChannel({ channelId, friend }: ChatChannelProps) {
	const { client: chatClient } = useChatContext();
	const navigate = useNavigate();
	const displayName = useDisplayName(friend);
	const [searchParam] = useSearchParams();
	const noBack = searchParam.has("from_notification");

	const { instance: channel, error: channelError } = useAsyncInstance<StreamChannel>(
		useCallback(
			async (setInstance) => {
				const channel = chatClient.channel("messaging", channelId);
				await channel.watch({ presence: true });
				setInstance(channel);
				return async () => {
					try {
						await channel.stopWatching();
					} catch (
						// Error can really be any shape.
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						error: any
					) {
						if (error.message === "You can't use a channel after client.disconnect() was called") {
							// Ignoring a disconnect error. Timing issue due to useAsyncInstance happening after
							// a parent's useEffect cleanup.
							return;
						}
						if (error.code === 17) {
							// Ignore permissions issues when stopping watching.
							return;
						}
						reportError(error);
					}
				};
			},
			[chatClient, channelId],
		),
	);

	useGlobalLoader(channel == null, "ChatChannel channel");

	useEffect(() => {
		if (channel == null) {
			return;
		}
		channel.on((event) => {
			if (event.type !== "message.new" || event.user?.id == null || friend.uuid === event.user.id) {
				return;
			}
			track("chat_sent", {
				uuid: friend.uuid,
				is_breadcrumb: friend.is_breadcrumb,
				is_match: friend.is_match,
				online_status: getOnlineStatus(friend),
				profile_picture: friend.photos.length > 0,
				ITM_photo: friend?.in_the_moment_status?.full != null,
				has_pinned_photo: friend.photos.some((photo) => photo.pinned),
			});
		});
	}, [channel, friend]);

	useEffect(() => {
		if (channelError != null) {
			navigate("/chat", { replace: true });
		}
	}, [navigate, channelError]);

	return (
		<AppLayout
			homeButtonTarget={noBack ? "map" : "back"}
			noBottomNavigation
			toolbarHead={
				<Box sx={(theme) => ({ display: "flex", gap: theme.spacing(1.5) })}>
					<FriendProfileDialog
						renderOpener={(onOpen) => (
							<IconButton
								sx={{
									p: 0,
								}}
								aria-label={displayName}
								onClick={onOpen}
							>
								<PresenceBadge
									account={friend}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "right",
									}}
								>
									<ProfileAvatar
										variant="square"
										account={friend}
										sx={{
											width: "44px",
											height: "44px",
											background: palette.SurfaceContainerMid,
											"& .MuiSvgIcon-root": {
												height: "24px",
												width: "24px",
											},
										}}
									/>
								</PresenceBadge>
							</IconButton>
						)}
						profile={friend}
						hideChatButton={true}
						onBlock={() => {
							navigate("/chat", { replace: true });
						}}
						source="chat"
					/>
					<ChatHeader friend={friend} />
				</Box>
			}
		>
			{channel == null ? null : (
				<Box
					sx={{
						height: "100%",
						overflow: "hidden",
						".str-chat-channel": {
							height: "100%",
							overflow: "auto",
						},
						".str-chat__container": {
							display: "flex",
							flex: "1 1",
							flexDirection: "column",
							height: "100%",
							minWidth: "250px",
							width: "100%",
						},
						"& .str-chat__message .str-chat__message-inner .str-chat__message-options": {
							alignItems: "center",
							"& .str-chat__message-actions-box-button": {
								px: 0,
							},
						},
						"& .str-chat__message-attachment__voice-recording-widget__title": {
							display: "none",
						},
						"& .str-chat__message-attachment__voice-recording-widget__right-section": {
							display: "none",
						},
						"& .str-chat__attachment-list .str-chat__message-attachment__voice-recording-widget": {
							gridTemplateColumns: "36px auto 0px",
						},
					}}
				>
					<Channel
						Avatar={EmptyComponent}
						channel={channel}
						DateSeparator={DateSeparator}
						MessageOptions={MessageOptions}
						SendButton={SendButton}
						FileUploadIcon={PlusSquareIcon}
						StartRecordingAudioButton={StartRecordingAudioButton}
					>
						<MessageList messageActions={allowedMessageActions} />
						<MessageInput audioRecordingEnabled grow />
					</Channel>
				</Box>
			)}
		</AppLayout>
	);
}

export default withChatFriend(ChatChannel);
