import InformationPane from "@components/InformationPane";
import Link from "@components/Link";
import GlobeIcon from "@components/icons/GlobeIcon";
import useLocale from "@hooks/useLocale";
import getSupportLinks from "@lib/util/getSupportLinks";
import { Box, Typography } from "@mui/material";
import palette from "@styles/palette";
import { Trans, useTranslation } from "next-i18next";

export default function RegionBlockedMapView() {
	const { t } = useTranslation("common");
	const locale = useLocale();
	const externalLink = getSupportLinks("availability", locale);

	return (
		<Box
			sx={{
				position: "absolute",
				inset: 0,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<InformationPane
				Icon={GlobeIcon}
				title={t("region_blocked_map_pane.title")}
				subtitle={
					<>
						<Typography sx={{ textAlign: "center", fontSize: "16px" }}>
							{t("region_blocked_map_pane.subtitle")}
						</Typography>
						<Typography sx={{ textAlign: "center", fontSize: "16px" }}>
							<Trans
								t={t}
								i18nKey={"region_blocked_map_pane.learn_more_link"}
								components={[
									<Link
										to={externalLink}
										sx={{
											color: palette.onSurface,
											textDecoration: `underline ${palette.onSurface}`,
										}}
										target="_blank"
										key="0"
									/>,
								]}
							/>
						</Typography>
					</>
				}
			/>
		</Box>
	);
}
