import { deleteInTheMomentStatus } from "@api/profile";
import { Button } from "@components/Button";
import Dialog from "@components/Dialog";
import { refreshOwnUser } from "@feature/accountRegisterModel";
import { useUpdateAlert } from "@feature/alert/notificationContext";
import { removeFeedItem } from "@feature/Feed/feedModel";
import useGlobalLoader from "@hooks/useGlobalLoader";
import useUser from "@hooks/useUser";
import { DialogActions } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { useState } from "react";

type ITMCDeleteConfirmationDialogProps = {
	open: boolean;
	onClose: () => void;
	onDelete: () => void;
};

export default function ITMCDeleteConfirmationDialog({ open, onClose, onDelete }: ITMCDeleteConfirmationDialogProps) {
	const { t } = useTranslation("common");
	const ownUser = useUser();
	const updateAlert = useUpdateAlert();
	const [deleting, setDeleting] = useState(false);
	useGlobalLoader(deleting, "deleting itmc");

	if (ownUser == null) {
		return null;
	}

	return (
		<Dialog open={open} onClose={onClose} title={t("in_the_moment.destructive_confirmation_dialog_header")}>
			<p>{t("in_the_moment.delete_confirmation_dialog_body")}</p>
			<DialogActions>
				<Button
					variant="tertiary"
					onClick={onClose}
					sx={{
						backgroundcolor: palette.onSecondary,
						boxShadow: "none",
					}}
				>
					{t("cancel_button")}
				</Button>
				<Button
					variant="primary"
					onClick={async () => {
						try {
							setDeleting(true);
							await deleteInTheMomentStatus();
							await refreshOwnUser();
							removeFeedItem(ownUser.uuid);
							updateAlert(t("in_the_moment.update_deleted_title"), "success");
						} catch {
							updateAlert(t("unknown_error"));
						} finally {
							setDeleting(false);
							onDelete();
						}
					}}
				>
					{t("in_the_moment.delete_confirmation_button")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
