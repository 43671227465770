import { SvgIcon, SvgIconProps } from "@mui/material";

export default function UserPlusIcon(props: SvgIconProps) {
	return (
		<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" data-testid="BKDRUserPlusIcon" {...props}>
			<path
				d="M5 5C5 2.23858 7.23858 0 10 0C12.7614 0 15 2.23858 15 5C15 7.76142 12.7614 10 10 10C7.23858 10 5 7.76142 5 5Z"
				fill="currentColor"
			/>
			<path
				d="M0 21C0 15.4772 4.47715 11 10 11C11.4222 11 12.7751 11.2969 14 11.8321V15H11V21H14V22H0V21Z"
				fill="currentColor"
			/>
			<path d="M18 14V17H21V19H18V22H16V19H13V17H16V14H18Z" fill="currentColor" />
		</SvgIcon>
	);
}
