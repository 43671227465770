import useIsImperial from "@hooks/useIsImperial";
import useLocation, { UserLocationState } from "@hooks/useLocation";
import useRegionBlocked from "@hooks/useRegionBlocked";
import useUser from "@hooks/useUser";
import { getMap } from "@lib/feature/map/mapbox";
import mapConfig from "@lib/feature/map/mapbox/mapConfig";
import { AnyAccount, OtherUser, UserAccount, UserShort } from "@lib/models";
import assertType from "@lib/util/assertType";
import { track } from "@lib/util/trackAnalytics";
import { Box } from "@mui/material";
import palette from "@styles/palette";
import distanceFrom from "distance-from";
import { Trans, useTranslation } from "next-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "./Button";
import LocationPinIcon from "./icons/LocationPinIcon";

type DistanceProps = {
	account: AnyAccount | UserShort;
};

type GetDistance = {
	positionStatus: UserLocationState;
	ownUser: UserAccount | undefined;
	account: AnyAccount | UserShort;
	position: GeolocationPosition | null;
	isImperial: boolean;
};

function isOtherAccount(account: AnyAccount | UserShort, ownUser: UserAccount): account is OtherUser {
	return account.uuid !== ownUser.uuid;
}

function isVisibleOnMap(account: UserShort | AnyAccount) {
	return account.is_active || account.is_breadcrumb;
}

function getDistance({ positionStatus, ownUser, account, position, isImperial }: GetDistance) {
	if (
		positionStatus !== "success" ||
		ownUser == null ||
		!isOtherAccount(account, ownUser) ||
		account.lat == null ||
		account.lng == null
	) {
		return null;
	}

	// assertType call here is assured by positionStatus === "success"
	const nonNullPosition = assertType(position);
	const userPosition: [number, number] = [nonNullPosition.coords.latitude, nonNullPosition.coords.longitude];
	return (
		Math.round(
			distanceFrom(userPosition)
				.to([account.lat, account.lng])
				.in(isImperial ? "miles" : "kilometers") * 10,
		) / 10
	);
}

export default function Distance({ account }: DistanceProps) {
	const navigate = useNavigate();
	const { t } = useTranslation("common");
	const regionBlocked = useRegionBlocked();
	const [positionStatus, position] = useLocation();
	const isImperial = useIsImperial();
	const ownUser = useUser();
	const distance = getDistance({ positionStatus, position, ownUser, account, isImperial });
	if (distance == null) {
		return null;
	}

	const goToLocation = (() => {
		const map = getMap();
		const { lng, lat } = account;
		if (regionBlocked || map == null || lng == null || lat == null) {
			return null;
		}
		return () => {
			track("user_location_tapped");
			navigate("/map");
			map.easeTo({ center: [lng, lat], zoom: mapConfig.maxZoom });
		};
	})();

	const text = (
		<Trans
			t={t}
			i18nKey={isImperial ? "profile.distance_imperial" : "profile.distance_metric"}
			components={[<span key="0" className="sr-only" />]}
			tOptions={{ distance }}
		/>
	);
	if (goToLocation == null || !isVisibleOnMap(account)) {
		return (
			<Box
				component="span"
				sx={(theme) => ({
					display: "flex",
					alignItems: "center",
					gap: theme.spacing(0.5),
					fontSize: "12px",
					fontFamily: "var(--heading-font)",
					color: palette.OnSurfaceSecondary,
				})}
			>
				<LocationPinIcon sx={{ width: "12px", height: "12px" }} />
				{text}
			</Box>
		);
	}

	return (
		<Button size="xxxs" variant="primary ghost" onClick={goToLocation}>
			<LocationPinIcon />
			{text}
		</Button>
	);
}
