import AppLayout from "@components/AppLayout";
import useAccount from "@hooks/useAccount";
import useGlobalLoader from "@hooks/useGlobalLoader";
import { ReactNode } from "react";
import { Chat } from "stream-chat-react";
import useChatClient from "./useChatClient";
// This forces Fast Refresh to always build this component's state
// from scratch.
// @refresh reset

export default function ChatRoot({ children }: { children: ReactNode }) {
	const user = useAccount();
	const chatClientState = useChatClient();

	useGlobalLoader(user != null && chatClientState == null, "ChatRoot chatClientState");

	if (user == null) {
		return null;
	}

	if (chatClientState == null) {
		return <AppLayout>{null}</AppLayout>;
	}

	return (
		<Chat
			key={user.uuid}
			client={chatClientState.streamClient}
			i18nInstance={chatClientState.i18nInstance}
			theme="str-chat__theme-dark"
		>
			{children}
		</Chat>
	);
}
