import UnauthenticatedLanguageMenu from "@components/UnauthenticatedLanguageMenu";
import isPWA from "@lib/util/isPWA";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import bkdr from "public/imgs/BKDR.com.svg";

export default function NowRedirect() {
	const { t } = useTranslation("common");
	const inPWA = isPWA();
	return (
		<Box
			sx={{
				height: "100%",
				width: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<UnauthenticatedLanguageMenu />
			<Box
				sx={{
					maxWidth: "500px",
					padding: "0 20px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					"& > img": {
						maxWidth: "100%",
					},
				}}
			>
				<Typography sx={{ mb: "20px" }}>{t("landing_redirect.title")}</Typography>
				{/* eslint-disable-next-line @next/next/no-img-element */}
				<img src={bkdr} alt={t("landing_redirect.new_domain")} />
				{inPWA ? (
					<Typography sx={{ mt: "20px", textAlign: "center" }}>{t("landing_redirect.is_pwa")}</Typography>
				) : null}
				<Button
					LinkComponent="a"
					href="https://bkdr.com"
					variant="outlined"
					target={inPWA ? "_blank" : "_self"}
					sx={{ mt: "60px", width: "100%" }}
				>
					{t("landing_redirect.link_name")}
				</Button>
			</Box>
		</Box>
	);
}
