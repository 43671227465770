import assertType from "@lib/util/assertType";
import { load } from "recaptcha-v3";

async function evaluateCaptcha(action: string): Promise<string> {
	if (process.env.NODE_ENV === "development") {
		return "dev_recaptcha_token";
	} else {
		const recaptcha = await load(assertType(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY));
		return await recaptcha.execute(action);
	}
}

export default evaluateCaptcha;
