import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ArrowRightIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRArrowRightIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M13 4.58594L20.4142 12.0002L13 19.4144L11.5858 18.0002L16.5858 13.0002H4V11.0002H16.5858L11.5858 6.00015L13 4.58594Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
