import { useEffect } from "react";
import useEventCallback from "use-event-callback";
import { EventBase } from "./realtimeTypes";
import useActionCable from "./useActionCable";

export type ChannelName = "ProfileChannel" | "FeedChannel";
export interface ChannelNameWithParams {
	channel: ChannelName;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}

export default function useChannel(
	channelName: ChannelName | ChannelNameWithParams | null,
	receivedHandler: (data: EventBase) => void,
) {
	const cable = useActionCable();
	const received = useEventCallback(receivedHandler);
	useEffect(() => {
		if (cable != null && channelName != null) {
			const channel = cable.subscriptions.create(
				typeof channelName === "string" ? { channel: channelName } : channelName,
				{
					received,
				},
			);

			return () => {
				channel.unsubscribe();
			};
		}
	}, [cable, received, channelName]);
}
