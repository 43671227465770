import makeAction from "@lib/util/frp/makeAction";
import reportError from "@lib/util/reportError";
import { defaultState } from "../types";
import updateState from "./util/updateState";

const [closeAction, closeActionSubject] = makeAction<void | "override">();

export { closeAction };

export const closeActionObservable = closeActionSubject.pipe(
	updateState((override, state) => {
		if (["closed", "saving"].includes(state.view)) {
			reportError(new Error(`Tried to use ITMC close action while already closed! ${JSON.stringify(state)}`));
			return state;
		}
		if (override === "override") {
			return defaultState;
		}

		if (state.confirmation === "back" && state.view === "text input" && state.media != null) {
			return {
				view: "ready to save media",
				media: state.media,
				text: "",
				confirmation: false,
			};
		}
		if (state.confirmation !== false) {
			return defaultState;
		}

		if (state.media != null || state.text !== "") {
			return {
				...state,
				confirmation: "close",
			};
		}
		return defaultState;
	}),
);
