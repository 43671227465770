import { Button } from "@components/Button";
import Dialog from "@components/Dialog";
import InformationSquareIcon from "@components/icons/InformationSquareIcon";
import { Box, DialogActions, keyframes } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { getTutorialState, setTutorialState } from "./itmcTutorialState";

const INFO_BUTTON_OFFSET = "32px";

const infoButtonAnimation = keyframes({
	"0%": {
		transform: `translateX(calc(100% - ${INFO_BUTTON_OFFSET}))`,
	},
	"80%": {
		transform: `translateX(-${INFO_BUTTON_OFFSET})`,
	},
	"100%": {
		transform: "translateX(0)",
	},
});

export default function ITMCTutorialDialog() {
	const { t } = useTranslation("common");
	const [tutorialState] = useState(() => getTutorialState());
	const [tutorialOpen, setTutorialOpen] = useState(() => getTutorialState() === "not seen");
	const [openedOnce, setOpenedOnce] = useState(() => getTutorialState() === "seen");

	function handleClose() {
		setTutorialOpen(false);
		if (getTutorialState() === "not seen") {
			setTutorialState("seen once");
		}
	}

	function handleOpen() {
		setTutorialOpen(true);
		if (getTutorialState() === "seen once") {
			setTutorialState("seen");
			setOpenedOnce(true);
		}
	}

	return (
		<>
			<Box sx={{ overflow: "hidden", paddingLeft: INFO_BUTTON_OFFSET }}>
				{tutorialState === "not seen" ? null : (
					<Button
						icon
						size="xxs"
						variant="primary ghost"
						aria-label={t("in_the_moment.what_is_this")}
						sx={
							tutorialState === "seen once" && openedOnce === false
								? {
										animation: `1s ease 1.5s both ${infoButtonAnimation}`,
										zIndex: "-1",
										overflow: "hidden",
										width: "fit-content",
										padding: "8px",
										gap: "8px",
										"::after": {
											content: "attr(aria-label)",
										},
									}
								: undefined
						}
						onClick={handleOpen}
					>
						<InformationSquareIcon />
					</Button>
				)}
			</Box>
			<Dialog
				open={tutorialOpen}
				onClose={handleClose}
				mediaElement={<Box component="img" sx={{ aspectRatio: "700 / 574" }} src="/imgs/try_it_out.png" alt="" />}
				title={t("in_the_moment.post_an_update")}
			>
				<p>{t("in_the_moment.post_an_update_message")}</p>
				<p>{t("in_the_moment.have_fun")}</p>
				<DialogActions>
					<Button variant="primary" onClick={handleClose}>
						{t("in_the_moment.try_it_out_button")}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
