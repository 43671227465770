import FormCard from "@components/card/FormCard";
import FormCardHeader from "@components/card/FormCardHeader";
import CardContent from "@components/CardContent";
import { RenderableAlertType } from "@lib/feature/alert/types";
import { Button } from "@mui/material";
import { useTranslation } from "next-i18next";
import { Link } from "react-router-dom";

export default function SuccessView({ alert }: { alert: RenderableAlertType | null }) {
	const { t } = useTranslation("common");

	return (
		<FormCard component="main" alert={alert}>
			<FormCardHeader title={t("reset_password.form_title")} subheader={t("reset_password.success_subtitle")} />
			<CardContent>
				<Button component={Link} to="/" fullWidth variant="contained">
					{t("reset_password.home_link")}
				</Button>
			</CardContent>
		</FormCard>
	);
}
