import CardContent from "@components/CardContent";
import ImageFileInput from "@components/ImageFileInput";
import FormCardHeader from "@components/card/FormCardHeader";
import useAccount from "@hooks/useAccount";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import assertType from "@lib/util/assertType";
import getProfilePictures, { ProfilePictureType } from "@lib/util/getProfilePictures";
import AddIcon from "@mui/icons-material/Add";
import { Box, Card, Fab, Tooltip, styled } from "@mui/material";
import { useTranslation } from "next-i18next";
import PhotoUploadWizard from "./PhotoUploadWizard";
import hasPinnedProfilePhoto from "./PhotoUploadWizard/hasPinnedProfilePhoto";
import usePhotoWizardDialogState from "./PhotoUploadWizard/usePhotoWizardDialogState";
import GalleryPhotoButton from "./ProfilePhoto/GalleryPhotoButton";
import PrimaryProfilePhoto from "./ProfilePhoto/PrimaryProfilePhoto";

const MAX_USER_PHOTOS_LENGTH = 5;

const GalleryButtonGroup = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "stretch",
	alignItems: "center",
	gap: theme.spacing(1.25),
}));

const EmptyImageSpace = styled("label")(({ theme }) => ({
	background: "none",
	border: `1px dashed #C4C4C4`,
	borderRadius: "4px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	aspectRatio: "1 / 1",
	flex: "1 1 25%",
	color: theme.palette.grey[600],
}));

export default function ProfilePicManager() {
	const { t } = useTranslation("common");
	const user = assertType(useAccount());
	const hasPinnedPhoto = hasPinnedProfilePhoto(user);
	const updateAlert = useUpdateAlert();
	const photos: (ProfilePictureType | null)[] = getProfilePictures(user, true);
	const { onSelectImage, photoWizardDialogState } = usePhotoWizardDialogState();
	const buttons =
		photos.length < MAX_USER_PHOTOS_LENGTH
			? (
					Array.from(
						{ length: MAX_USER_PHOTOS_LENGTH - (photos.length === 0 ? 1 : photos.length) },
						() => null,
					) as (ProfilePictureType | null)[]
				).concat(photos.slice(1))
			: photos.slice(1);
	return (
		<Card variant="outlined" sx={{ overflow: "unset" }}>
			<FormCardHeader
				title={t("profile.photos_header")}
				subheader={t("profile.photos_subheader", { count: photos.length })}
			/>
			<CardContent
				sx={{
					gap: (theme) => theme.spacing(1.25),
				}}
			>
				<Box
					sx={{
						width: "100%",
						height: "auto",
						aspectRatio: "1 / 1",
						borderRadius: "4px",
						overflow: "hidden",
						position: "relative",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: (theme) => theme.palette.grey[600],
					}}
				>
					<PrimaryProfilePhoto photo={photos.at(0)} />
					<ImageFileInput
						renderLabel={(labelProps) => {
							return (
								<Tooltip arrow placement="top" title={t("profile.add_photo_button")}>
									<Fab
										component="label"
										{...labelProps}
										color="default"
										disabled={photos.length >= 5}
										sx={(theme) => ({
											position: "absolute",
											bottom: theme.spacing(1.25),
											right: theme.spacing(1.25),
										})}
										aria-label={t("profile.add_photo_button")}
									>
										<AddIcon fontSize="large" />
									</Fab>
								</Tooltip>
							);
						}}
						inputLabelText={t("profile.add_photo_button")}
						onFileSelected={onSelectImage}
						onError={updateAlert}
						disabled={photos.length >= 5}
					/>
				</Box>
				<GalleryButtonGroup data-testid="gallery-button-group">
					{buttons.map((photo, index) => {
						if (photo == null) {
							return (
								<ImageFileInput
									key={index}
									renderLabel={(labelProps) => {
										return <EmptyImageSpace {...labelProps} aria-hidden="true" />;
									}}
									inputLabelText={t("profile.add_photo_button")}
									onFileSelected={onSelectImage}
									onError={updateAlert}
									disabled={photos.length >= 5}
								/>
							);
						}
						return <GalleryPhotoButton key={photo?.uuid ?? index} photo={photo} />;
					})}
				</GalleryButtonGroup>
			</CardContent>
			<PhotoUploadWizard {...photoWizardDialogState} hasPinnedPhoto={hasPinnedPhoto} />
		</Card>
	);
}
