import BoltIcon from "@components/icons/BoltIcon";
import LocationPinIcon from "@components/icons/LocationPinIcon";
import useRegionBlocked from "@hooks/useRegionBlocked";
import {
	BottomNavigation,
	BottomNavigationAction,
	BottomNavigationActionProps,
	ButtonProps,
	styled,
} from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { ComponentProps } from "react";
import { Link, useLocation } from "react-router-dom";
import ChatsNotificationIcon from "./ChatsNotificationIcon";

const StyledBottomNavigationAction = styled(BottomNavigationAction)<
	BottomNavigationActionProps & ButtonProps & ComponentProps<typeof Link>
>(({ theme }) => {
	return {
		display: "flex",
		flexDirection: "row",
		gap: theme.spacing(1),
		maxWidth: "none",
		minWidth: "none",
		width: "100%",
		"& .MuiBottomNavigationAction-label": {
			fontFamily: "var(--heading-font)",
			fontSize: "14px",
		},
		"& svg": {
			height: "16px",
			width: "16px",
		},
	};
});

export default function BottomNav() {
	const { t } = useTranslation("common");
	const pathname = useLocation().pathname;
	const route = {
		map: pathname === "/map",
		feed: pathname === "/feed",
		chat: pathname === "/chat" || pathname.startsWith("/chat/"),
	};
	const isRegionBlocked = useRegionBlocked();
	const highlightedGridColumn = (() => {
		if (isRegionBlocked) {
			return route.map ? 1 : route.chat ? 2 : null;
		} else {
			return route.map ? 1 : route.feed ? 2 : route.chat ? 3 : null;
		}
	})();

	return (
		<BottomNavigation
			component="nav"
			showLabels
			sx={(theme) => ({
				display: "grid",
				gridTemplateColumns: `repeat(${isRegionBlocked ? "2" : "3"}, minmax(80px, 168px))`,
				gridTemplateRows: "auto 0px",
				height: theme.spacing(7),
				flex: "0 0 auto",
				justifySelf: "flex-end",
				bgcolor: palette.Surface + "50",
				backdropFilter: "blur(40px)",
				"&:after":
					highlightedGridColumn === null
						? null
						: {
								backgroundColor: palette.Primary,
								gridColumn: `${highlightedGridColumn} / span 1`,
								content: "' '",
								width: "57px",
								height: "2px",
								justifySelf: "center",
								alignSelf: "end",
								boxShadow: `0 0 8px 1px ${palette.Primary}`,
							},
			})}
			value={"active"}
		>
			<StyledBottomNavigationAction
				component={Link}
				to="/map"
				label={t("nav.map_link")}
				sx={{ color: route.map ? palette.onSurface : "text.secondary" }}
				icon={<LocationPinIcon sx={{ color: route.map ? palette.onSurface : "text.secondary" }} />}
				value={route.map ? "active" : "inactive"}
			/>
			{isRegionBlocked ? null : (
				<StyledBottomNavigationAction
					component={Link}
					to="/feed"
					label={t("nav.feed_link")}
					sx={{ color: route.feed ? palette.onSurface : "text.secondary" }}
					icon={<BoltIcon sx={{ color: route.feed ? palette.onSurface : "text.secondary" }} />}
					value={route.feed ? "active" : "inactive"}
				/>
			)}
			<StyledBottomNavigationAction
				component={Link}
				to="/chat"
				label={t("nav.inbox_link")}
				icon={<ChatsNotificationIcon sx={{ color: route.chat ? palette.onSurface : "text.secondary" }} />}
				value={route.chat ? "active" : "inactive"}
			/>
		</BottomNavigation>
	);
}
