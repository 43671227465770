import useDisplayName from "@hooks/useDisplayName";
import { useLocaleWithCountry } from "@hooks/useLocale";
import { OtherUser } from "@lib/models";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { ReactNode, memo, useMemo, useReducer } from "react";
import { useInterval } from "react-timers-hooks";

function TimeMessage({ lastActive }: { lastActive: string }): ReactNode {
	const { t } = useTranslation("common");
	const locale = useLocaleWithCountry();
	const [, forceRerender] = useReducer((previous) => !previous, false);
	useInterval(() => {
		forceRerender();
	}, 120000);
	const timeFormatter = useMemo(() => {
		return new Intl.RelativeTimeFormat(locale);
	}, [locale]);
	// It's `floor()` here and `ceil()` later because later is negative division
	const diffSeconds = Math.floor((Date.now() - new Date(lastActive).getTime()) / 1000);
	let timeDistance;
	if (diffSeconds > 86400) {
		timeDistance = timeFormatter.format(Math.ceil(diffSeconds / -86400), "day");
	} else if (diffSeconds > 3600) {
		timeDistance = timeFormatter.format(Math.ceil(diffSeconds / -3600), "hour");
	} else if (diffSeconds > 300) {
		// ceiling(N / -300) * 5 gives 5 minute precision of minute count.
		timeDistance = timeFormatter.format(Math.ceil(diffSeconds / -300) * 5, "minute");
	} else if (diffSeconds > 60) {
		return t("chat.last_active_recently_message");
	} else {
		return t("chat.current_active_message");
	}
	return t("chat.last_active_message", { timeDistance });
}

type ChatHeaderProps = {
	friend: OtherUser;
};

export default memo(function ChatHeader({ friend }: ChatHeaderProps) {
	const displayName = useDisplayName(friend);
	return (
		<Box sx={{ flex: "0 0 auto", justifyContent: "flex-start" }}>
			<Typography component="h1" variant="body1" fontSize="14px" fontWeight="bold">
				{displayName}
			</Typography>
			<Typography component="p" variant="body2" fontSize="12px">
				<TimeMessage lastActive={friend.last_active} />
			</Typography>
		</Box>
	);
});
