import AppLayout from "@components/AppLayout";
import useUser from "@hooks/useUser";
import { Box, Card, CardContent, CardHeader, List } from "@mui/material";
import { useTranslation } from "next-i18next";
import BlockManager from "./BlockManager";
import DeleteAccountSettingsCard from "./DeleteAccountSettings/DeleteAccountSettingsCard";
import EditEmailSettingsListItem from "./EmailSettings/EditEmailSettingsListItem";
import UpdateLanguageSettingsListItem from "./LanguageSettings/UpdateLanguageSettingsListItem";
import EditNotificationsSettingsListItem from "./NotificationsSettings/EditNotificationsSettingsListItem";
import RegisterForPushNotificationsListItem from "./NotificationsSettings/RegisterForPushNotificationsListItem";
import EditPasswordSettingsListItem from "./PasswordSettings/EditPasswordSettingsListItem";
import EditUnitsOfMeasurementSettingsListItem from "./UnitsOfMeasurementSettings/EditUnitsOfMeasurementSettingsListItem";
import VisibilitySettings from "./VisibilitySettings";

export default function SettingsView() {
	const { t } = useTranslation("common");
	const isAdmin = useUser()?.is_admin ?? false;

	return (
		<AppLayout pageHeading={t("account_settings.page_heading")} homeButtonTarget="map" noBottomNavigation>
			<Box
				component="main"
				sx={{
					flex: "1 1 auto",
					position: "relative",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto",
					px: 2.5,
					pb: 3,
					"> *": {
						maxWidth: "480px",
						width: "100%",
					},
				}}
			>
				<Card variant="outlined" sx={{ marginTop: "24px", flexShrink: 0 }}>
					<CardHeader title={t("account_settings.login_information")} sx={{ paddingBottom: "0" }} />
					<CardContent sx={{ py: "0" }}>
						<List sx={{ paddingBottom: "0" }}>
							<EditEmailSettingsListItem />
							<EditPasswordSettingsListItem />
						</List>
					</CardContent>
				</Card>
				<Card variant="outlined" sx={{ marginTop: "24px", flexShrink: 0 }}>
					<CardHeader
						sx={{ paddingBottom: "0" }}
						title={t("account_settings.notifications")}
						subheader={t("account_settings.notifications_body")}
					/>
					<CardContent sx={{ py: "0" }}>
						<List sx={{ paddingBottom: "0" }}>
							<EditNotificationsSettingsListItem />
							<RegisterForPushNotificationsListItem />
						</List>
					</CardContent>
				</Card>
				<Card variant="outlined" sx={{ marginTop: "24px", flexShrink: 0 }}>
					<CardHeader sx={{ paddingBottom: "0" }} title={t("account_settings.locale_settings_title")} />
					<CardContent sx={{ py: "0" }}>
						<List sx={{ paddingBottom: "0" }}>
							<UpdateLanguageSettingsListItem />
							<EditUnitsOfMeasurementSettingsListItem />
						</List>
					</CardContent>
				</Card>
				<Card variant="outlined" sx={{ marginTop: "24px", flexShrink: 0 }}>
					<CardHeader
						sx={{ paddingBottom: "0" }}
						title={t("account_settings.visibility_title")}
						subheader={isAdmin ? t("account_settings.visibility_body_admin") : t("account_settings.visibility_body")}
					/>
					<CardContent sx={{ py: "0" }}>
						<List sx={{ paddingBottom: "0" }}>
							<VisibilitySettings />
						</List>
					</CardContent>
				</Card>
				<BlockManager />
				<DeleteAccountSettingsCard />
			</Box>
		</AppLayout>
	);
}
