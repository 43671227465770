import { getFeed } from "@api/feed";
import { indicateLoading } from "@components/GlobalLoader";
import { exploreLocationObservable } from "@feature/exploreMode/exploreOverrideModel";
import { FeedItem } from "@lib/models";
import reportError from "@lib/util/reportError";
import { nanoid } from "nanoid";
import { BehaviorSubject, Subject, combineLatest, from, map, merge, of, scan, share, startWith, switchMap } from "rxjs";
import { blockStream } from "../blocking";

const INITIAL_RADIUS_KM = 50;

export class FeedError extends Error {}

export const radiusBehavior = new BehaviorSubject(INITIAL_RADIUS_KM);

export function setRadius(radius: number) {
	radiusBehavior.next(radius);
}

const refreshSubject = new Subject<undefined>();

export function refresh() {
	refreshSubject.next(undefined);
}

const removeItemSubject = new Subject<string>();
blockStream.pipe(map((blockEvent) => blockEvent.uuid)).subscribe(removeItemSubject);

export function removeFeedItem(userUuid: string) {
	removeItemSubject.next(userUuid);
}

export type FeedStreamEmission = {
	itemsListId: string;
	feedItems: FeedItem[] | FeedError;
	loading: boolean;
};

export const feedStream = merge(
	combineLatest([radiusBehavior, exploreLocationObservable, refreshSubject]).pipe(
		switchMap(([radius, location]) => {
			if (location == null) {
				return of(() => ({
					itemsListId: nanoid(),
					feedItems: [],
					loading: false,
				}));
			}
			const stopLoading = indicateLoading("feed API call");
			return from(
				getFeed(radius, location)
					.catch((error) => {
						reportError(error);
						return new FeedError();
					})
					.finally(() => {
						stopLoading();
					}),
			)
				.pipe(
					map((feedItems) => () => ({
						itemsListId: nanoid(),
						feedItems,
						loading: false,
					})),
				)
				.pipe(
					startWith((emission: FeedStreamEmission) => ({
						itemsListId: emission.itemsListId,
						feedItems: emission.feedItems instanceof FeedError ? [] : emission.feedItems,
						loading: true,
					})),
				);
		}),
	),
	removeItemSubject.pipe(
		map((userUuid: string) => (emission: FeedStreamEmission) => ({
			itemsListId: emission.itemsListId,
			feedItems:
				emission.feedItems instanceof FeedError
					? []
					: emission.feedItems.filter((feedItem) => feedItem.content.user_short.uuid != userUuid),
			loading: emission.loading,
		})),
	),
).pipe(
	scan<(_: FeedStreamEmission) => FeedStreamEmission, FeedStreamEmission>((acc, updator) => updator(acc), {
		itemsListId: nanoid(),
		feedItems: [],
		loading: true,
	}),
	share(),
);
