import HeaderBar from "@components/HeaderBar";
import useObservable from "@hooks/useObservable";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { viewObservable } from "./itmcPostModel";
import { backAction } from "./postModelAction/backAction";
import { closeAction } from "./postModelAction/closeAction";

type ITMCHeaderProps = {
	titleId: string;
};

export default function ITMCHeader({ titleId }: ITMCHeaderProps) {
	const { t } = useTranslation("common");
	const view = useObservable(viewObservable);

	const heading = useMemo(() => {
		if (view === "text input") {
			return t("in_the_moment.text_status_button");
		}
		return "";
	}, [view, t]);

	return (
		<HeaderBar
			titleId={titleId}
			heading={heading}
			onBack={backAction}
			onClose={closeAction}
			sx={{ minHeight: "45px", padding: "0 8px", "[aria-label='BACK']": { marginLeft: 0 } }}
		/>
	);
}
