import { PixelCrop } from "react-image-crop";

export class Media {
	blob: Blob;
	constructor(blob: Blob) {
		this.blob = blob;
	}
}
export class PhotoMedia extends Media {
	crop: PixelCrop | null;
	constructor({ blob, crop }: { blob: Blob; crop?: PixelCrop | null }) {
		super(blob);
		this.crop = crop ?? null;
	}
	setCrop(crop: PixelCrop) {
		return new PhotoMedia({ ...this, crop });
	}
}
export class VideoMedia extends Media {}

export type View =
	| "closed"
	| "media recorder"
	| "media preview"
	| "photo crop"
	| "ready to save media"
	| "text input"
	| "saving";

export type ITMCCaptureType = "video" | "camera";

export type State = {
	view: View;
	media: null | Media;
	text: string;
	confirmation: false | "close" | "back";
};

export const defaultState: State = {
	view: "closed",
	media: null,
	text: "",
	confirmation: false,
};
