import { Button } from "@components/Button";
import CornerUpLeftIcon from "@components/icons/CornerUpLeftIcon";
import { useTranslation } from "next-i18next";
import { BaseSyntheticEvent } from "react";
import { Message } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react";

type SendButtonProps = {
	disabled?: boolean;
	sendMessage: (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		event: BaseSyntheticEvent<object, any, any>,
		customMessageData?: Partial<Message<DefaultStreamChatGenerics>> | undefined,
	) => void;
};

export default function SendButton({ disabled, sendMessage }: SendButtonProps) {
	const { t } = useTranslation("common");
	return (
		<Button
			icon
			variant={"primary"}
			aria-label={t("send_button_label")}
			type="button"
			onClick={sendMessage}
			disabled={disabled}
		>
			<CornerUpLeftIcon />
		</Button>
	);
}
