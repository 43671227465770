import useNavModalState from "@hooks/useNavModalState";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import { ReactNode, useCallback } from "react";

export type RenderOpener = (onOpen: () => void) => ReactNode;

type ProfileDialogProps = {
	renderOpener: RenderOpener;
	onBlock?: () => void;
	children: (handlers: { onClose: () => void; onBlock: (message: string) => void }) => ReactNode;
	uuid: string;
	source: string;
};

export default function ProfileDialog({
	renderOpener,
	uuid,
	onBlock: outerOnBlock = () => undefined,
	children,
	source,
}: ProfileDialogProps) {
	const { open: profileOpen, setOpen } = useNavModalState(`viewing-profile-${uuid}-${source}`);
	const updateAlert = useUpdateAlert();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const onOpen = useCallback(() => {
		setOpen(true);
	}, [setOpen]);

	function onClose() {
		setOpen(false);
	}

	function onBlock(successMessage: string) {
		onClose();
		outerOnBlock();
		updateAlert(successMessage, "success");
	}

	return (
		<>
			{renderOpener(onOpen)}
			<Dialog
				PaperProps={{ sx: { pb: 0 } }}
				fullWidth={!fullScreen}
				fullScreen={fullScreen}
				open={profileOpen}
				onClose={onClose}
			>
				{children({ onBlock, onClose })}
			</Dialog>
		</>
	);
}
