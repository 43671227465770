import { Button } from "@components/Button";
import Dialog from "@components/Dialog";
import { DialogActions, TextField } from "@mui/material";
import { useTranslation } from "next-i18next";
import CurrentSelection from "./CurrentSelection";
import SelectOptionsList from "./SelectOptionsList";
import SuggestionLink from "./SuggestionLink";
import { MultiSelectConfig } from "./configTypes";

type MultiSelectDialogProps = {
	open: boolean;
	onClose: () => void;
	onChange: (next: string[]) => void;
	config: MultiSelectConfig;
	currentValueSet: Set<string>;
	setCurrentValueSet: (update: (prev: Set<string>) => Set<string>) => void;
	disableFilter: boolean;
	maxSelectionSize: number | null;
	filter: string;
	setFilter: (next: string) => void;
};

export default function MultiSelectDialog({
	open,
	onClose,
	onChange,
	config,
	currentValueSet,
	setCurrentValueSet,
	disableFilter,
	maxSelectionSize,
	filter,
	setFilter,
}: MultiSelectDialogProps) {
	const { t } = useTranslation("common");

	return (
		<Dialog
			open={open}
			onClose={onClose}
			title={config.label}
			subtitle={config.sectionLabel}
			dialogContentProps={{
				sx: {
					"& > *": {
						margin: 0,
					},
				},
			}}
		>
			<p>
				{maxSelectionSize == null
					? t("multi_select_dialog.select_helper")
					: t("multi_select_dialog.select_helper_limited", { limit: maxSelectionSize })}
			</p>
			{disableFilter ? null : (
				<TextField
					label={t("filter_input_label")}
					fullWidth
					size="small"
					value={filter}
					onChange={(event) => {
						setFilter(event.target.value.toLocaleLowerCase());
					}}
					InputLabelProps={{
						shrink: true,
					}}
					helperText={t("multi_select_dialog.filter_help_text")}
				/>
			)}
			<CurrentSelection config={config} currentValueSet={currentValueSet} setCurrentValueSet={setCurrentValueSet} />
			<SelectOptionsList
				config={config}
				currentValueSet={currentValueSet}
				setCurrentValueSet={setCurrentValueSet}
				maxSelectionSize={maxSelectionSize}
				filter={filter}
			/>
			<DialogActions
				sx={{
					position: "sticky",
					bottom: 0,
				}}
			>
				<Button
					variant="primary"
					onClick={() => {
						onChange(Array.from(currentValueSet.values()));
						onClose();
					}}
				>
					{t("done_button")}
				</Button>
			</DialogActions>
			{config.suggestionType != null ? (
				<p>
					<SuggestionLink suggestionType={config.suggestionType} />
				</p>
			) : null}
		</Dialog>
	);
}
