import useUser from "@hooks/useUser";
import { Box } from "@mui/material";
import { useMatch } from "react-router-dom";
import MapLayout from "./MapLayout";

export default function GlobalMap() {
	const ownUser = useUser();
	const isMapRoute = useMatch("/map") != null;
	const isAnonSignupRoute = useMatch("/anon-sign-up") != null;
	const shouldShowMap = isMapRoute || isAnonSignupRoute;

	if (ownUser == null && isAnonSignupRoute === false) {
		return null;
	}

	return (
		<Box
			sx={{
				position: "absolute",
				top: 0,
				bottom: 0,
				width: "100%",
				left: shouldShowMap ? 0 : -10000,
				display: "flex",
				flexDirection: "column",
				overflow: "hidden",
				visibility: shouldShowMap ? "visible" : "hidden",
				zIndex: shouldShowMap ? 0 : -10000,
			}}
		>
			<MapLayout />
		</Box>
	);
}
