import ITMCEntryController from "@feature/itmc/ITMCEntryController";
import useIsDesktop from "@hooks/useIsDesktop";
import useUpdatedITMCFlowGate from "@hooks/useUpdatedITMCFlowGate";
import useUser from "@hooks/useUser";
import Box from "@mui/material/Box";
import { BottomRightMapControlsProps } from ".";
import CenterControl from "../CenterControl";
import ExplorePopularCitiesControl from "../ExplorePopularCitiesControl";
import InTheMomentControl from "../InTheMomentControl";

export default function MapControls({ mapbox, center }: BottomRightMapControlsProps) {
	const user = useUser();
	const isDesktop = useIsDesktop();
	const useUpdatedITMCFlow = useUpdatedITMCFlowGate();
	if (user == null) {
		return null;
	}
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-end",
				marginBottom: isDesktop ? "16px" : "0",
			}}
		>
			<Box sx={{ gap: 0, marginBottom: "40px" }}>
				{user.is_admin === true ? <ExplorePopularCitiesControl source="map" /> : null}
				<CenterControl mapbox={mapbox} center={center} />
			</Box>
			{useUpdatedITMCFlow ? <ITMCEntryController source="map" /> : <InTheMomentControl source="map" />}
		</Box>
	);
}
