import CheckboxField from "@components/CheckboxField";
import TranslationExternalLink from "@components/TranslationExternalLink";
import useIdify from "@hooks/useIdify";
import { Typography } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";

function validate(value: boolean | undefined) {
	if (value === true) {
		return undefined;
	}
	return [{ t: "signup.legal_agree_error" }];
}

const externalLinks = [
	// it's not that kind of array
	// eslint-disable-next-line react/jsx-key
	<TranslationExternalLink href="https://www.perrystreet.com/privacy" />,
	// eslint-disable-next-line react/jsx-key
	<TranslationExternalLink href="https://www.perrystreet.com/tos" />,
];

export default function AcceptLegalField() {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const helperId = idify("accept_legal_helper_text");

	return (
		<CheckboxField
			validate={validate}
			name="accept_legal"
			label={t("signup.legal_agree_checkbox_label")}
			helperId={helperId}
			helperElement={
				<Typography id={helperId} sx={{ fontSize: "12px", ml: "26px" }}>
					<Trans t={t} i18nKey="lurker_wizard.legal_helper_text" components={externalLinks} />
				</Typography>
			}
		/>
	);
}
