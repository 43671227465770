import Dialog from "@components/Dialog";
import DialogButton from "@components/DialogButton";
import EyeSlashIcon from "@components/icons/EyeSlashIcon";
import { refreshAccountRegister } from "@feature/accountRegisterModel";
import useIdify from "@hooks/useIdify";
import useFeatureFlags from "@lib/feature/featureFlags/useFeatureFlags";
import SaveSwitch from "@lib/feature/settings/SaveSwitch";
import vcrOsdMono from "@lib/fonts/vcrOsdMono";
import { track } from "@lib/util/trackAnalytics";
import { FormControlLabel, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";

type AppMenuNSFWSwitch = {
	onClose: () => void;
};

export default function AppMenuNSFWSwitch({ onClose }: AppMenuNSFWSwitch) {
	const { t } = useTranslation("common");
	const nsfwModeOn = useFeatureFlags()?.blur_map_pins ?? false;
	const idify = useIdify();
	const labelId = idify("blur_map_pins");

	return (
		<DialogButton
			renderDialog={(open, onClose) => {
				return (
					<Dialog open={open} onClose={onClose} title={t("nsfw_mode.dialog_title")}>
						<p>{t("nsfw_mode.dialog_body_1")}</p>
						<p>{t("nsfw_mode.dialog_body_2")}</p>
						<FormControlLabel
							id={labelId}
							sx={{ display: "flex", justifyContent: "space-between", m: 0 }}
							control={
								<SaveSwitch
									labelId={labelId}
									accountSetting="blur_map_pins"
									extraRefresh={() => {
										refreshAccountRegister();
									}}
									onChangeFinished={(savedValue) => {
										track("NSFW_tapped", { state: savedValue });
									}}
								/>
							}
							label={
								<Typography style={{ fontFamily: vcrOsdMono.style.fontFamily }}>
									{t("nsfw_mode.switch_label")}
								</Typography>
							}
							labelPlacement="start"
						/>
					</Dialog>
				);
			}}
			renderButton={(onOpen) => (
				<MenuItem
					component="span"
					onClick={() => {
						onClose();
						onOpen();
					}}
				>
					<EyeSlashIcon />
					{nsfwModeOn ? t("app_bar.nsfw_switch_on") : t("app_bar.nsfw_switch")}
				</MenuItem>
			)}
			dialogRoutingName="nsfw-switch"
		/>
	);
}
