import LightboxDialog from "@components/Lightbox";
import TapToUnblurButton from "@components/TapToUnblurButton";
import UserIcon from "@components/icons/UserIcon";
import useIsBlurred from "@hooks/useIsBlurred";
import useNavModalState from "@hooks/useNavModalState";
import { AnyAccount, ProfileType } from "@lib/models";
import getProfilePictures from "@lib/util/getProfilePictures";
import { track } from "@lib/util/trackAnalytics";
import getClickedMediaInfo from "@lib/util/trackingHelpers/getClickedMediaInfo";
import { Box } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { useCallback, useState } from "react";
import ITMCOverlay from "./ITMCOverlay";
import PhotoRotator from "./PhotoRotator";
import usePhotoRotatorState from "./usePhotoRotatorState";

export default function ProfilePicture({ account, profileType }: { account: AnyAccount; profileType: ProfileType }) {
	const { t } = useTranslation("common");
	const images = getProfilePictures(account);
	const useAnonPlaceholder = account.is_anon;
	const photoRotatorState = usePhotoRotatorState(images);
	const blurEnabled = useIsBlurred();
	const [isBlurred, setIsBlurred] = useState(blurEnabled);
	const showUnblur = isBlurred && images.length > 0;

	const { open: lightboxOpen, setOpen: setLightboxOpen } = useNavModalState("profile-lightbox");

	const rotatorPhotoClick = useCallback(
		(imageIndex: number) => {
			if (showUnblur) {
				return;
			}
			photoRotatorState?.setForwardMediaIndex(imageIndex - 1);
			setLightboxOpen(true);
			track("media_tapped_to_enlarge", getClickedMediaInfo(images[Math.max(0, imageIndex - 1)], "profile"));
		},
		[showUnblur, photoRotatorState, images, setLightboxOpen],
	);

	const itmContent = account.in_the_moment_status;

	return (
		<Box
			sx={{
				position: "relative",
				height: "auto",
				width: "100%",
				aspectRatio: "1 / 1",
				color: "background.default",
				flex: "0 0 auto",
			}}
		>
			<LightboxDialog
				open={lightboxOpen}
				setOpen={setLightboxOpen}
				renderContent={(isLightbox: boolean) => {
					if (images.length === 0) {
						return (
							<>
								<Box
									component="p"
									sx={{
										width: "100%",
										height: "100%",
										display: "flex",
										margin: 0,
										justifyContent: "center",
										alignItems: "center",
										backgroundColor: palette.OnSurfaceSecondary25,
									}}
								>
									<span className="sr-only">
										{t(useAnonPlaceholder ? "anonymous_user_avatar_alt" : "placeholder_avatar_alt")}
									</span>
									<UserIcon sx={{ width: 200, height: 200, color: palette.Primary }} />
								</Box>
							</>
						);
					}

					return (
						<PhotoRotator
							state={photoRotatorState}
							images={images}
							blurred={isBlurred}
							zoom={isLightbox}
							onClick={isLightbox || isBlurred ? undefined : rotatorPhotoClick}
							showButtons={isLightbox && images.length > 1}
						/>
					);
				}}
			/>

			<ITMCOverlay
				account={account}
				showReplyButton={
					profileType === "friend" && (itmContent?.message !== "" || photoRotatorState?.forwardMediaType !== "profile")
				}
				showTimestamp={
					(itmContent?.full != null || itmContent?.video != null) && photoRotatorState?.forwardMediaType !== "profile"
				}
			/>

			{showUnblur ? (
				<TapToUnblurButton
					onClick={() => {
						setIsBlurred(false);
					}}
				/>
			) : null}
		</Box>
	);
}
