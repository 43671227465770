import { LinkButton } from "@components/Button";
import Pane from "@components/Pane";
import SnackbarProvider from "@feature/alert/SnackbarProvider";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import EmailSubmissionSuccess from "./EmailSubmissionSuccess";
import RegionBlockedEmailForm from "./RegionBlockedEmailForm";

export default function RegionBlockedPane() {
	const { t } = useTranslation("common");
	const [isSuccessful, setIsSuccessful] = useState(false);

	return (
		<SnackbarProvider sx={{ top: "20px", left: "20px", right: "20px" }}>
			<Pane
				sx={{
					gap: 0,
				}}
			>
				{isSuccessful ? <EmailSubmissionSuccess /> : <RegionBlockedEmailForm onSuccess={() => setIsSuccessful(true)} />}
				<LinkButton to="/login" variant="secondary ghost" sx={{ mt: isSuccessful ? "24px" : "8px" }}>
					{t("landing_region_blocked.already_have_an_account_button")}
				</LinkButton>
			</Pane>
		</SnackbarProvider>
	);
}
