import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ArrowsPointingOutIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRArrowsPointingOutIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path d="M0 0H7V2H3.41421L9.41421 8L8 9.41421L2 3.41421V7H0V0Z" fill="currentColor" />
			<path d="M13 0H20V7H18V3.41421L12 9.41421L10.5858 8L16.5858 2H13V0Z" fill="currentColor" />
			<path d="M9.41421 12L3.41421 18H7V20H0V13H2V16.5858L8 10.5858L9.41421 12Z" fill="currentColor" />
			<path d="M12 10.5858L18 16.5858V13H20V20H13V18H16.5858L10.5858 12L12 10.5858Z" fill="currentColor" />
		</SvgIcon>
	);
}
