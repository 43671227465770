import useRegionBlocked from "@hooks/useRegionBlocked";
import useUser from "@hooks/useUser";
import { Box } from "@mui/material";
import { useMatch } from "react-router-dom";
import Feed from ".";

export default function GlobalFeed() {
	const ownUser = useUser();
	const isFeedRoute = useMatch("/feed") != null;
	const isRegionBlocked = useRegionBlocked();

	if (ownUser == null || isRegionBlocked) {
		return null;
	}

	return (
		<Box
			sx={{
				height: "100%",
				visibility: isFeedRoute ? "visible" : "hidden",
				zIndex: isFeedRoute ? 0 : -10000,
				position: isFeedRoute ? "relative" : "absolute",
				left: isFeedRoute ? "unset" : "-2000px",
				display: "flex",
				flexDirection: "column",
				overflow: "hidden",
			}}
		>
			<Feed />
		</Box>
	);
}
