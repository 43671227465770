import { keyframes } from "@emotion/react";
import useGlobalLoader from "@hooks/useGlobalLoader";
import useLocale from "@hooks/useLocale";
import useRegionBlocked from "@hooks/useRegionBlocked";
import useUser from "@hooks/useUser";
import useFeatureFlags from "@lib/feature/featureFlags/useFeatureFlags";
import assertType from "@lib/util/assertType";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import { styled } from "@mui/material";
import type { LngLatLike, Map as MapboxMap } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import useEventCallback from "use-event-callback";
import MapPins from "./mapPoints/MapPins";
import { clearMap, initMap } from "./mapbox";
import BottomRightMapControls from "./mapbox/BottomRightMapControls";
import useMapboxConfig from "./mapbox/useMapboxConfig";
import { UserPin } from "./pin";
import useEaseToPrecisePosition from "./useEaseToPrecisePosition";
import useMapLocation from "./useMapLocation";
import { setMapStatus } from "./useMapStatus";

// This forces Fast Refresh to always build this component's state
// from scratch. This is necessarily currently or the map glitches.
// @refresh reset

const fadeInAnimation = keyframes({
	from: {
		opacity: 0,
	},
	to: {
		opacity: 1,
	},
});

const MapRoot = styled("div")({
	height: "100%",
	width: "100%",
	".mapboxgl-canvas-container": {
		position: "relative",
		zIndex: 0,
	},
	"& .mapboxgl-marker:not(.cluster-pin) > *": {
		animation: `${fadeInAnimation} 1s linear`,
	},
});

const MapView = memo(function MapView() {
	const mapRef = useRef<HTMLDivElement>(null);
	const locale = useLocale();
	const [mapbox, setMapbox] = useState<MapboxMap | undefined>(undefined);
	const featureFlags = useFeatureFlags();
	const [position, isGranting] = useMapLocation();

	const regionBlocked = useRegionBlocked();
	const ownUser = useUser();
	const userPosition = useMemo(
		() => ({ lng: ownUser?.lng ?? position.lng, lat: ownUser?.lat ?? position.lat }),
		[ownUser, position],
	);
	useEaseToPrecisePosition(userPosition, mapbox);
	const mapConfig = useMapboxConfig();
	const userPin = useMemo(() => {
		if (ownUser == null || mapbox == null || regionBlocked || ownUser.is_hidden_admin) {
			return null;
		}
		return new UserPin(
			{
				id: "self",
				lng: userPosition.lng,
				lat: userPosition.lat,
			},
			mapbox,
		);
	}, [ownUser, userPosition, mapbox, regionBlocked]);

	const setupMap = useEventCallback(() => {
		if (!mapbox) {
			const center: LngLatLike = [userPosition.lng, userPosition.lat];

			const mapEl = assertType<HTMLDivElement>(mapRef.current);
			const map = initMap({
				...mapConfig,
				container: mapEl,
				center,
				zoom: mapConfig.zoom,
			});
			if (featureFlags?.nearest_users_center != null) {
				map.fitBounds([center, [featureFlags.nearest_users_center.lng, featureFlags.nearest_users_center.lat]]);
			}
			// MapboxLanguage does not have TS checks.
			const language = new MapboxLanguage({ defaultLanguage: locale });
			map.addControl(language);
			setMapbox(map);
		}
	});

	const teardownMap = useEventCallback(() => {
		clearMap();
		mapbox?.remove();
		setMapbox(undefined);
	});

	useEffect(() => {
		setupMap();
		return () => {
			teardownMap();
		};
	}, [setupMap, teardownMap]);

	useEffect(() => {
		setMapStatus(mapbox);
		return () => {
			setMapStatus(undefined);
		};
	}, [mapbox]);

	useGlobalLoader(isGranting, "MapView isGranting");

	return (
		<>
			<MapRoot ref={mapRef} />
			{mapbox == null ? null : <BottomRightMapControls mapbox={mapbox} center={position} />}
			<MapPins mapbox={mapbox} userPin={userPin} />
		</>
	);
});

export default MapView;
