import { Button } from "@components/Button";
import HeaderBar from "@components/HeaderBar";
import ArrowRightLeftIcon from "@components/icons/ArrowRightLeftIcon";
import { InTheMomentStatus } from "@lib/models";
import { Box } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import ITMCDialog from "../ITMCDialog";
import ITMCActiveUpdateMedia from "./ITMCActiveUpdateMedia";
import ITMCDeleteConfirmationDialog from "./ITMCDeleteConfirmationDialog";
import ITMCTimeRemaining from "./ITMCTimeRemaining";

type ITMCActiveUpdateProps = {
	open: boolean;
	itmc: NonNullable<InTheMomentStatus>;
	onClose: () => void;
	onReplace: () => void;
};

export default function ITMCActiveUpdate({ open, itmc, onClose, onReplace }: ITMCActiveUpdateProps) {
	const { t } = useTranslation("common");
	const [confirmingDelete, setConfirmingDelete] = useState(false);

	return (
		<ITMCDialog
			open={open}
			onClose={onClose}
			renderHeader={(titleId) => (
				<HeaderBar
					heading={t("in_the_moment.active_update_heading")}
					onClose={onClose}
					sx={{ minHeight: "45px", padding: "0 8px 0 16px" }}
					titleId={titleId}
				/>
			)}
		>
			<Box
				sx={{
					gap: "8px",
					height: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: "stretch",
				}}
			>
				<Box>
					<ITMCActiveUpdateMedia itmc={itmc} />
					{itmc.message === "" ? null : (
						<Box
							component="p"
							sx={{
								flex: "1 1 auto",
								display: "relative",
								justifyContent: "flex-start",
								fontSize: "18px",
								margin: 0,
								marginTop: itmc.media_type !== "text" ? "24px" : 0,
							}}
						>
							{itmc.message}
						</Box>
					)}
					<ITMCTimeRemaining createdAt={itmc.created_at} />
				</Box>

				<Button size="lg" variant="primary" sx={{ marginTop: "auto" }} onClick={onReplace}>
					{t("in_the_moment.replace_update")}
					<ArrowRightLeftIcon />
				</Button>
				<Button size="lg" variant="secondary ghost" onClick={() => setConfirmingDelete(true)}>
					{t("in_the_moment.delete_update")}
				</Button>
			</Box>
			<ITMCDeleteConfirmationDialog
				open={confirmingDelete}
				onClose={() => setConfirmingDelete(false)}
				onDelete={() => {
					setConfirmingDelete(false);
					onClose();
				}}
			/>
		</ITMCDialog>
	);
}
