import { track } from "@lib/util/trackAnalytics";
import { Button, Card, CardContent, CardHeader, List, ListItem } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import DeleteAccountDialog from "./DeleteAccountDialog";

export default function DeleteAccountSettingsCard() {
	const { t } = useTranslation("common");
	const [open, setOpen] = useState(false);
	function handleOpen() {
		track("delete_account_clicked");
		setOpen(true);
	}

	function handleClose() {
		track("delete_account_canceled");
		setOpen(false);
	}

	return (
		<Card variant="outlined" sx={(theme) => ({ marginTop: theme.spacing(3), flexShrink: 0 })}>
			<CardHeader
				sx={{ pb: 0 }}
				title={t("account_settings.delete_account_title")}
				subheader={t("account_settings.delete_account_body")}
			/>
			<CardContent sx={{ py: 0 }}>
				<List sx={{ pb: 0 }}>
					<ListItem>
						<Button variant="contained" onClick={handleOpen} fullWidth>
							{t("delete_button")}
						</Button>
					</ListItem>
				</List>
			</CardContent>
			<DeleteAccountDialog open={open} onClose={handleClose} />
		</Card>
	);
}
