import { useEffect } from "react";
import type { Channel, DefaultGenerics, Event } from "stream-chat";
import type { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import shouldDeleteUpdateCount from "./shouldDeleteUpdateCount";
import useChatClient from "./useChatClient";

export default function useMessageDeletedListener(
	setChannels: React.Dispatch<React.SetStateAction<Array<Channel<DefaultStreamChatGenerics>>>>,
) {
	const { streamClient } = useChatClient() ?? {};

	useEffect(() => {
		function handleEvent(event: Event<DefaultGenerics>) {
			if (event.type == null || event.type !== "message.deleted") {
				return;
			}
			setChannels((channels) => {
				const channelIndex = channels.findIndex((channel: Channel) => {
					return channel.cid === event.cid;
				});
				if (channelIndex < 0) {
					return channels;
				}
				const channel = channels[channelIndex];
				if (shouldDeleteUpdateCount(event, channel)) {
					channel.state.unreadCount = channel.countUnread() - 1;
					return [...channels];
				}
				return channels;
			});
		}
		if (streamClient == null) {
			return;
		}
		streamClient.on(handleEvent);
		return () => {
			streamClient.off(handleEvent);
		};
	}, [streamClient, setChannels]);
}
