import { SvgIcon, SvgIconProps } from "@mui/material";

export default function PlusSquareIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRPlusSquareIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22 2H2V22H22V2ZM13 11V7H11V11H7V13H11V17H13V13H17V11H13Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
