import { NotFoundError } from "@api/commonErrors";
import { blockUser as blockUserApi, unblockUser } from "@api/users";
import AvatarThumbnailMedia from "@components/AvatarThumbnailMedia";
import { Button } from "@components/Button";
import useGlobalLoader from "@hooks/useGlobalLoader";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import { blockUser } from "@lib/feature/blocking";
import { UserShort } from "@lib/models";
import reportError from "@lib/util/reportError";
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useCallback, useState } from "react";

type State = "blocked" | "unblocking" | "unblocked" | "blocking";

type BlockedUserProps = {
	userShort: UserShort;
};

export default function BlockedUser({ userShort }: BlockedUserProps) {
	const { t } = useTranslation("common");
	const updateAlert = useUpdateAlert();

	const [state, setState] = useState<State>("blocked");

	const onClick = useCallback(async () => {
		if (state === "blocked") {
			setState("unblocking");
			const error = await unblockUser({ block: { uuid: userShort.uuid } })
				.then(() => null)
				.catch((error) => {
					if (error instanceof NotFoundError) {
						return null;
					}
					return error;
				});
			if (error == null) {
				setState("unblocked");
			} else {
				updateAlert(t("unknown_error"));
				reportError(error);
				setState("blocked");
			}
		}
		if (state === "unblocked") {
			setState("blocking");
			const error = await blockUserApi({ block: { uuid: userShort.uuid } })
				.then(() => null)
				.catch((error) => error);
			if (error == null) {
				blockUser(userShort.uuid);
				setState("blocked");
			} else {
				updateAlert(t("unknown_error"));
				reportError(error);
				setState("unblocked");
			}
		}
	}, [state, userShort.uuid, updateAlert, t]);

	const loading = state === "blocking" || state === "unblocking";
	useGlobalLoader(loading, "BlockedUser setting");

	return (
		<ListItem sx={{ justifyContent: "space-between" }}>
			<ListItemAvatar>
				<Avatar variant="square">
					{/* empty alt as the display name conveys all non-visual information we have */}
					<AvatarThumbnailMedia profile={userShort} alt="" />
				</Avatar>
			</ListItemAvatar>
			<ListItemText
				primary={userShort.display_name}
				primaryTypographyProps={{
					sx: { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" },
				}}
			/>
			<Button
				onClick={onClick}
				disabled={loading}
				active={state === "unblocked" || state === "blocking"}
				variant="secondary ghost"
			>
				{state === "blocked" || state === "unblocking" ? t("account_settings.unblock_button") : t("block_button")}
			</Button>
		</ListItem>
	);
}
