import { postUserSettings, userSettingsPath } from "@api/profile";
import useGlobalLoader from "@hooks/useGlobalLoader";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import reportError from "@lib/util/reportError";
import { Switch } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useEffect, useMemo, useState } from "react";
import { useSWRConfig } from "swr";

type NotificationsInputProps = {
	id: string;
	checked: boolean | undefined;
};

export default function NotificationsInput({ id, checked = false }: NotificationsInputProps) {
	const { mutate } = useSWRConfig();
	const { t } = useTranslation("common");
	const updateAlert = useUpdateAlert();
	const [optimisticallyChecked, setOptimisticallyChecked] = useState<boolean | null>();
	useGlobalLoader(optimisticallyChecked != null, "NotificationsInput updating");

	useEffect(() => {
		setOptimisticallyChecked(null);
	}, [checked]);

	const isChecked = useMemo(() => {
		return optimisticallyChecked != null ? optimisticallyChecked : checked;
	}, [checked, optimisticallyChecked]);

	async function onChange(event: React.ChangeEvent<HTMLInputElement>) {
		setOptimisticallyChecked(event.target.checked);
		postUserSettings({ account_settings: { email_notifications: event.target.checked } })
			.then(() => {
				mutate(userSettingsPath);
			})
			.catch((error) => {
				reportError(error);
				updateAlert(t("unknown_error"));
				setOptimisticallyChecked(null);
			});
	}
	return (
		<Switch
			color="secondary"
			inputProps={{ "aria-labelledby": id }}
			onChange={onChange}
			checked={isChecked}
			disabled={optimisticallyChecked != null}
		/>
	);
}
