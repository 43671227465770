import MultiSelectField from "@components/MultiSelectField";
import { MultiSelectConfig } from "@components/MultiSelectField/configTypes";
import renderValueDefaultChips from "@components/MultiSelectField/renderValueDefaultChips";
import renderValueDefaultList from "@components/MultiSelectField/renderValueDefaultList";
import { SuggestionType } from "@components/MultiSelectField/useSuggestionLinks";
import { AnyAccount } from "@lib/models";
import { Divider } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Field } from "react-final-form";
import options from "../options.json";
import { isMatchableCategory } from "./getMatchingFriendCategories";

type TagFieldProps = {
	name: string;
	label: string;
	sectionLabel?: string;
	optionsKey: keyof typeof options;
	translationPrefix: string;
	maxSelectionSize?: number;
	suggestionType?: SuggestionType;
};

function TagField({
	name,
	label,
	sectionLabel,
	optionsKey,
	translationPrefix,
	maxSelectionSize,
	suggestionType,
}: TagFieldProps) {
	const { t } = useTranslation("common");
	const config = useMemo((): MultiSelectConfig => {
		return {
			label,
			sectionLabel,
			renderValue: isMatchableCategory(name as keyof AnyAccount) ? renderValueDefaultChips : renderValueDefaultList,
			options: Object.fromEntries(options[optionsKey].map((v) => [v, t(`${translationPrefix}.${v}`)])),
			maxSelectionSize,
			suggestionType,
		};
	}, [label, sectionLabel, optionsKey, translationPrefix, maxSelectionSize, suggestionType, t, name]);
	return (
		<Field<string[]>
			name={name}
			render={({ input, meta }) => {
				return (
					<>
						<MultiSelectField input={input} meta={meta} config={config} />
						<Divider />
					</>
				);
			}}
		/>
	);
}

export function PronounsField() {
	const { t } = useTranslation("common");
	return (
		<TagField
			name={"pronouns"}
			label={t("profile.pronouns_field_label")}
			sectionLabel={t("profile.section_basic_info_heading")}
			optionsKey="pronouns"
			translationPrefix="tag_pronoun_options"
			maxSelectionSize={options.pronouns.length}
			suggestionType="pronouns"
		/>
	);
}

export function RelationshipsField() {
	const { t } = useTranslation("common");
	return (
		<TagField
			name={"relationships"}
			label={t("profile.relationships_field_label")}
			sectionLabel={t("profile.section_basic_info_heading")}
			optionsKey="relationships"
			translationPrefix="relationship_options"
			maxSelectionSize={5}
		/>
	);
}

export function MyRolesField() {
	const { t } = useTranslation("common");
	return (
		<TagField
			name={"my_roles"}
			label={t("profile.roles_field_label")}
			sectionLabel={t("profile.section_details_heading")}
			optionsKey="roles"
			translationPrefix="tag_role_options"
			maxSelectionSize={5}
			suggestionType="role"
		/>
	);
}

export function MyIdentitiesField() {
	const { t } = useTranslation("common");
	return (
		<TagField
			name={"my_identities"}
			label={t("profile.identities_field_label")}
			sectionLabel={t("profile.section_details_heading")}
			optionsKey="identities"
			translationPrefix="tag_identity_options"
			maxSelectionSize={5}
			suggestionType="identity"
		/>
	);
}

export function MyTraitsField() {
	const { t } = useTranslation("common");
	return (
		<TagField
			name={"my_traits"}
			label={t("profile.traits_field_label")}
			sectionLabel={t("profile.section_details_heading")}
			optionsKey="traits"
			translationPrefix="tag_trait_options"
			maxSelectionSize={5}
			suggestionType="traits"
		/>
	);
}

export function LookingForRolesField() {
	const { t } = useTranslation("common");
	return (
		<TagField
			name={"looking_for_roles"}
			label={t("profile.roles_field_label")}
			sectionLabel={t("profile.section_looking_for_heading")}
			optionsKey="roles"
			translationPrefix="tag_role_options"
			maxSelectionSize={5}
			suggestionType="role"
		/>
	);
}

export function LookingForIdentitiesField() {
	const { t } = useTranslation("common");
	return (
		<TagField
			name={"looking_for_identities"}
			label={t("profile.identities_field_label")}
			sectionLabel={t("profile.section_looking_for_heading")}
			optionsKey="identities"
			translationPrefix="tag_identity_options"
			maxSelectionSize={5}
			suggestionType="identity"
		/>
	);
}

export function LookingForTraitsField() {
	const { t } = useTranslation("common");
	return (
		<TagField
			name={"looking_for_traits"}
			label={t("profile.traits_field_label")}
			sectionLabel={t("profile.section_looking_for_heading")}
			optionsKey="traits"
			translationPrefix="tag_trait_options"
			maxSelectionSize={5}
			suggestionType="traits"
		/>
	);
}

export function ScenariosField() {
	const { t } = useTranslation("common");
	return (
		<TagField
			name={"scenarios"}
			label={t("profile.scenarios_field_label")}
			sectionLabel={t("profile.section_turn_ons_heading")}
			optionsKey="scenarios"
			translationPrefix="tag_scenario_options"
			maxSelectionSize={10}
			suggestionType="scenarios-dynamic"
		/>
	);
}

export function IntoField() {
	const { t } = useTranslation("common");
	return (
		<TagField
			name={"into"}
			label={t("profile.into_field_label")}
			sectionLabel={t("profile.section_turn_ons_heading")}
			optionsKey="into"
			translationPrefix="tag_into_options"
			maxSelectionSize={10}
			suggestionType="into"
		/>
	);
}

export function KinksField() {
	const { t } = useTranslation("common");
	return (
		<TagField
			name={"kinks"}
			label={t("profile.kinks_field_label")}
			sectionLabel={t("profile.section_turn_ons_heading")}
			optionsKey="kinks"
			translationPrefix="tag_kink_options"
			maxSelectionSize={10}
			suggestionType="kinks-fetishes"
		/>
	);
}

export function GendersField() {
	const { t } = useTranslation("common");
	return (
		<TagField
			name={"gender_identities"}
			label={t("profile.gender_identities_field_label")}
			optionsKey="gender_identities"
			translationPrefix="tag_gender_options"
			maxSelectionSize={8}
			suggestionType="gender"
		/>
	);
}

export function SexualitiesField() {
	const { t } = useTranslation("common");
	return (
		<TagField
			name={"sexualities"}
			label={t("profile.sexualities_field_label")}
			optionsKey="sexualities"
			translationPrefix="tag_sexuality_options"
			maxSelectionSize={8}
			suggestionType="sexuality"
		/>
	);
}

export function LanguagesSpokenField() {
	const { t } = useTranslation("common");
	return (
		<TagField
			name={"languages_spoken"}
			label={t("profile.languages_spoken_field_label")}
			optionsKey="languages_spoken"
			translationPrefix="tag_languages_spoken_options"
			maxSelectionSize={options.languages_spoken.length}
			suggestionType="languages_spoken"
		/>
	);
}
