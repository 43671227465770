import { uploadMedia } from "@api/media";
import { addInTheMomentStatus } from "@api/profile";
import { indicateLoading } from "@components/GlobalLoader";
import { refreshOwnUser } from "@feature/accountRegisterModel";
import { setGlobalAlert } from "@feature/alert/setGlobalAlert";
import "@lib/testUtil/TranslationWrapper"; // makes t work
import assertType from "@lib/util/assertType";
import makeAction from "@lib/util/frp/makeAction";
import reportError from "@lib/util/reportError";
import { t } from "i18next";
import { omit } from "ramda";
import { exhaustMap, map, startWith, Subject } from "rxjs";
import { defaultState, PhotoMedia, State, VideoMedia } from "../types";

const [saveAction, saveActionSubject] = makeAction<void>();

export { saveAction };

async function save(state: State) {
	await addInTheMomentStatus({
		in_the_moment_status: {
			message: state.text === "" ? undefined : state.text,
			...(state.media instanceof PhotoMedia
				? {
						image: {
							metadata: {
								crop: omit(["unit"], assertType(state.media.crop)),
							},
							id: await uploadMedia(state.media.blob),
						},
					}
				: undefined),
			...(state.media instanceof VideoMedia
				? {
						video: {
							id: await uploadMedia(state.media.blob),
						},
					}
				: undefined),
		},
	});
}

export const saveActionObservable = saveActionSubject
	.pipe(map(() => new Subject<(state: State) => State>()))
	.pipe(
		map((subject): [Subject<(state: State) => State>, (state: State) => State] => [
			subject,
			(state: State) => {
				if (
					(state.view === "text input" && state.text !== "" && state.media == null) ||
					(state.view === "ready to save media" && state.media != null)
				) {
					const stopLoading = indicateLoading("ITMC save action");
					save(state)
						.catch((error) => {
							reportError(error);
							setGlobalAlert({ severity: "error", children: t("unknown_error") });
						})
						.then(() => refreshOwnUser())
						.finally(() => {
							stopLoading();
							subject.next(() => defaultState);
							subject.complete();
						});
					// synchronoun return, the async handlers above are happening this
					return {
						...state,
						view: "saving",
					};
				}
				// this function is running in the itmcPostModel scan updator, so subject.complete()
				// doesn't block the return value below from having any affect.
				subject.complete();
				setGlobalAlert({ severity: "error", children: t("unknown_error") });
				reportError(
					new Error(`Tried to use ITMC save action on the wrong view or without content! ${JSON.stringify(state)}`),
				);
				return state;
			},
		]),
	)
	.pipe(exhaustMap(([subject, firstAction]) => subject.pipe(startWith(firstAction))));
