import { Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "next-i18next";
import { Field } from "react-final-form";

export default function ShowAgeField() {
	const { t } = useTranslation("common");

	return (
		<Field
			name="show_age"
			type="checkbox"
			render={({ input }) => {
				return (
					<FormControlLabel label={t("profile_edit.show_age_on_profile_label")} control={<Checkbox {...input} />} />
				);
			}}
		/>
	);
}
