import CheckboxField from "@components/CheckboxField";
import { useTranslation } from "next-i18next";

function validate(value: boolean | undefined) {
	if (!value) {
		return [{ t: "field_required_error" }];
	}
	return undefined;
}

export default function ConfirmAgeField() {
	const { t } = useTranslation("common");

	return (
		<CheckboxField
			name={"confirm_age"}
			label={t("landing_region_blocked.age_agreement_checkbox_label")}
			validate={validate}
			helperText={t("landing_region_blocked.age_agreement_checkbox_helper_text")}
		/>
	);
}
