import { Button } from "@components/Button";
import useIsDesktop from "@hooks/useIsDesktop";
import useUser from "@hooks/useUser";
import { track } from "@lib/util/trackAnalytics";
import { Box, Modal } from "@mui/material";
import constants from "@styles/constants";
import { useTranslation } from "next-i18next";
import { useCallback, useEffect, useState } from "react";
import { filter } from "rxjs";
import ITMC from ".";
import ITMCActiveUpdate from "./ActiveUpdate";
import ITMCButton from "./ITMCButton";
import ITMCEntryOptions from "./ITMCEntryOptions";
import { viewObservable } from "./itmcPostModel";
import ITMCTutorialDialog from "./ITMCTutorialDialog";
import { openItmc } from "./postModelAction/openAction";

export default function ITMCEntryController({ source }: { source: "map" | "feed" | "profile" }) {
	const { t } = useTranslation("common");
	const [openDialog, setOpenDialog] = useState<"entry point" | "active update" | null>(null);
	const isDesktop = useIsDesktop();
	const bottomOffset = isDesktop ? "36px" : "44px";
	const ownUser = useUser();
	const itmc = ownUser?.in_the_moment_status;
	const hasITMC = itmc != null;
	const isProcessing = hasITMC != null && itmc?.processing === true;

	useEffect(() => {
		const subscription = viewObservable.pipe(filter((value) => value === "saving")).subscribe(() => {
			setOpenDialog(null);
		});

		return () => {
			subscription.unsubscribe();
		};
	}, []);

	const labelTitle = isProcessing
		? t("loading")
		: openDialog === "entry point"
			? t("close_button_label")
			: hasITMC
				? t("in_the_moment_view_button_label")
				: t("in_the_moment_button_label");

	const onOpen = useCallback(() => {
		track("itm_button_tapped", { source });
		if (hasITMC) {
			setOpenDialog("active update");
			return;
		}
		setOpenDialog("entry point");
	}, [source, hasITMC]);

	const onClose = useCallback(() => {
		track("itmc_closed", { source });
		setOpenDialog(null);
	}, [source]);

	return (
		<>
			{(() => {
				if (openDialog === "entry point") {
					return (
						<>
							<Button variant="primary" icon aria-label="" size="xl" disabled={true} />
							<Modal
								open={true}
								onClose={onClose}
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "flex-end",
									justifyContent: "flex-end",
									marginBottom: `calc(${constants.footerHeight} + ${bottomOffset})`,
								}}
							>
								<Box role="dialog" tabIndex={-1} aria-label={labelTitle} aria-modal="true">
									<ITMCEntryOptions onClose={onClose} />
									<Box sx={{ display: "flex", gap: "20px", alignItems: "center", justifyContent: "flex-end" }}>
										<ITMCTutorialDialog />
										<ITMCButton open={true} label={labelTitle} loading={isProcessing} onClick={onClose} />
									</Box>
								</Box>
							</Modal>
						</>
					);
				}
				if (hasITMC && openDialog === "active update") {
					return (
						<>
							<Button variant="primary" icon aria-label="" size="xl" disabled={true} />
							<ITMCActiveUpdate
								open={true}
								itmc={itmc}
								onClose={() => setOpenDialog(null)}
								onReplace={() => {
									openItmc(itmc.media_type === "text" ? "text" : "media");
								}}
							/>
						</>
					);
				}
				return <ITMCButton open={false} label={labelTitle} loading={isProcessing} onClick={onOpen} />;
			})()}
			<ITMC key="itmc" />
		</>
	);
}
