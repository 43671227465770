import { SvgIcon, SvgIconProps } from "@mui/material";

export default function AudioIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRAudioIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9 2H7V22H9V2ZM11 7H13V17H11V7ZM3 9.99979H5V13.9998H3V9.99979ZM15 5.99979H17V17.9998H15V5.99979ZM21 8.99979H19V14.9998H21V8.99979Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
