import { Subject } from "rxjs";

export default function makeAction<T>() {
	const subject = new Subject<T>();
	return <[(arg: T) => void, Subject<T>]>[
		(arg: T) => {
			subject.next(arg);
		},
		subject,
	];
}
