import { PasswordNotValid, deleteAccount } from "@api/account";
import { ServerFormError } from "@api/commonErrors";
import Alert from "@components/Alert";
import { Button } from "@components/Button";
import Dialog from "@components/Dialog";
import Link from "@components/Link";
import { PANE_VERTICAL_SPACING } from "@components/Pane/PaneSection";
import PasswordField from "@components/PasswordField";
import useAuth from "@hooks/useAuth";
import { updateAlertType } from "@lib/feature/alert/types";
import useAlertState from "@lib/feature/alert/useAlertState";
import { makeHandleFormSubmit } from "@lib/util/makeHandleFormSubmit";
import { track } from "@lib/util/trackAnalytics";
import { Box, DialogActions } from "@mui/material";
import createDecorator from "final-form-focus";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Form } from "react-final-form";

type DeleteAccountFormValues = {
	password: string;
};

type DeleteAccountDialogProps = {
	open: boolean;
	onClose: () => void;
};

export default function DeleteAccountDialog({ open, onClose }: DeleteAccountDialogProps) {
	const { t } = useTranslation("common");
	const { onLogout } = useAuth();
	const submitter = async (values: DeleteAccountFormValues) => {
		await deleteAccount({
			password: values.password,
		})
			.then(() => {
				track("account_deleted");
				onLogout();
			})
			.catch((error) => {
				if (error instanceof PasswordNotValid) {
					throw new ServerFormError({
						error: t("delete_account_settings.invalid_password_error"),
						fields: { password: [t("delete_account_settings.invalid_password_error")] },
					});
				}
				throw error;
			});
		return undefined;
	};

	const [alert, localUpdateAlert] = useAlertState();
	const focusOnError = useMemo(() => createDecorator<DeleteAccountFormValues>(), []);
	const updateAlert: updateAlertType = function updateAlert(message, variant) {
		if (message === t("unknown_error")) {
			localUpdateAlert(message, variant);
		}
	};
	const onSubmit = makeHandleFormSubmit({
		submitter,
		updateAlert,
		successMessage: null,
		unknownErrorMessage: t("unknown_error"),
	});

	return (
		<Dialog open={open} onClose={onClose} title={t("delete_account_settings.dialog_title")}>
			<Form
				onSubmit={onSubmit}
				decorators={[focusOnError]}
				render={({ handleSubmit, hasValidationErrors, pristine, submitting }) => {
					return (
						<Box
							component="form"
							onSubmit={handleSubmit}
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: PANE_VERTICAL_SPACING,
							}}
						>
							{alert?.severity === "error" ? (
								<Alert
									key={alert.key}
									severity={alert.severity}
									body={alert.children}
									onClose={() => {
										localUpdateAlert();
									}}
								/>
							) : null}
							<p>{t("delete_account_settings.dialog_body_1")}</p>
							<p>{t("delete_account_settings.dialog_body_2")}</p>
							<p>{t("delete_account_settings.dialog_body_3")}</p>
							<PasswordField />
							<DialogActions>
								<Button variant="primary ghost" onClick={onClose} disabled={submitting}>
									{t("cancel_button")}
								</Button>
								<Button variant="primary" type="submit" disabled={submitting || pristine || hasValidationErrors}>
									{t("delete_button")}
								</Button>
							</DialogActions>
						</Box>
					);
				}}
			/>
			<div>
				<Link to="/forgot-password" variant="body2">
					{t("forgot_password_link")}
				</Link>
			</div>
		</Dialog>
	);
}
