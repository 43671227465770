import { useLocaleWithCountry } from "@hooks/useLocale";
import { Typography } from "@mui/material";
import palette from "@styles/palette";
import { addDays, addMilliseconds, differenceInMilliseconds } from "date-fns";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { useInterval } from "react-timers-hooks";

// returns the remaining time before ITMC expiration as an offset from 00:00:00 of the current date
function getTimeRemaining(isoString: string, locale: string) {
	const expirationDate = addDays(new Date(isoString), 1);
	const startOfDay = new Date().setHours(0, 0, 0, 0);
	const diffMs = differenceInMilliseconds(expirationDate, new Date());
	const displayDate = addMilliseconds(startOfDay, diffMs);
	const formatter = new Intl.DateTimeFormat(locale, {
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
		hourCycle: "h23",
	});
	if (diffMs <= 0) {
		return formatter.format(startOfDay);
	}
	return formatter.format(displayDate);
}

export default function ITMCTimeRemaining({ createdAt }: { createdAt: string }) {
	const { t } = useTranslation("common");
	const locale = useLocaleWithCountry();
	const [timeRemaining, setTimeRemaining] = useState<string>(() => getTimeRemaining(createdAt, locale));

	useInterval(() => {
		setTimeRemaining(getTimeRemaining(createdAt, locale));
	}, 1000);

	return (
		<Typography
			component="p"
			sx={{
				color: palette.OnSurfaceSecondary,
				fontSize: "10px",
				marginTop: "12px",
			}}
		>
			{t("in_the_moment.itmc_time_left", { timeRemaining })}
		</Typography>
	);
}
