import useIdify from "@hooks/useIdify";
import errorHelper from "@lib/util/errorHelper";
import emailValidator from "@lib/util/validation/emailValidator";
import merge from "@lib/util/validation/merge";
import { requiredStringValidator } from "@lib/util/validation/requiredValidators";
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { Field } from "react-final-form";
import FieldSubmitError from "./FieldSubmitError";

type EmailFieldProps = {
	name?: string;
	autoComplete?: string;
	label?: string;
	showHelperOnlyOnError?: boolean;
};

export default function EmailField({
	name = "email",
	autoComplete = "email",
	label,
	showHelperOnlyOnError = false,
}: EmailFieldProps) {
	const idify = useIdify();
	const { t } = useTranslation("common");
	return (
		<Field
			name={name}
			type="email"
			validate={merge([requiredStringValidator, emailValidator])}
			render={({ input, meta }) => {
				const { error, helperText } = errorHelper(t, t("email.field_helper"), meta);
				const inputId = idify(name);
				const helperTextId = idify(`${name}_helper`);
				const submitErrorId = idify(`${name}_submit_error`);
				return (
					<FormControl variant="outlined" fullWidth error={error}>
						<InputLabel required variant="outlined" htmlFor={inputId}>
							{label ?? t("email.field_label")}
						</InputLabel>
						<OutlinedInput
							autoComplete={autoComplete ?? "email"}
							id={inputId}
							aria-describedby={clsx(helperTextId, submitErrorId)}
							required
							label={label ?? t("email.field_label")}
							error={error}
							{...input}
						/>
						{showHelperOnlyOnError ? (
							error ? (
								<FormHelperText id={helperTextId} error={error}>
									{helperText}
								</FormHelperText>
							) : null
						) : (
							<FormHelperText id={helperTextId} error={error}>
								{helperText}
							</FormHelperText>
						)}
						<FieldSubmitError id={submitErrorId} meta={meta} />
					</FormControl>
				);
			}}
		/>
	);
}
