import { forgotPassword } from "@api/authentication";
import FormCard from "@components/card/FormCard";
import FormCardHeader from "@components/card/FormCardHeader";
import CardContent from "@components/CardContent";
import EmailField from "@components/EmailField";
import Link from "@components/Link";
import UnauthenticatedLanguageMenu from "@components/UnauthenticatedLanguageMenu";
import useLocale from "@hooks/useLocale";
import useRegionBlocked from "@hooks/useRegionBlocked";
import useAlertState from "@lib/feature/alert/useAlertState";
import { makeHandleFormSubmit } from "@lib/util/makeHandleFormSubmit";
import { track } from "@lib/util/trackAnalytics";
import { Button } from "@mui/material";
import createDecorator from "final-form-focus";
import { useTranslation } from "next-i18next";
import { Form } from "react-final-form";

type ForgotPasswordFormValues = {
	email: string;
};

const focusOnError = createDecorator<ForgotPasswordFormValues>();
export default function ForgotPassword() {
	const { t } = useTranslation("common");
	const locale = useLocale();
	const isRegionBlocked = useRegionBlocked();

	const [alert, updateAlert] = useAlertState();

	const onSubmit = makeHandleFormSubmit<ForgotPasswordFormValues>({
		submitter: async (values: ForgotPasswordFormValues) => {
			track("forgot_password_submitted");
			await forgotPassword({ login: values.email, locale });
			return undefined;
		},
		updateAlert,
		successMessage: t("forgot_password.email_sent_message"),
		unknownErrorMessage: t("unknown_error"),
	});

	return (
		<>
			<UnauthenticatedLanguageMenu />
			<Form
				onSubmit={onSubmit}
				decorators={[focusOnError]}
				render={({ handleSubmit, hasValidationErrors, submitting }) => {
					return (
						<FormCard component="form" noValidate={true} onSubmit={handleSubmit} alert={alert}>
							<FormCardHeader title={t("forgot_password.form_title")} subheader={t("forgot_password.form_subtitle")} />
							<CardContent>
								<EmailField />
								<Button
									type="submit"
									disabled={submitting}
									fullWidth
									variant={hasValidationErrors ? "outlined" : "contained"}
								>
									{t("forgot_password.send_email_button")}
								</Button>
								{isRegionBlocked ? null : (
									<Link to="/sign-up" variant="body2">
										{t("forgot_password.create_account_link")}
									</Link>
								)}
							</CardContent>
						</FormCard>
					);
				}}
			/>
		</>
	);
}
