import UnauthenticatedLanguageMenu from "@components/UnauthenticatedLanguageMenu";
import useAlertState from "@lib/feature/alert/useAlertState";
import { getLocationHash } from "@lib/util/locationWrapper";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useEventCallback from "use-event-callback";
import ResetPasswordForm from "./ResetPasswordForm";
import SuccessView from "./SuccessView";
import { clearPasswordSessionStorage, statusFromPasswordSessionStorage } from "./passwordStatusStorage";

type Status = "expired" | "success";

const minimumValidHashLength = 5;

export default function ResetPassword() {
	const { t } = useTranslation("common");
	const navigate = useNavigate();

	const [alert, updateAlert] = useAlertState();

	const [status, setStatus] = useState<Status | null>(statusFromPasswordSessionStorage);

	const setSuccessAlert = useEventCallback(() => {
		updateAlert(t("reset_password.reset_successful"), "success");
	});
	useEffect(() => {
		if (status === "success") {
			setSuccessAlert();
			clearPasswordSessionStorage();
		}
	}, [setSuccessAlert, status]);

	useEffect(() => {
		if (getLocationHash().length < minimumValidHashLength) {
			navigate("/", { replace: true });
		}
	}, [navigate]);

	return (
		<>
			<UnauthenticatedLanguageMenu />
			{status === "success" ? (
				<SuccessView alert={alert} />
			) : (
				<ResetPasswordForm
					expired={status === "expired"}
					updateAlert={updateAlert}
					onExpired={() => {
						setStatus("expired");
					}}
					alert={alert}
				/>
			)}
		</>
	);
}
