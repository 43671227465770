import InformationPane from "@components/InformationPane";
import CheckSquareIcon from "@components/icons/CheckSquareIcon";
import { useTranslation } from "next-i18next";

export default function EmailSubmissionSuccess() {
	const { t } = useTranslation("common");
	return (
		<InformationPane
			Icon={CheckSquareIcon}
			title={t("landing_region_blocked.submission_success_title")}
			subtitle={t("landing_region_blocked.submission_success_subtitle")}
		/>
	);
}
