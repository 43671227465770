import AppLayout from "@components/AppLayout";
import AnonBirthdateForm from "./AnonBirthdateForm";

export default function AnonSignUp() {
	return (
		<AppLayout noBottomNavigation>
			<AnonBirthdateForm />
		</AppLayout>
	);
}
