import { AccountRegister, Location, UserAccount } from "@lib/models";
import { pick } from "ramda";
import { BehaviorSubject, Observable, distinctUntilChanged, filter, map, of, switchMap } from "rxjs";
import { accountRegisterObservable } from "./accountRegisterModel";

export const locationOverrideBehavior = new BehaviorSubject<null | Location>(null);

export function setLocationOverride(location: null | Location) {
	locationOverrideBehavior.next(location);
}

function extendsLocation<T extends { lng: null | number; lat: null | number }>(obj: T): obj is T & Location {
	return typeof obj.lng === "number" && typeof obj.lat === "number";
}

const ownUserLocationObservable: Observable<Location | null> = accountRegisterObservable
	.pipe(
		filter((accountRegister): accountRegister is AccountRegister & { user: UserAccount } => {
			return accountRegister.user != null;
		}),
	)
	.pipe(map((accountRegister) => accountRegister.user))
	.pipe(
		map((ownUser): Location | null => {
			if (extendsLocation(ownUser)) {
				return pick(["lng", "lat"])(ownUser);
			}
			return null;
		}),
	);

export const locationObservable: Observable<Location | null> = accountRegisterObservable
	.pipe(map((accountRegister) => accountRegister.user != null))
	.pipe(distinctUntilChanged())
	.pipe(filter((hasUser) => hasUser))
	.pipe(
		switchMap(() => {
			return locationOverrideBehavior;
		}),
	)
	.pipe(
		switchMap((override) => {
			if (override == null) {
				return ownUserLocationObservable;
			}
			return of(override);
		}),
	)
	.pipe(
		distinctUntilChanged((prev: Location | null, current: Location | null) => {
			if (current === prev) {
				return true;
			}
			if (current === null || prev === null) {
				return false;
			}
			return prev.lng === current.lng && prev.lat === current.lat;
		}),
	);
