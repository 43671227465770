import PinIcon from "@components/icons/PinIcon";
import TrashIcon from "@components/icons/TrashIcon";
import useGlobalLoader from "@hooks/useGlobalLoader";
import useIdify from "@hooks/useIdify";
import { Menu, MenuItem } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { MutableRefObject, useRef, useState } from "react";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import usePhotoUpdate from "./usePhotoUpdate";

type PhotoUpdateMenuState = {
	open: boolean;
	rootRef: MutableRefObject<HTMLButtonElement | null>;
	onClose: () => void;
};

type PhotoUpdateMenuProps = {
	isPinned: boolean;
	imageUuid: string;
	menuState: PhotoUpdateMenuState;
};

export default function PhotoUpdateMenu({ isPinned, imageUuid, menuState }: PhotoUpdateMenuProps) {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const id = idify("photo-update-menu");
	const { saving, setPinned, deletePhoto } = usePhotoUpdate(imageUuid);
	const [confirmingDelete, setConfirmingDelete] = useState(false);
	useGlobalLoader(saving, "PhotoUpdateMenu saving");

	return (
		<>
			<Menu
				id={id}
				anchorEl={menuState.rootRef.current}
				onClose={menuState.onClose}
				open={menuState.open}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
			>
				<MenuItem
					className="sr-only"
					onClick={() => {
						menuState.onClose();
					}}
				>
					{t("screen_reader_close_menu_button")}
				</MenuItem>
				{isPinned ? (
					<MenuItem
						disabled={saving}
						onClick={() => {
							menuState.onClose();
							setPinned(false);
						}}
					>
						<PinIcon />
						{t("profile.unpin_photo_menu_item")}
					</MenuItem>
				) : (
					<MenuItem
						disabled={saving}
						onClick={() => {
							menuState.onClose();
							setPinned(true);
						}}
					>
						<PinIcon />
						{t("profile.pin_photo_menu_item")}
					</MenuItem>
				)}
				<MenuItem
					disabled={saving}
					onClick={() => {
						menuState.onClose();
						setConfirmingDelete(true);
					}}
				>
					<TrashIcon sx={{ color: palette.Primary }} />
					{t("profile.delete_photo_menu_item")}
				</MenuItem>
			</Menu>
			<DeleteConfirmationDialog
				open={confirmingDelete}
				onClose={() => setConfirmingDelete(false)}
				onDelete={deletePhoto}
			/>
		</>
	);
}

export function usePhotoUpdateMenuState() {
	const rootRef = useRef<HTMLButtonElement | null>(null);
	const [open, setOpen] = useState(false);

	function onOpenMenu() {
		setOpen(true);
	}
	function onClose() {
		setOpen(false);
	}

	return {
		onOpenMenu,
		rootRef,
		menuState: {
			open,
			onClose,
			rootRef,
		},
	};
}
