import useDisplayName from "@hooks/useDisplayName";
import useFeatureFlags from "@lib/feature/featureFlags/useFeatureFlags";
import { AnyAccount, UserShort } from "@lib/models";
import safeSx from "@lib/util/safeSx";
import { Avatar, SxProps, Theme } from "@mui/material";
import AvatarThumbnailMedia from "./AvatarThumbnailMedia";

type ProfileAvatarProps = {
	account: AnyAccount | UserShort;
	sx?: SxProps<Theme>;
	variant?: "square" | "circular" | "rounded";
};

export default function ProfileAvatar({ account, sx, variant = "circular" }: ProfileAvatarProps) {
	const alt = useDisplayName(account);
	const blurMapPins = useFeatureFlags()?.blur_map_pins ?? false;
	return (
		<Avatar variant={variant} sx={safeSx(sx)}>
			{account == null ? null : <AvatarThumbnailMedia profile={account} alt={alt} stayBlurred={blurMapPins} />}
		</Avatar>
	);
}
