import { BadLogin, Suspended, login } from "@api/authentication";
import { InputInvalid } from "@api/commonErrors";
import CardContent from "@components/CardContent";
import EmailField from "@components/EmailField";
import Link from "@components/Link";
import TranslationExternalLink from "@components/TranslationExternalLink";
import UnauthenticatedLanguageMenu from "@components/UnauthenticatedLanguageMenu";
import FormCard from "@components/card/FormCard";
import FormCardHeader from "@components/card/FormCardHeader";
import useAuth from "@hooks/useAuth";
import useLocale from "@hooks/useLocale";
import useAlertState from "@lib/feature/alert/useAlertState";
import getSupportLinks from "@lib/util/getSupportLinks";
import { makeHandleFormSubmit } from "@lib/util/makeHandleFormSubmit";
import { track } from "@lib/util/trackAnalytics";
import { Button } from "@mui/material";
import createDecorator from "final-form-focus";
import { Trans, useTranslation } from "next-i18next";
import { useEffect } from "react";
import { Form } from "react-final-form";
import PasswordField from "../components/PasswordField";

type LoginFormValues = {
	email: string;
	password: string;
};

const focusOnError = createDecorator<LoginFormValues>();
export default function Login() {
	const { t } = useTranslation("common");
	const { onLogin } = useAuth();
	const locale = useLocale();

	const [alert, updateAlert] = useAlertState();

	const onSubmit = makeHandleFormSubmit<LoginFormValues>({
		submitter: async (values: LoginFormValues) => {
			await login({ login: values.email, password: values.password });
			track("user_logged_in");
			onLogin();
			return undefined;
		},
		updateAlert,
		successMessage: null,
		unknownErrorMessage: t("unknown_error"),
		errorHandlers: [
			{
				errorType: BadLogin,
				result: t("login.bad_login"),
			},
			{
				errorType: InputInvalid,
				result: t("login.invalid_input"),
			},
			{
				errorType: Suspended,
				result: (
					<Trans
						t={t}
						i18nKey="login.suspended"
						components={[<TranslationExternalLink key="1" href={getSupportLinks("suspended", locale)} />]}
					/>
				),
			},
		],
	});

	useEffect(() => {
		track("login_page_visited");
	}, []);

	return (
		<>
			<UnauthenticatedLanguageMenu />
			<Form
				onSubmit={onSubmit}
				decorators={[focusOnError]}
				render={({ handleSubmit, hasValidationErrors, submitting }) => {
					return (
						<FormCard component="form" noValidate={true} onSubmit={handleSubmit} alert={alert}>
							<FormCardHeader title={t("login.log_in_heading")} subheader={t("login.login_sub_heading")} />
							<CardContent>
								<EmailField />
								<PasswordField />
								<Button
									type="submit"
									disabled={submitting}
									fullWidth
									variant={hasValidationErrors ? "outlined" : "contained"}
								>
									{t("log_in_button")}
								</Button>
								<Link to="/forgot-password" variant="body2">
									{t("forgot_password_link")}
								</Link>
							</CardContent>
						</FormCard>
					);
				}}
			/>
		</>
	);
}
