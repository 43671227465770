import useIsBlurred from "@hooks/useIsBlurred";
import { ProfilePictureType } from "@lib/util/getProfilePictures";
import EditIcon from "@mui/icons-material/Edit";
import PlaceholderIcon from "@mui/icons-material/Person";
import PushPinIcon from "@mui/icons-material/PushPin";
import { IconButton } from "@mui/material";
import { useTranslation } from "next-i18next";
import PhotoUpdateMenu, { usePhotoUpdateMenuState } from "./PhotoUpdateMenu";
import ProfilePhotoLoader from "./ProfilePhotoLoader";

// Split into two components so we don't have to deal with null photo for usePhotoUpdateMenuState
function PhotoWithMenu({ photo }: { photo: ProfilePictureType }) {
	const { t } = useTranslation("common");
	const { menuState, onOpenMenu, rootRef } = usePhotoUpdateMenuState();
	const isBlurred = useIsBlurred();
	return (
		<>
			{photo.render(photo.pinned ? t("profile.pinned_photo_alt") : t("profile.latest_photo_alt"), undefined, isBlurred)}

			<IconButton
				aria-label={t("profile.photo_menu_screen_reader_button")}
				onClick={onOpenMenu}
				ref={rootRef}
				sx={(theme) => ({
					position: "absolute",
					top: theme.spacing(0.5),
					right: theme.spacing(0.5),
				})}
			>
				{photo.pinned ? (
					<PushPinIcon
						sx={{
							filter: "drop-shadow(0px 0px 2px rgb(0 0 0 / 1))",
						}}
					/>
				) : (
					<EditIcon
						sx={{
							filter: "drop-shadow(0px 0px 2px rgb(0 0 0 / 1))",
						}}
					/>
				)}
			</IconButton>
			<PhotoUpdateMenu isPinned={photo.pinned} imageUuid={photo.uuid} menuState={menuState} />
		</>
	);
}

type PrimaryProfilePhotoProps = {
	photo: ProfilePictureType | null | undefined;
};

export default function PrimaryProfilePhoto({ photo }: PrimaryProfilePhotoProps) {
	if (photo == null) {
		return (
			<PlaceholderIcon
				component={PlaceholderIcon}
				sx={(theme) => ({
					width: "78.94%",
					height: "auto",
					aspectRatio: "1 / 1",
					color: "background.default",
					borderRadius: theme.spacing(0.5),
					overflow: "hidden",
				})}
			/>
		);
	}
	if (photo.processing) {
		return <ProfilePhotoLoader primary />;
	}
	return <PhotoWithMenu photo={photo} />;
}
