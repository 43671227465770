import { CaptchaFailure, createAccount, EmailNotUnique } from "@api/account";
import FormCard from "@components/card/FormCard";
import FormCardHeader from "@components/card/FormCardHeader";
import CardContent from "@components/CardContent";
import EmailField from "@components/EmailField";
import Link from "@components/Link";
import UnauthenticatedLanguageMenu from "@components/UnauthenticatedLanguageMenu";
import useAuth from "@hooks/useAuth";
import useAlertState from "@lib/feature/alert/useAlertState";
import evaluateCaptcha from "@lib/util/captcha";
import { makeHandleFormSubmit } from "@lib/util/makeHandleFormSubmit";
import { track } from "@lib/util/trackAnalytics";
import { Button } from "@mui/material";
import createDecorator from "final-form-focus";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";
import { Form } from "react-final-form";
import PasswordField from "../components/PasswordField";
import AcceptLegalField from "./Signup/AcceptLegalField";
import AgeVerificationField from "./Signup/AgeVerificationField";

type SignupFormValues = {
	email: string;
	password: string;
	birthdate: string;
};

const focusOnError = createDecorator<SignupFormValues>();
export default function Signup() {
	const { t } = useTranslation("common");
	const { onLogin } = useAuth();

	const [alert, updateAlert] = useAlertState();

	const onSubmit = makeHandleFormSubmit<SignupFormValues>({
		submitter: async (values: SignupFormValues) => {
			const token = await evaluateCaptcha("create_account");
			await createAccount({
				login: values.email,
				password: values.password,
				token,
				birthdate: values.birthdate,
			});
			track("user_signed_up");
			onLogin();
			return undefined;
		},
		updateAlert,
		successMessage: null,
		unknownErrorMessage: t("unknown_error"),
		errorHandlers: [
			{
				errorType: EmailNotUnique,
				result: t("email.not_unique"),
			},
			{
				errorType: CaptchaFailure,
				result: (error) => {
					track("captcha_failure", { reason: error.message });
					return t("unknown_error");
				},
			},
		],
	});

	useEffect(() => {
		track("sign_up_page_visited");
	}, []);

	return (
		<>
			<UnauthenticatedLanguageMenu />
			<Form
				onSubmit={onSubmit}
				decorators={[focusOnError]}
				render={({ handleSubmit, hasValidationErrors, submitting }) => {
					return (
						<FormCard component="form" onSubmit={handleSubmit} noValidate={true} alert={alert}>
							<FormCardHeader title={t("signup.sign_up_heading")} subheader={t("signup.sign_up_subheading")} />
							<CardContent>
								<EmailField />
								<PasswordField />
								<AgeVerificationField showAgeHelper />
								<AcceptLegalField />
								<Button
									type="submit"
									disabled={submitting}
									fullWidth
									variant={hasValidationErrors ? "outlined" : "contained"}
								>
									{t("sign_up_button")}
								</Button>
								<Link sx={{ fontSize: 14, alignSelf: "start" }} to="/login">
									{t("log_in_instead_link")}
								</Link>
							</CardContent>
						</FormCard>
					);
				}}
			/>
		</>
	);
}
