import useIdify from "@hooks/useIdify";
import useIsDesktop from "@hooks/useIsDesktop";
import useLocale from "@hooks/useLocale";
import { getLocationHref } from "@lib/util/locationWrapper";
import LanguageIcon from "@mui/icons-material/Language";
import { Button, Menu, MenuItem } from "@mui/material";
import { useTranslation } from "next-i18next";
import i18next from "next-i18next.config.mjs";
import { useMemo, useRef, useState } from "react";
const locales = i18next.i18n.locales.slice(1);

export default function UnauthenticatedLanguageMenu() {
	const currentUrl = getLocationHref();
	const locale = useLocale();
	const { t } = useTranslation("common");
	const idify = useIdify();
	const isDesktop = useIsDesktop();
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const sortedLocales = useMemo(
		() => locales.slice().sort((localeA: string) => (localeA === locale ? -1 : 1)),
		[locale],
	);
	const menuButtonLabelId = idify("language-menu-button-label");
	const languageMenuButtonId = idify("language-menu-button");
	const languageMenuId = idify("langauage-menu");
	return (
		<>
			<Button
				variant="contained"
				ref={buttonRef}
				id={languageMenuButtonId}
				aria-controls={menuOpen ? languageMenuId : undefined}
				aria-haspopup="true"
				aria-expanded={menuOpen ? "true" : undefined}
				aria-describedby={menuButtonLabelId}
				onClick={() => {
					setMenuOpen(true);
				}}
				endIcon={<LanguageIcon />}
				sx={(theme) => ({
					"&.MuiButton-contained": {
						backgroundColor: "background.default",
						color: "text.secondary",
					},
					"&.MuiButton-contained:hover": {
						backgroundColor: "background.default",
						backgroundImage: `linear-gradient(${theme.palette.action.disabledBackground},${theme.palette.action.disabledBackground})`,
					},
					position: "absolute",
					right: isDesktop ? theme.spacing(14.5) : theme.spacing(2.5),
					top: `calc(${theme.spacing(3)} + var(--safe-top))`,
					borderRadius: 0,
					borderBottom: `solid 1px ${theme.palette.divider}`,
					zIndex: "tooltip",
				})}
			>
				{t(`languages_label.${locale}`)}
			</Button>
			<Menu
				id={languageMenuId}
				anchorEl={buttonRef.current}
				open={menuOpen}
				onClose={() => {
					setMenuOpen(false);
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				{sortedLocales.map((localeCode) => {
					const href = new URL(currentUrl).pathname.replace(new RegExp(`^/${locale}(?=(/|$))`), `/${localeCode}`);
					return (
						<MenuItem
							selected={localeCode === locale}
							component="a"
							key={localeCode}
							href={href}
							onClick={() => {
								setMenuOpen(false);
							}}
						>
							{t(`languages_label.${localeCode}`)}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
}
