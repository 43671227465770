import { SvgIcon, SvgIconProps } from "@mui/material";

export default function TrashIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRTrashIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.7208 1H7.27924L6.27924 4H2V6H4V23H20V6H22V4H17.7208L16.7208 1ZM15.6126 4H8.38743L8.72076 3H15.2792L15.6126 4ZM11 18V9H9V18H11ZM15 18V9H13V18H15Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
