import useAccount from "@hooks/useAccount";
import { ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import UnitsOfMeasureInput from "./UnitsOfMeasurementInput";

export default function EditUnitsOfMeasurementSettingsListItem() {
	const { t } = useTranslation("common");
	const userAccount = useAccount();
	return (
		<ListItem sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
			<ListItemText
				primary={
					<Typography variant="body2" sx={{ color: "text.secondary" }}>
						{t("account_settings.units_of_measurement_label")}
					</Typography>
				}
			/>
			<UnitsOfMeasureInput useMetric={userAccount?.units_override === "metric"} />
		</ListItem>
	);
}
