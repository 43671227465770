import { editPassword } from "@api/account";
import Dialog from "@components/Dialog";
import PasswordField from "@components/PasswordField";
import { useTranslation } from "next-i18next";
import EditSettingsForm from "../SettingsComponents/EditSettingsForm";

type EditPasswordDialogProps = {
	open: boolean;
	onClose: () => void;
};

type EditPasswordFormValues = {
	current_password: string;
	new_password: string;
};
export default function EditPasswordDialog({ open, onClose }: EditPasswordDialogProps) {
	const { t } = useTranslation("common");
	const submitter = async (values: EditPasswordFormValues) => {
		await editPassword({
			password: values.current_password,
			"new-password": values.new_password,
		});
		return undefined;
	};
	return (
		<Dialog
			open={open}
			onClose={onClose}
			title={t("edit_password.dialog_title")}
			subtitle={t("edit_password.dialog_subtitle")}
		>
			<EditSettingsForm<EditPasswordFormValues>
				onClose={onClose}
				submitter={submitter}
				successMessage={t("edit_password.successfully_updated_alert")}
			>
				<PasswordField
					required
					fullWidth
					id="current_password"
					name="current_password"
					label={t("edit_password.current_password_label")}
				/>
				<PasswordField
					name="new_password"
					required
					fullWidth
					id="new_password"
					label={t("edit_password.new_password_label")}
					autoComplete="new-password"
				/>
			</EditSettingsForm>
		</Dialog>
	);
}
