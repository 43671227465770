import { editEmail, EmailNotUnique, PasswordNotValid } from "@api/account";
import { ServerFormError } from "@api/commonErrors";
import { userSettingsPath } from "@api/profile";
import Dialog from "@components/Dialog";
import EmailField from "@components/EmailField";
import PasswordField from "@components/PasswordField";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { useSWRConfig } from "swr";
import EditSettingsForm from "../SettingsComponents/EditSettingsForm";
type EditEmailFormValues = {
	new_email: string;
	password: string;
};
type EditPasswordDialogProps = {
	open: boolean;
	onClose: () => void;
	currentEmail: string | undefined;
};

export default function EditEmailDialog({ open, onClose, currentEmail }: EditPasswordDialogProps) {
	const { mutate } = useSWRConfig();
	const { t } = useTranslation("common");
	const submitter = async (values: EditEmailFormValues) => {
		await editEmail({
			login: values.new_email,
			"login-confirm": values.new_email,
			password: values.password,
		}).catch((error) => {
			if (error instanceof PasswordNotValid) {
				throw new ServerFormError({
					error: t("edit_email.invalid_password_error"),
					fields: { password: [t("edit_email.invalid_password_error")] },
				});
			}
			if (error instanceof EmailNotUnique) {
				throw new ServerFormError({
					error: t("edit_email.email_not_unique"),
					fields: { new_email: [t("edit_email.email_not_unique")] },
				});
			}
			throw error;
		});
		mutate(userSettingsPath);
		return undefined;
	};
	const initialValues = useMemo(
		() => ({
			current_email: currentEmail ?? "",
		}),
		[currentEmail],
	);
	return (
		<Dialog
			open={open}
			onClose={onClose}
			title={t("edit_email.dialog_title")}
			subtitle={t("edit_email.dialog_subtitle")}
		>
			<EditSettingsForm<EditEmailFormValues>
				onClose={onClose}
				submitter={submitter}
				initialValues={initialValues}
				successMessage={t("edit_email.successfully_updated_alert")}
			>
				<Box>
					<Typography sx={{ fontSize: "14px" }}>{t("edit_email.current_email_title")}</Typography>
					<Typography>{currentEmail}</Typography>
				</Box>
				<EmailField name="new_email" label={t("edit_email.new_email_field_label")} />
				<PasswordField />
			</EditSettingsForm>
		</Dialog>
	);
}
