import { useEffect, useState } from "react";

export default function useVisualViewportHeight() {
	const vizViewport = "visualViewport" in window ? visualViewport : undefined;
	const [visualViewportHeight, setVisualViewportHeight] = useState<number | undefined>(vizViewport?.height);

	useEffect(() => {
		if (vizViewport == null) {
			return;
		}

		function onResize() {
			setVisualViewportHeight((previous) => {
				// this will never be true but we introduce type safety for hoisting
				if (vizViewport == null) {
					return previous;
				}
				return vizViewport.height;
			});
		}

		vizViewport.addEventListener("resize", onResize);

		return () => {
			vizViewport.removeEventListener("resize", onResize);
		};
	}, [vizViewport]);

	return visualViewportHeight;
}
