import useLocale from "@hooks/useLocale";
import getSupportLinks from "@lib/util/getSupportLinks";

export type SuggestionType =
	| "role"
	| "identity"
	| "traits"
	| "scenarios-dynamic"
	| "into"
	| "kinks-fetishes"
	| "pronouns"
	| "gender"
	| "sexuality"
	| "languages_spoken";

// TODO re-create this with Zoho deep linked form
// const linkBase =
// 	"https://support.bkdr.com/hc/requests/new?ticket_form_id=12196765202843&tf_subject=Profile%20field%20suggestion&tf_13861022686747=labs_profile-field&tf_13860640603675=labs_";
// function useSuggestionLinks(suggestionType: SuggestionType)
// return `${linkBase}${suggestionType}`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function useSuggestionLinks(_suggestionType: SuggestionType): string {
	const locale = useLocale();
	return getSupportLinks("feedback", locale);
}
