import safeSx from "@lib/util/safeSx";
import { LinearProgress, SxProps, Theme } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
type LinearLoaderProps = {
	sx?: SxProps<Theme>;
};

export default function LinearLoader({ sx }: LinearLoaderProps) {
	const { t } = useTranslation("common");
	return (
		<>
			<LinearProgress
				sx={safeSx(
					{
						backgroundColor: palette.Primary,
						backgroundImage: `linear-gradient(${palette.surfaceContainer60}, ${palette.surfaceContainer60})`,
						".MuiLinearProgress-bar": {
							backgroundColor: palette.Primary,
						},
					},
					sx,
				)}
			/>
			<p className="sr-only" role="alert">
				{t("loading")}
			</p>
		</>
	);
}
