import { useSyncExternalStore } from "react";
import { createPortal } from "react-dom";
import { BehaviorSubject, Subject, distinctUntilChanged, map, merge, scan } from "rxjs";
import Delayed from "./Delayed";
import LinearLoader from "./LinearLoader";

type LoadingReason = {
	name: string;
};

const activeLoadingReasons = new Set<LoadingReason>();

const setLoadingStream = new Subject<LoadingReason>();
const unsetLoadingStream = new Subject<LoadingReason>();
const clearLoadingStream = new Subject<undefined>();

export const loadingBehavior = new BehaviorSubject(false);
merge<((set: Set<LoadingReason>) => Set<LoadingReason>)[]>(
	setLoadingStream.pipe(
		map((loadingReason) => (set) => {
			set.add(loadingReason);
			return set;
		}),
	),
	unsetLoadingStream.pipe(
		map((loadingReason) => (set) => {
			set.delete(loadingReason);
			return set;
		}),
	),
	clearLoadingStream.pipe(
		map(() => (set) => {
			set.clear();
			return set;
		}),
	),
)
	.pipe(
		scan((activeLoadingReasons, update) => {
			return update(activeLoadingReasons);
		}, activeLoadingReasons),
	)
	.pipe(map((set) => set.size > 0))
	.pipe(distinctUntilChanged())
	.subscribe(loadingBehavior);

export function indicateLoading(reasonName: string) {
	// make an object for the unique reference
	const reason = { name: reasonName };
	setLoadingStream.next(reason);
	return () => {
		unsetLoadingStream.next(reason);
	};
}

export function testOnlyClearLoading() {
	if (process.env.NODE_ENV !== "test") {
		throw new Error(
			"testOnlyClearLoading must not be called outside of test environment. All loaders must be cleaned up directly.",
		);
	}
	clearLoadingStream.next(undefined);
}

function subscribe(listener: () => void) {
	const subscription = loadingBehavior.subscribe(listener);
	return () => {
		subscription.unsubscribe();
	};
}

function getLoading() {
	return loadingBehavior.value;
}

function getSSRLoadingValue() {
	return false;
}

const ELEMENT_ID = "components/GlobalLoader/root";

let loaderRootEl: HTMLDivElement | null;

function getLoadingRootEl(): HTMLDivElement {
	if (loaderRootEl == null) {
		// queryselect to grab previous makes this code safer to re-run.
		const el = document.querySelector<HTMLDivElement>(`[data-testid="${ELEMENT_ID}"]`) ?? document.createElement("div");
		el.dataset.testid = ELEMENT_ID;
		document.body.appendChild(el);
		// various modals will add aria-hidden to top level elements.
		// doesn't seem to be a way to exclude something.
		loadingBehavior.subscribe(() => {
			el.removeAttribute("aria-hidden");
		});
		loaderRootEl = el;
	}
	return loaderRootEl;
}

export default function GlobalLoader() {
	// with getSSRLoadingValue, loading is only ever true in a browser rendering environment
	const loading = useSyncExternalStore(subscribe, getLoading, getSSRLoadingValue);

	if (loading) {
		const el = getLoadingRootEl();
		return createPortal(
			<Delayed>
				<LinearLoader
					sx={{ position: "fixed", top: "env(safe-area-inset-top, 0)", left: 0, right: 0, zIndex: 10000 }}
				/>
			</Delayed>,
			el,
		);
	}
	return null;
}
