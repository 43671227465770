import { InTheMomentStatus } from "@lib/models";
import { useTranslation } from "next-i18next";
import { CurrentImageElement } from "./CurrentMediaElement";
import CurrentVideo from "./CurrentVideo";
import ITMCProcessingMedia from "./ITMCProcessingMedia";

export default function ITMCActiveUpdateMedia({ itmc }: { itmc: InTheMomentStatus }) {
	const { t } = useTranslation("common");

	if (itmc.processing) {
		return <ITMCProcessingMedia mediaType={itmc.media_type} />;
	}
	return (
		<>
			{itmc.full === null ? null : (
				<CurrentImageElement sx={{ mx: "auto" }} alt={t("in_the_moment.current_photo_alt")} src={itmc.full} />
			)}
			{itmc.video === null ? null : <CurrentVideo videoUrl={itmc.video} />}
		</>
	);
}
