import { Button } from "@components/Button";
import LocationArrowIcon from "@components/icons/LocationArrowIcon";
import { Tooltip } from "@mui/material";
import palette from "@styles/palette";
import mapboxgl, { LngLatLike } from "mapbox-gl";
import { useTranslation } from "next-i18next";
import useMapboxConfig from "./useMapboxConfig";

type CenterControlProps = {
	mapbox: mapboxgl.Map;
	center: LngLatLike;
};

export default function CenterControl({ mapbox, center }: CenterControlProps) {
	const { t } = useTranslation("common");
	const mapConfig = useMapboxConfig();
	const tooltipTitle = t("map.recenter_map_button_hidden_label");
	return (
		<Tooltip arrow placement="left" title={tooltipTitle}>
			<Button
				icon
				variant="secondary ghost"
				aria-label={tooltipTitle}
				onClick={() => {
					mapbox.easeTo({
						center,
						zoom: mapConfig.zoom,
					});
				}}
				sx={{
					backdropFilter: "blur(4px)",
					backgroundColor: palette.SurfaceContainerOpacity15,
				}}
			>
				<LocationArrowIcon />
			</Button>
		</Tooltip>
	);
}
