import GlobalFeed from "@feature/Feed/GlobalFeed";
import GlobalMap from "@feature/map/GlobalMap";
import { Outlet } from "react-router-dom";

export default function RootComponent() {
	return (
		<>
			<GlobalMap />
			<Outlet />
			<GlobalFeed />
		</>
	);
}
