import makeAction from "@lib/util/frp/makeAction";
import reportError from "@lib/util/reportError";
import { map } from "rxjs";
import { defaultState, PhotoMedia, State, View } from "../types";

const [backAction, backActionSubject] = makeAction<void>();

export { backAction };

function setView(view: View, state: State) {
	return {
		...state,
		view,
	};
}

export const backActionObservable = backActionSubject.pipe(
	map(() => (state: State): State => {
		// no op errors
		if (state.view === "closed" || state.view === "saving") {
			reportError(new Error(`Tried to do ITMC back action while view is ${state.view}!`));
			return state;
		}

		// confirmation handling
		if (state.confirmation !== false) {
			return {
				...state,
				confirmation: false,
			};
		}
		if (
			(state.view === "text input" && state.text !== "") ||
			(state.view === "media recorder" && state.media != null)
		) {
			return {
				...state,
				confirmation: "back",
			};
		}

		// back actions
		if (state.view === "text input") {
			if (state.media == null) {
				return defaultState;
			}
			return setView("ready to save media", state);
		}
		if (state.view === "media recorder") {
			return defaultState;
		}
		if (state.view === "photo crop") {
			return setView("media preview", state);
		}
		if (state.view === "media preview") {
			return setView("media recorder", state);
		}
		if (state.view === "ready to save media") {
			if (state.text !== "") {
				return setView("text input", state);
			}
			if (state.media instanceof PhotoMedia) {
				return setView("photo crop", state);
			}
			// else === VideoMedia
			return setView("media preview", state);
		}

		reportError(new Error(`Tried to go back from an unexpected state! ${JSON.stringify(state)}`));
		return state;
	}),
);
