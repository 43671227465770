import { get } from "@lib/httpClient";
import { FeedItem, Location } from "@lib/models";

export async function getFeed(radius: number, location: Location) {
	const params = new URLSearchParams({
		radius: String(radius),
		lng: String(location.lng),
		lat: String(location.lat),
	});
	return get<unknown, FeedItem[]>(`/feed?${params.toString()}`);
}
