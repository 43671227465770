import { TextField } from "@mui/material";
import { useTranslation } from "next-i18next";
import { Field } from "react-final-form";
import { dontDisplay } from ".";
import options from "../options.json";

export default function SafetyField() {
	const { t } = useTranslation("common");

	return (
		<Field
			name="safety"
			type="select"
			render={({ input }) => {
				return (
					<TextField select label={t("profile.safety_label")} SelectProps={{ native: true }} {...input}>
						<option value={dontDisplay}>{t("profile_edit.dont_display")}</option>
						{options.safety.map((option) => (
							<option key={option} value={option}>
								{t(`safety.${option}`)}
							</option>
						))}
					</TextField>
				);
			}}
		/>
	);
}
