import { CaptchaFailure, EmailNotUnique, createAccount } from "@api/account";
import Alert from "@components/Alert";
import { Button } from "@components/Button";
import useAuth from "@hooks/useAuth";
import AcceptLegalField from "@lib/auth/Signup/AcceptLegalField";
import AgeVerificationField from "@lib/auth/Signup/AgeVerificationField";
import useAlertState from "@lib/feature/alert/useAlertState";
import evaluateCaptcha from "@lib/util/captcha";
import { makeHandleFormSubmit } from "@lib/util/makeHandleFormSubmit";
import { track } from "@lib/util/trackAnalytics";
import { Box } from "@mui/material";
import { FORM_ERROR } from "final-form";
import createDecorator from "final-form-focus";
import { useTranslation } from "next-i18next";
import { Form } from "react-final-form";

type BirthdateFormValues = {
	birthdate: string;
};

const focusOnError = createDecorator<BirthdateFormValues>();

export default function BirthdateForm() {
	const { t } = useTranslation("common");
	const { onLogin } = useAuth();

	const [alert, updateAlert] = useAlertState();

	const onSubmit = makeHandleFormSubmit<BirthdateFormValues>({
		submitter: async (values: BirthdateFormValues) => {
			const token = await evaluateCaptcha("create_account");
			await createAccount({
				token,
				birthdate: values.birthdate,
				anon: true,
			});
			track("created_anon_user");
			onLogin();
			return undefined;
		},
		updateAlert,
		successMessage: null,
		unknownErrorMessage: t("unknown_error"),
		errorHandlers: [
			{
				errorType: EmailNotUnique,
				result: t("email.not_unique"),
			},
			{
				errorType: CaptchaFailure,
				result: (error) => {
					track("captcha_failure", { reason: error.message });
					return t("unknown_error");
				},
			},
		],
	});

	return (
		<Form<BirthdateFormValues>
			decorators={[focusOnError]}
			onSubmit={onSubmit}
			// force validation failure on first render
			validate={(values) => (values.birthdate == null ? { [FORM_ERROR]: true } : undefined)}
			render={({ handleSubmit, valid }) => {
				return (
					<Box
						component="form"
						onSubmit={handleSubmit}
						sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(2) }}
					>
						<AgeVerificationField showAgeHelper />
						<AcceptLegalField />
						{alert == null ? null : <Alert key={alert.key} severity={alert.severity} body={alert.children} />}
						<Button type="submit" variant="primary" size="lg" disabled={!valid}>
							{t("continue_button")}
						</Button>
					</Box>
				);
			}}
		/>
	);
}
