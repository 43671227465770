import AppLayout from "@components/AppLayout";
import useAccount from "@hooks/useAccount";
import { ShowGlobalLoader } from "@hooks/useGlobalLoader";
import assertType from "@lib/util/assertType";
import { track } from "@lib/util/trackAnalytics";
import { Box } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Channel } from "stream-chat";
import {
	ChannelListMessenger,
	ChannelPreview,
	ChannelPreviewUIComponentProps,
	ChatDown,
	LoadMorePaginator,
	useChannelHiddenListener,
	useChannelTruncatedListener,
	useChannelUpdatedListener,
	useChatContext,
	useConnectionRecoveredListener,
	useMessageNewListener,
	useNotificationMessageNewListener,
} from "stream-chat-react";
import type { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import ChatChannelPreview from "./ChatChannelPreview";
import EmptyList from "./EmptyList";
import { Avatar } from "./streamOverride/Avatar";
import { usePaginatedChannels } from "./streamOverride/usePaginatedChannels";
import useMessageDeletedListener from "./useMessageDeletedListener";

const DEFAULT_OPTIONS = { presence: true };
const DEFAULT_SORT = {};
const EARLIEST_DATE = "1970-01-01T00:00:00.00Z";

type StreamChatGenerics = DefaultStreamChatGenerics;

export default function ChatsList() {
	const navigate = useNavigate();
	const user = assertType(useAccount());
	// Date filter on last_message_at filters out channels with no messages
	const filters = { members: { $in: [user.uuid] }, last_message_at: { $gt: EARLIEST_DATE } };
	/**
	 * Hack from stream chat code
	 * For some events, inner properties on the channel will update but the shallow comparison will not
	 * force a re-render. Incrementing this dummy variable ensures the channel previews update.
	 */
	const [channelUpdateCount, setChannelUpdateCount] = useState(0);
	const forceUpdate = () => setChannelUpdateCount((count) => count + 1);

	const { channelsQueryState, client } = useChatContext<StreamChatGenerics>("ChannelList");

	const { channels, hasNextPage, loadNextPage, setChannels, refresh } = usePaginatedChannels(
		client,
		filters,
		DEFAULT_SORT,
		DEFAULT_OPTIONS,
		() => undefined,
	);

	const loadedChannels = channels;

	useChannelTruncatedListener(setChannels, undefined, forceUpdate);
	useChannelUpdatedListener(setChannels, undefined, forceUpdate);
	useConnectionRecoveredListener(forceUpdate);
	useNotificationMessageNewListener(setChannels);
	useMessageDeletedListener(setChannels);
	useMessageNewListener(setChannels);
	useChannelHiddenListener(setChannels);

	const renderChannel = (channel: Channel<StreamChatGenerics>) => {
		const memberUUIDs = Object.keys(channel.state.members);
		const friendUUID = memberUUIDs.find((uuid) => uuid !== user.uuid);
		// if friend id is not available, it's probably due to being blocked, and we
		// can ignore this channel
		if (friendUUID == null) {
			return null;
		}

		async function hideThisChannel() {
			await channel.hide();
			await channel.markRead();
			track("hide_channel_clicked", { uuid: friendUUID });
		}

		const previewProps = {
			Avatar,
			channel: channel,
			// forces the update of preview component on channel update
			channelUpdateCount,
			Preview: (props: ChannelPreviewUIComponentProps<DefaultStreamChatGenerics>) => {
				return (
					<ChatChannelPreview
						{...props}
						hideChannel={hideThisChannel}
						uuid={friendUUID}
						onBlock={() => {
							refresh();
						}}
					/>
				);
			},
			async onSelect() {
				navigate(`/chat/${friendUUID}`);
			},
			watchers: {},
		};

		return <ChannelPreview key={channel.id} {...previewProps} />;
	};

	return (
		<AppLayout>
			<Box
				sx={(theme) => ({
					height: "100%",
					overflow: "auto",
					".str-chat__channel-preview-messenger": {
						boxShadow: `0 1px 0 0 ${theme.palette.grey[800]}`,
					},
				})}
			>
				{channelsQueryState.queryInProgress == null && (loadedChannels?.length ?? 0) === 0 ? (
					<EmptyList />
				) : (
					<ChannelListMessenger
						error={channelsQueryState.error}
						loading={channelsQueryState.queryInProgress === "reload"}
						LoadingErrorIndicator={ChatDown}
						LoadingIndicator={ShowGlobalLoader}
						setChannels={setChannels}
					>
						<LoadMorePaginator
							hasNextPage={hasNextPage}
							loadNextPage={loadNextPage}
							isLoading={channelsQueryState.queryInProgress === "load-more"}
						>
							{loadedChannels.map((channel) => renderChannel(channel))}
						</LoadMorePaginator>
					</ChannelListMessenger>
				)}
			</Box>
		</AppLayout>
	);
}
