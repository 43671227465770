import { deleteProfileImage, updatePinnedPicture } from "@api/profile";
import { refreshAccountRegister } from "@feature/accountRegisterModel";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import reportError from "@lib/util/reportError";
import { track } from "@lib/util/trackAnalytics";
import { useTranslation } from "next-i18next";
import { useState } from "react";

export default function usePhotoUpdate(uuid: string) {
	const { t } = useTranslation("common");
	const updateAlert = useUpdateAlert();
	const [saving, setSaving] = useState(false);

	async function blockingSave(action: () => Promise<void>) {
		if (saving) {
			return;
		}
		setSaving(true);
		try {
			await action();
			await refreshAccountRegister();
		} catch (error) {
			reportError(error as Error);
			updateAlert(t("unknown_error"));
		} finally {
			setSaving(false);
		}
	}

	function setPinned(pinned: boolean) {
		blockingSave(async () => {
			await updatePinnedPicture({ uuid, pinned });

			track(pinned ? "profile_photo_pinned" : "profile_photo_unpinned");
		});
	}

	function deletePhoto() {
		blockingSave(async () => {
			await deleteProfileImage({ uuid });

			track("profile_photo_deleted");
		});
	}

	return {
		saving,
		setPinned,
		deletePhoto,
	};
}
