import useObservable from "@hooks/useObservable";
import { ReactNode } from "react";
import { accountRegisterErrorObservable, accountRegisterObservable } from "./accountRegisterModel";

export default function InitialDataLoader({ children }: { children: ReactNode }) {
	const accountRegister = useObservable(accountRegisterObservable);
	const accountRegisterError = useObservable(accountRegisterErrorObservable);

	if (accountRegisterError != null && accountRegister == null) {
		throw accountRegisterError;
	}

	if (accountRegister == null) {
		return null;
	}

	return children;
}
