import makeAction from "@lib/util/frp/makeAction";
import reportError from "@lib/util/reportError";
import { VideoMedia } from "../types";
import updateState from "./util/updateState";

const [setVideoAction, videoActionSubject] = makeAction<Blob>();

export { setVideoAction };

export const videoMediaActionObservable = videoActionSubject.pipe(
	updateState((blob, state) => {
		if (state.view === "media recorder") {
			return {
				...state,
				media: new VideoMedia(blob),
				view: "media preview",
			};
		}
		reportError(new Error("Tried to set ITMC video from wrong view!"));
		return state;
	}),
);
