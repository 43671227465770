import { LocaleCode } from "@lib/models";
import languages from "@lib/util/languages";

function isLocale(code: string): code is LocaleCode {
	return languages.includes(code as LocaleCode);
}

export function getLocale() {
	const locale = document.documentElement.lang;

	if (isLocale(locale)) {
		return locale;
	}

	throw new Error("locale not found!");
}

export function useLocaleWithCountry() {
	const language = useLocale();
	let country = "";
	if (typeof navigator !== "undefined") {
		const languageAndCountry = navigator.language.split("-");
		if (languageAndCountry.length > 1) {
			country = `-${languageAndCountry[1]}`;
		}
	}

	return `${language.slice(0, 2)}${country}`;
}

export default function useLocale(): LocaleCode {
	return getLocale();
}
