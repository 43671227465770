import Alert from "@components/Alert";
import { globalAlertActionObervable } from "@feature/alert/setGlobalAlert";
import { NotificationProvider, useUpdateAlert } from "@lib/feature/alert/notificationContext";
import { RenderableAlertType } from "@lib/feature/alert/types";
import useAlertState from "@lib/feature/alert/useAlertState";
import useAutoDismissValid from "@lib/feature/alert/useAutoDismissValid";
import { ReactNode, createContext, useContext, useEffect } from "react";
import AlertContainer from "./AlertContainer";

const alertContext = createContext<RenderableAlertType | null | undefined>(undefined);
const AlertProvider = alertContext.Provider;

type AppAlertProviderProps = {
	children: ReactNode;
};

export function AppAlertProvider({ children }: AppAlertProviderProps) {
	const [alert, updateAlert] = useAutoDismissValid(useAlertState());

	useEffect(() => {
		const subscription = globalAlertActionObervable.subscribe((alert) => {
			updateAlert(alert?.children, alert?.severity);
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [updateAlert]);

	return (
		<AlertProvider value={alert}>
			<NotificationProvider value={updateAlert}>{children}</NotificationProvider>
		</AlertProvider>
	);
}

export function AppLayoutAlert() {
	const alert = useContext(alertContext);
	const updateAlert = useUpdateAlert();
	if (alert === undefined) {
		throw new Error("AppLayoutAlert must be used a descendent of AppAlertProvider");
	}
	if (alert === null) {
		return null;
	}
	return (
		<AlertContainer>
			<Alert
				key={alert.key}
				severity={alert.severity}
				body={alert.children}
				onClose={() => {
					updateAlert();
				}}
			/>
		</AlertContainer>
	);
}
