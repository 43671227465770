import useIdify from "@hooks/useIdify";
import useLocale from "@hooks/useLocale";
import errorHelper from "@lib/util/errorHelper";
import getDateFormat, { DateFormat } from "@lib/util/getDateFormat";
import { TextField } from "@mui/material";
import { InputMask, type InputMaskProps } from "@react-input/mask";
import { differenceInYears, format, formatISO, isValid, parse, parseISO } from "date-fns";
import { useTranslation } from "next-i18next";
import { forwardRef, useMemo, useState } from "react";
import { Field } from "react-final-form";

const MIN_AGE = 18;
const MAX_AGE = 120;

function formatISODate(date: Date) {
	return formatISO(date, { representation: "date" });
}

function validate(value: string | undefined) {
	if (value === "incomplete") {
		return [{ t: "field_required_error" }];
	}
	if (value === "invalid") {
		return [{ t: "birthdate.invalid_date_error" }];
	}
	if (value === "too young") {
		return [{ t: "birthdate.validation_too_young" }];
	}
	return undefined;
}

const ForwardedInputMask = forwardRef<
	HTMLInputElement,
	InputMaskProps & { value: string | undefined; onChange: (value: string) => void; dateFormat: DateFormat }
>(function WrappedInputMask({ value: formValue, onChange: formOnChange, dateFormat, ...otherProps }, forwardedRef) {
	const [value, onChange] = useState(() => {
		const date = parseISO(formValue ?? "");
		if (isValid(date)) {
			return format(date, dateFormat.format);
		}
		return dateFormat.mask;
	});
	return (
		<InputMask
			ref={forwardedRef}
			mask={dateFormat.mask}
			replacement={{ _: /\d/ }}
			showMask
			{...otherProps}
			autoComplete="off"
			inputMode="numeric"
			value={value}
			onChange={(event) => {
				const string = event.target.value;
				onChange(string);
				if (string === "" || /_/.test(string)) {
					formOnChange("incomplete");
					return;
				}
				const date = parse(string, dateFormat.format, new Date(0));
				if (!isValid(date)) {
					formOnChange("invalid");
					return;
				}
				const diff = differenceInYears(new Date(), date);
				if (diff >= MIN_AGE && diff < MAX_AGE) {
					formOnChange(formatISODate(date));
				} else {
					formOnChange("too young");
				}
			}}
		/>
	);
});

type AgeVerificationFieldProps = {
	showAgeHelper?: boolean;
};

export default function AgeVerificationField({ showAgeHelper = false }: AgeVerificationFieldProps) {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const locale = useLocale();
	const dateFormat = useMemo(() => getDateFormat(locale), [locale]);
	// new line is necessary for formatting, to have the date format helper on its own line
	const helperText = `${t(dateFormat.helper)}
${showAgeHelper ? t("birthdate.field_helper") : ""}`;
	return (
		<Field
			name="birthdate"
			validate={validate}
			render={({ input, meta }) => {
				return (
					<TextField
						sx={{ mt: "8px" }}
						id={idify("signup-age-input")}
						variant="outlined"
						type="text"
						label={t("birthdate.field_label")}
						placeholder={dateFormat.helper}
						inputProps={{
							dateFormat,
							onChange: input.onChange,
							value: input.value,
						}}
						InputProps={{
							inputComponent: ForwardedInputMask as unknown as "input",
						}}
						InputLabelProps={{
							shrink: true,
						}}
						FormHelperTextProps={{
							sx: {
								whiteSpace: "pre-line",
							},
						}}
						{...input}
						{...errorHelper(t, helperText, meta)}
					/>
				);
			}}
		/>
	);
}
