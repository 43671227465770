import DialogButton from "@components/DialogButton";
import useIdify from "@hooks/useIdify";
import { useTranslation } from "next-i18next";
import SettingsListItem from "../SettingsComponents/SettingsListItem";
import EditPasswordDialog from "./EditPasswordDialog";

export default function EditPasswordSettingsListItem() {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const passwordLabelId = idify("password");
	return (
		<SettingsListItem
			title={t("account_settings.password")}
			subtitle={
				<>
					<span aria-hidden>**************</span>
					<span className="sr-only">{t("account_settings.hidden_password")}</span>
				</>
			}
			labelId={passwordLabelId}
			listItemSx={{ paddingBottom: 0 }}
		>
			<DialogButton
				aria-describedby={passwordLabelId}
				buttonProps={{ size: "sm", variant: "primary" }}
				renderDialog={(open, onClose) => {
					return <EditPasswordDialog open={open} onClose={onClose} />;
				}}
				dialogRoutingName="edit-password"
			>
				{t("edit_button")}
			</DialogButton>
		</SettingsListItem>
	);
}
