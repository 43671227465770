import makeAction from "@lib/util/frp/makeAction";
import reportError from "@lib/util/reportError";
import { PixelCrop } from "react-image-crop";
import { PhotoMedia } from "../types";
import updateState from "./util/updateState";

const [setCropAction, cropActionSubject] = makeAction<PixelCrop>();

export { setCropAction };

export const cropActionObservable = cropActionSubject.pipe(
	updateState((crop, state) => {
		const media = state.media;
		if (state.view === "photo crop" && media instanceof PhotoMedia) {
			return {
				...state,
				view: "ready to save media",
				media: media.setCrop(crop),
			};
		}
		if (state.view !== "photo crop") {
			reportError(new Error(`Tried to set ITMC crop from wrong view. Expected: "photo crop", found: "${state.view}"`));
		} else {
			reportError(new Error("Tried to set ITMC crop without setting a photo first!"));
		}
		return state;
	}),
);
