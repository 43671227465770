import ErrorPage from "@components/ErrorPage";
import AnonSignUp from "@feature/anon/anonSignUp/AnonSignUp";
import ChatChannel from "@feature/chat/ChatChannel";
import ChatRoot from "@feature/chat/ChatRoot";
import ChatsList from "@feature/chat/ChatsList";
import EditView from "@feature/profile/EditView";
import NowRedirect from "@feature/redirect/NowRedirect";
import SettingsView from "@feature/settings/SettingsView";
import ForgotPassword from "@lib/auth/ForgotPassword";
import Login from "@lib/auth/Login";
import ResetPassword from "@lib/auth/ResetPassword";
import Signup from "@lib/auth/Signup";
import { Outlet } from "react-router-dom";
import RootComponent from "./RootComponent";
import { LandingRoute, RequireNoUser, RequireNonAnon, RequireNotRegionBlocked, RequireUser } from "./needsLoaderRoutes";

export default [
	{
		path: "/",
		element: <RootComponent />,
		errorElement: <ErrorPage error="404" />,
		children: [
			// prettier
			{ index: true, element: <LandingRoute /> },
			{
				path: "forgot-password",
				element: <ForgotPassword />,
			},
			{
				path: "reset-password",
				element: <ResetPassword />,
			},
			{
				path: "redirect",
				element: <NowRedirect />,
			},
			{
				element: <RequireNoUser />,
				children: [
					{
						path: "login",
						element: <Login />,
					},
					{
						element: <RequireNotRegionBlocked route="/" />,
						children: [
							{
								path: "anon-sign-up",
								element: <AnonSignUp />,
							},
							{
								path: "sign-up",
								element: <Signup />,
							},
						],
					},
				],
			},
			{
				element: <RequireUser />,
				children: [
					// Routes that require a user (anon or logged in)
					{
						path: "map",
						element: null,
					},
					{
						element: <RequireNotRegionBlocked route="/map" />,
						children: [
							{
								path: "feed",
								element: null,
							},
						],
					},
					{
						path: "chat",
						element: (
							<ChatRoot>
								<Outlet />
							</ChatRoot>
						),
						children: [
							{
								index: true,
								element: <ChatsList />,
							},
							{
								path: ":uuid",
								element: <ChatChannel />,
							},
						],
					},
					{
						element: <RequireNonAnon />,
						children: [
							// routes that require a logged in user
							{
								path: "account/profile",
								element: <EditView />,
							},
							{
								path: "account/settings",
								element: <SettingsView />,
							},
						],
					},
				],
			},
		],
	},
];
