import { Button } from "@components/Button";
import Dialog from "@components/Dialog";
import { DialogActions } from "@mui/material";
import { useTranslation } from "next-i18next";

type DeleteConfirmationDialogProps = {
	open: boolean;
	onClose: () => void;
	onDelete: () => void;
};

export default function DeleteConfirmationDialog({ open, onClose, onDelete }: DeleteConfirmationDialogProps) {
	const { t } = useTranslation("common");

	return (
		<Dialog open={open} onClose={onClose} title={t("profile.photo_delete_confirmation_header")}>
			<p>{t("profile.photo_delete_confirmation_body")}</p>
			<DialogActions>
				<Button variant="primary ghost" onClick={onClose}>
					{t("cancel_button")}
				</Button>
				<Button
					variant="primary"
					onClick={() => {
						onClose();
						onDelete();
					}}
				>
					{t("delete_button")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
