import FriendProfileDialog from "@components/ProfileDialog/FriendProfileDialog";
import useIdify from "@hooks/useIdify";
import { Box, IconButton } from "@mui/material";
import clsx from "clsx";
import { Trans, useTranslation } from "next-i18next";
import React, { memo, useRef } from "react";
import { ChannelPreviewUIComponentProps, Avatar as DefaultAvatar } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import ChatPreviewMoreMenu from "./ChatPreviewMoreMenu";
const AVATAR_SIZE = 72;
const AVATAR_SIZE_WITH_PADDING = AVATAR_SIZE + 24;
const MORE_MENU_SIZE = 30;

const UnMemoizedChannelPreviewMessenger = <
	StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics,
>(
	props: ChannelPreviewUIComponentProps<StreamChatGenerics> & {
		hideChannel: () => void;
		uuid: string;
		onBlock: () => void;
	},
) => {
	const {
		active,
		Avatar = DefaultAvatar,
		channel,
		className: customClassName = "",
		displayImage,
		displayTitle,
		latestMessage,
		onSelect: customOnSelectChannel,
		setActiveChannel,
		unread,
		watchers,
		hideChannel,
		uuid,
		onBlock,
	} = props;

	const { t } = useTranslation("common");
	const idify = useIdify();
	const displayNameId = idify("display-name");
	const channelPreviewButton = useRef<HTMLButtonElement | null>(null);

	const avatarName = displayTitle || channel.state.messages[channel.state.messages.length - 1]?.user?.id;

	const onSelectChannel = (e: React.MouseEvent<HTMLButtonElement>) => {
		if (customOnSelectChannel) {
			customOnSelectChannel(e);
		} else if (setActiveChannel) {
			setActiveChannel(channel, watchers);
		}
		if (channelPreviewButton?.current) {
			channelPreviewButton.current.blur();
		}
	};

	return (
		<Box
			sx={{ display: "flex", flexDirection: "space-between", alignItems: "center" }}
			className="str-chat__channel-preview-messenger"
		>
			<FriendProfileDialog
				renderOpener={(onOpen) => (
					<IconButton
						disableRipple
						onClick={onOpen}
						aria-label={t("profile.view_profile_screen_reader_button")}
						aria-describedby={displayNameId}
					>
						<Avatar image={displayImage} name={avatarName} size={AVATAR_SIZE} />
					</IconButton>
				)}
				uuid={uuid}
				hideChatButton={false}
				onBlock={onBlock}
				source="inbox"
			/>
			<Box
				component="button"
				type="button"
				aria-selected={active}
				className={clsx(
					"str-chat__channel-preview",
					active && "str-chat__channel-preview-messenger--active",
					unread && unread >= 1 && "str-chat__channel-preview-messenger--unread",
					customClassName,
				)}
				sx={(theme) => ({
					// the max width is whatever is left of the parent after the width of the Avatar and MoreMenu
					// necessary to constrain size of message preview and force an ellipses overflow
					maxWidth: `calc(100% - ${AVATAR_SIZE_WITH_PADDING + MORE_MENU_SIZE}px)`,
					"&.str-chat__channel-preview": {
						flex: 1,
						minHeight: theme.spacing(7.5),
						py: 1.25,
						px: 1,
					},
				})}
				style={{ flex: 1 }}
				data-testid="channel-preview-button"
				onClick={onSelectChannel}
				ref={channelPreviewButton}
				role="option"
			>
				<div className="str-chat__channel-preview-messenger--right str-chat__channel-preview-end">
					<div className="str-chat__channel-preview-end-first-row">
						<div className="str-chat__channel-preview-messenger--name">
							<span id={displayNameId}>{displayTitle}</span>
						</div>
						{!!unread && (
							<div className="str-chat__channel-preview-unread-badge" data-testid="unread-badge">
								<Trans
									i18nKey={"chat.unread_count_with_screen_reader_label"}
									components={[<span className="sr-only" key="0" />]}
									values={{
										count: unread,
									}}
								/>
							</div>
						)}
					</div>
					<Box
						className="str-chat__channel-preview-messenger--last-message"
						// creates empty space where the timestamp will be
						sx={(theme) => ({ pr: theme.spacing(4.5) })}
					>
						{latestMessage}
					</Box>
				</div>
			</Box>
			<ChatPreviewMoreMenu hideChannel={hideChannel} />
		</Box>
	);
};

/**
 * Used as preview component for channel item in [ChannelList](#channellist) component.
 * Its best suited for messenger type chat.
 */
export const ChatChannelPreview = memo(UnMemoizedChannelPreviewMessenger) as typeof UnMemoizedChannelPreviewMessenger;
export { ChatChannelPreview as default };
