import makeAction from "@lib/util/frp/makeAction";
import reportError from "@lib/util/reportError";
import updateState from "./util/updateState";

const [setTextAction, textActionSubject] = makeAction<string>();

export { setTextAction };

export const textActionObservable = textActionSubject.pipe(
	updateState((text, state) => {
		if (state.view === "text input") {
			return {
				...state,
				text,
			};
		}
		reportError(new Error("Tried to set ITMC photo from wrong view!"));
		return state;
	}),
);
