import { getLocale } from "@hooks/useLocale";
import { locationAssign, locationReplace } from "@lib/util/locationWrapper";
import { useEffect } from "react";

type DirectProps = {
	href: string;
	replace?: boolean;
};

/**
 * @deprecated
 */

export default function Direct({ href, replace = false }: DirectProps) {
	useEffect(() => {
		const location = `/${getLocale()}${href}`;
		if (replace) {
			locationReplace(location);
		}
		locationAssign(location);
	}, [href, replace]);
	return null;
}
