import { ServerFormError } from "@api/commonErrors";
import { uploadMedia } from "@api/media";
import { AddInTheMomentStatusRequest, addInTheMomentStatus } from "@api/profile";
import Alert from "@components/Alert";
import { Button } from "@components/Button";
import { PANE_VERTICAL_SPACING } from "@components/Pane/PaneSection";
import { refreshOwnUser } from "@feature/accountRegisterModel";
import useIdify from "@hooks/useIdify";
import useUser from "@hooks/useUser";
import * as feedModel from "@lib/feature/Feed/feedModel";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import { updateAlertType } from "@lib/feature/alert/types";
import useAlertState from "@lib/feature/alert/useAlertState";
import { UploadPhotoDataType } from "@lib/models";
import assertType from "@lib/util/assertType";
import checkForMobile from "@lib/util/checkForMobile";
import { makeHandleFormSubmit } from "@lib/util/makeHandleFormSubmit";
import { Box, DialogActions, Link, Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import createDecorator from "final-form-focus";
import { useTranslation } from "next-i18next";
import { Form } from "react-final-form";
import useAutoDismissValid from "../alert/useAutoDismissValid";
import PhotoField from "./PhotoField";
import StatusMessageField from "./StatusMessageField";
import VideoField from "./VideoField";

type InTheMomentFormValues = {
	message?: string;
	image?: UploadPhotoDataType;
	video?: Blob;
};

const focusOnError = createDecorator<InTheMomentFormValues>();
const formDecorators = [focusOnError];

type InTheMomentProps = {
	onClose: (reason: "cancel" | "success") => void;
	onOpenAnonRegister: () => void;
};

export default function InTheMomentForm({ onClose, onOpenAnonRegister }: InTheMomentProps) {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const user = assertType(useUser());
	const [, appUpdateAlert] = useAutoDismissValid([null, useUpdateAlert()]);
	const [alert, localUpdateAlert] = useAlertState();
	const updateAlert: updateAlertType = function updateAlert(message, variant) {
		if (variant === "success") {
			appUpdateAlert(message, variant);
			return;
		}
		localUpdateAlert(message, variant);
	};
	const isDesktop = !checkForMobile();

	const onSubmit = makeHandleFormSubmit<InTheMomentFormValues>({
		async submitter(values: InTheMomentFormValues) {
			const params: AddInTheMomentStatusRequest = { in_the_moment_status: { message: values.message } };

			if (values.image != null) {
				const mediaKey = await uploadMedia(values.image.file);

				params.in_the_moment_status.image = {
					id: mediaKey,
					metadata: {
						crop: {
							x: values.image.x,
							y: values.image.y,
							width: values.image.width,
							height: values.image.height,
						},
					},
				};
			} else if (values.video != null) {
				const mediaKey = await uploadMedia(values.video);

				params.in_the_moment_status.video = {
					id: mediaKey,
				};
			}

			await addInTheMomentStatus(params);
			if (values.video != null) {
				appUpdateAlert(t("in_the_moment.recording_upload_started"), "info");
			}
			if (values.image == null && values.video == null) {
				updateAlert(t("in_the_moment.status_posted_success_message"), "success");
			}
			await refreshOwnUser();
			feedModel.refresh();
			onClose("success");
			return undefined;
		},
		successMessage: null, // success message sent by server over realtime for photos
		unknownErrorMessage: t("unknown_error"),
		updateAlert,
		errorHandlers: [
			{
				errorType: ServerFormError,
				result: (error) => {
					// TODO find better errorHandlers type
					return (error as ServerFormError).data.error;
				},
			},
		],
	});

	return (
		<Form
			onSubmit={onSubmit}
			decorators={formDecorators}
			validate={(values) => {
				if (values.image == null && values.video == null && [undefined, ""].includes(values.message)) {
					// non display error, this is used for disabling submission
					return {
						[FORM_ERROR]: "empty",
					};
				}
				return {};
			}}
			render={({ handleSubmit, hasValidationErrors, error, submitting, values }) => {
				const formEmpty = error === "empty";
				return (
					<Box
						component="form"
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: PANE_VERTICAL_SPACING,
						}}
						onSubmit={
							formEmpty
								? (event) => {
										event.preventDefault();
										updateAlert(t("in_the_moment.must_include_message_or_photo_error"), "error");
									}
								: handleSubmit
						}
					>
						{user.is_anon ? <Typography variant="body2">{t("anon_mode.info_modal_body")}</Typography> : null}
						<Typography variant="body2">{t("in_the_moment.add_dialog_subtitle")}</Typography>
						<Box
							sx={{
								position: "relative",
								display: "flex",
								justifyContent: "center",
								gap: "16px",
							}}
						>
							{values.video == null && !isDesktop ? <PhotoField disabled={isDesktop} /> : null}
							{values.image == null ? <VideoField /> : null}
						</Box>
						<StatusMessageField />
						{alert == null ? null : <Alert key={alert.key} severity={alert.severity} body={alert.children} />}
						{formEmpty ? (
							<p id={idify("empty_form_helper")} hidden>
								{t("in_the_moment.must_include_message_or_photo_error")}
							</p>
						) : null}
						<DialogActions>
							<Button
								variant="primary ghost"
								type="button"
								onClick={() => {
									onClose("cancel");
								}}
								disabled={submitting}
							>
								{t("cancel_button")}
							</Button>
							<Button
								variant="primary"
								type="submit"
								aria-describedby={formEmpty ? idify("empty_form_helper") : ""}
								disabled={submitting || hasValidationErrors}
							>
								{t("save_button")}
							</Button>
						</DialogActions>
						{user.is_anon ? (
							<DialogActions>
								<Link component="button" type="button" onClick={onOpenAnonRegister}>
									{t("forgot_password.create_account_link")}
								</Link>
							</DialogActions>
						) : null}
					</Box>
				);
			}}
		/>
	);
}
