import { PhotoSizeSelectActual } from "@mui/icons-material";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
export default function ProfilePhotoLoader({ primary = false }: { primary?: boolean }) {
	const { t } = useTranslation("common");
	return (
		<Box
			data-testid="profile-photo-loader"
			sx={(theme) => ({
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				margin: "0 auto",
				borderRadius: theme.spacing(0.5),
				height: "100%",
				width: "100%",
				position: "absolute",
				color: "common.white",
			})}
		>
			<PhotoSizeSelectActual fontSize={primary ? "large" : "medium"} />
			{primary ? (
				<>
					<Typography
						sx={(theme) => ({
							textAlign: "center",
							fontSize: theme.spacing(2),
						})}
					>
						{t("profile.photo_loading_placeholder")}
					</Typography>
					<Box
						sx={(theme) => ({
							width: "100%",
							mt: theme.spacing(2),
							px: theme.spacing(1),
							height: theme.spacing(4.5),
							background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 100%)",
							display: "flex",
							alignItems: "center",
							position: "absolute",
							bottom: 0,
						})}
					>
						<LinearProgress
							data-testid="profile-photo-loader-bar"
							sx={(theme) => ({ width: "100%", height: theme.spacing(0.75) })}
							color="secondary"
						/>
					</Box>
				</>
			) : (
				<LinearProgress
					data-testid="profile-photo-loader-bar"
					sx={(theme) => ({
						width: `calc(100% - ${theme.spacing(1)})`,
						height: theme.spacing(0.25),
						position: "absolute",
						bottom: theme.spacing(1),
					})}
					color="secondary"
				/>
			)}
		</Box>
	);
}
