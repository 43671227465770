import { useState } from "react";

export default function usePhotoWizardDialogState() {
	const [selectedImage, setSelectedImage] = useState<File | null>(null);
	return {
		onSelectImage: (imageFile: File) => {
			setSelectedImage(imageFile);
		},
		photoWizardDialogState: {
			imageFile: selectedImage,
			onClose() {
				setSelectedImage(null);
			},
		},
	};
}
