import useIdify from "@hooks/useIdify";
import useLocale from "@hooks/useLocale";
import { getLocationHref } from "@lib/util/locationWrapper";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Button, Menu, MenuItem } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import i18next from "next-i18next.config.mjs";
import { useMemo, useRef, useState } from "react";
const locales = i18next.i18n.locales.slice(1);

export default function LanguageMenu() {
	const currentUrl = getLocationHref();
	const locale = useLocale();
	const { t } = useTranslation("common");
	const idify = useIdify();
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const sortedLocales = useMemo(
		() => locales.slice().sort((localeA: string) => (localeA === locale ? -1 : 1)),
		[locale],
	);
	const menuButtonLabelId = idify("language-menu-button-label");
	const languageMenuButtonId = idify("language-menu-button");
	const languageMenuId = idify("langauage-menu");
	return (
		<>
			<Button
				variant="contained"
				size="small"
				disableRipple
				ref={buttonRef}
				id={languageMenuButtonId}
				aria-controls={menuOpen ? languageMenuId : undefined}
				aria-haspopup="true"
				aria-expanded={menuOpen ? "true" : undefined}
				aria-describedby={menuButtonLabelId}
				onClick={() => {
					setMenuOpen(true);
				}}
				endIcon={<ExpandMore />}
				sx={{
					"&.MuiButton-contained": {
						backgroundColor: palette.SurfaceContainerOpacity15,
						color: palette.BKDRCoreBrandWhite,
						backdropFilter: "blur(60px)",
					},
					"&.MuiButton-contained:hover": {
						backgroundColor: palette.SurfaceContainerOpacity15,
						backgroundImage: "unset",
					},
					position: "static",
					height: "fit-content",
					minWidth: "110px",
					borderRadius: 0,
					boxShadow: "unset",
					zIndex: "tooltip",
				}}
			>
				{t(`languages.${locale}`)}
			</Button>
			<Menu
				id={languageMenuId}
				slotProps={{
					paper: {
						sx: {
							backgroundColor: palette.SurfaceContainerHigh,
						},
					},
				}}
				anchorEl={buttonRef.current}
				open={menuOpen}
				onClose={() => {
					setMenuOpen(false);
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				{sortedLocales.map((localeCode) => {
					const href = new URL(currentUrl).pathname.replace(new RegExp(`^/${locale}(?=(/|$))`), `/${localeCode}`);
					return (
						<MenuItem
							sx={{
								backgroundColor: "transparent",
								"&.Mui-selected": {
									backgroundColor: palette.SurfaceContainerHigh,
								},
								"&.Mui-focusVisible": {
									backgroundColor: palette.Primary,
								},
							}}
							selected={localeCode === locale}
							component="a"
							key={localeCode}
							href={href}
							onClick={() => {
								setMenuOpen(false);
							}}
						>
							{t(`languages_label.${localeCode}`)}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
}
