import { exploreLocationObservable } from "@feature/exploreMode/exploreOverrideModel";
import useChannel, { ChannelName } from "@feature/realtimeHandling/useChannel";
import useObservable from "@hooks/useObservable";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import { Button } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { combineLatest, map, Observable } from "rxjs";
import * as feedModel from "./feedModel";

type ChannelConfig = {
	channel: ChannelName;
	radius: number;
	lng: number;
	lat: number;
};

const channelConfigObservable: Observable<undefined | ChannelConfig> = combineLatest([
	feedModel.radiusBehavior,
	exploreLocationObservable,
]).pipe(
	map(([radius, location]) =>
		location == null ? undefined : { channel: "FeedChannel" as const, radius, lng: location.lng, lat: location.lat },
	),
);

export default function NewFeedItemsIndicator() {
	const { t } = useTranslation("common");
	const [visible, setVisible] = useState(false);
	const channelConfig = useObservable(channelConfigObservable);

	useEffect(() => {
		const subscription = feedModel.feedStream.subscribe(() => {
			setVisible(false);
		});
		return () => {
			subscription.unsubscribe();
		};
	}, []);

	useChannel(channelConfig ?? null, () => {
		setVisible(true);
	});

	if (!visible) {
		return null;
	}

	return (
		<Button
			variant="contained"
			color="secondary"
			size="small"
			startIcon={<ArrowUpward />}
			sx={(theme) => ({
				position: "absolute",
				top: theme.spacing(9.5),
				left: "50%",
				transform: "translateX(-50%)",
				zIndex: "fab",
			})}
			onClick={() => {
				feedModel.refresh();
			}}
		>
			{t("feed.new_posts_button")}
		</Button>
	);
}
