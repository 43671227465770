import { SvgIcon, SvgIconProps } from "@mui/material";

export default function UserPenIcon(props: SvgIconProps) {
	return (
		<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" data-testid="BKDRUserPenIcon" {...props}>
			<path
				d="M12 1C9.23858 1 7 3.23858 7 6C7 8.76142 9.23858 11 12 11C14.7614 11 17 8.76142 17 6C17 3.23858 14.7614 1 12 1Z"
				fill="currentColor"
			/>
			<path
				d="M12 12C6.47715 12 2 16.4772 2 22V23H11V18.7573L16.6255 13.1318C15.2423 12.4088 13.6689 12 12 12Z"
				fill="currentColor"
			/>
			<path d="M19.2426 23L21.8685 20.3741C21.955 20.9033 22 21.4464 22 22V23H19.2426Z" fill="currentColor" />
			<path d="M23.4142 16L20 12.5858L13 19.5858V23H16.4142L23.4142 16Z" fill="currentColor" />
		</SvgIcon>
	);
}
