import HeaderBar from "@components/HeaderBar";
import { getMap } from "@lib/feature/map/mapbox";
import { OtherUser, UserAccount } from "@lib/models";
import { track } from "@lib/util/trackAnalytics";
import profileClickAccountInfo, { ProfileViewSource } from "@lib/util/trackingHelpers/profileClickAccountInfo";
import { Card, CardActions, CardContent } from "@mui/material";
import palette from "@styles/palette";
import { useEffect } from "react";
import FriendBottomActions from "./FriendBottomActions";
import ProfileInfo from "./ProfileInfo";
import ProfilePicture from "./ProfilePicture";
import UserBottomActions from "./UserBottomActions";

export type ProfileCardProps = {
	onClose: () => void;
	onBlock?: (successMessage: string) => void;
	source: ProfileViewSource;
} & (
	| {
			profileType: "user";
			profile: UserAccount;
			hideChatButton: false;
			onBlock?: never;
	  }
	| {
			profileType: "friend";
			profile: OtherUser;
			hideChatButton: boolean;
			onBlock: (successMessage: string) => void;
	  }
);

export default function ProfileCard({
	profile,
	profileType,
	onClose,
	hideChatButton,
	onBlock,
	source,
}: ProfileCardProps) {
	useEffect(() => {
		track(
			"profile_viewed",
			profileClickAccountInfo(profile, {
				source,
				ownProfile: profileType === "user",
				zoomLevel: source === "map" ? getMap()?.getZoom() : undefined,
			}),
		);
	}, [profile, profileType, source]);

	return (
		<Card
			sx={{
				overflowY: "auto",
				position: "relative",
				display: "flex",
				flexDirection: "column",
				height: "100%",
			}}
		>
			<HeaderBar onBack={onClose} />
			<ProfilePicture account={profile} profileType={profileType} />
			<CardContent sx={{ py: 0, display: "flex", flexDirection: "column", gap: 3 }}>
				<ProfileInfo profile={profile} />
			</CardContent>
			<CardActions
				sx={{
					mt: "auto",
					position: "sticky",
					bottom: "0px",
					height: "56px",
					justifyContent: "flex-end",
					alignItems: "center",
					bgcolor: palette.Surface,
					zIndex: 2,
					"& .MuiSnackbar-root": {
						marginLeft: 0,
					},
				}}
			>
				{profileType === "friend" ? (
					<FriendBottomActions profile={profile} hideChatButton={hideChatButton} onBlock={onBlock} />
				) : (
					<UserBottomActions />
				)}
			</CardActions>
		</Card>
	);
}
