import makeLocalStorageHelper from "@lib/util/makeLocalStorageHelper";
export type ITMCTutorialState = { state: "not seen" | "seen once" | "seen" };
export type ITMCTutorialStateOption = ITMCTutorialState["state"];

export const itmcTutorialKey = "/feature/itmc/tutorialState";
const { get, set } = makeLocalStorageHelper<ITMCTutorialState>(itmcTutorialKey);

export function getTutorialState(): ITMCTutorialStateOption {
	const temp = get();
	return temp == null ? "not seen" : temp.state;
}

export function setTutorialState(state: ITMCTutorialStateOption) {
	set({ state });
}
