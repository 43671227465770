import useIdify from "@hooks/useIdify";
import deleteFromSet from "@lib/util/deleteFromSet";
import { Box, Chip } from "@mui/material";
import { useTranslation } from "next-i18next";
import ChipList from "./ChipList";
import { MultiSelectConfig } from "./configTypes";

type CurrentSelectionProps = {
	config: MultiSelectConfig;
	currentValueSet: Set<string>;
	setCurrentValueSet: (update: (prev: Set<string>) => Set<string>) => void;
};

export default function CurrentSelection({ config, currentValueSet, setCurrentValueSet }: CurrentSelectionProps) {
	const idify = useIdify();
	const { t } = useTranslation("common");

	return (
		<>
			<span className="sr-only" id={idify("current_list_description")}>
				{t("multi_select_dialog.current_selection_screen_reader_helper")}
			</span>
			<span className="sr-only" hidden id={idify("deselect_instructions")}>
				{t("multi_select_dialog.active_screen_reader_helper")}
			</span>
			<ChipList aria-describedby={idify("current_list_description")}>
				{Array.from(currentValueSet.values()).map((value) => {
					const label = config.options[value];
					return (
						<Box key={value} component="li" sx={{ listStyle: "none", m: 0, p: 0 }}>
							<Chip
								variant="filled"
								color="primary"
								size="small"
								label={label}
								component="button"
								aria-describedby={idify("deselect_instructions")}
								onClick={() => {
									setCurrentValueSet((current) => {
										return deleteFromSet(current, value);
									});
								}}
								onDelete={() => {
									setCurrentValueSet((current) => {
										return deleteFromSet(current, value);
									});
								}}
							/>
						</Box>
					);
				})}
			</ChipList>
		</>
	);
}
