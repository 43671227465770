import { SvgIcon, SvgIconProps } from "@mui/material";

export default function PenIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRPenIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path d="M23.4142 5.00015L19 0.585938L15.5 4.08594L19.9142 8.50015L23.4142 5.00015Z" fill="currentColor" />
			<path d="M18.5 9.91436L14.0858 5.50015L1 18.5859V23.0001H5.41421L18.5 9.91436Z" fill="currentColor" />
		</SvgIcon>
	);
}
