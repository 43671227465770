import FieldSubmitError from "@components/FieldSubmitError";
import useIdify from "@hooks/useIdify";
import errorHelper from "@lib/util/errorHelper";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { useTranslation } from "next-i18next";
import { Field } from "react-final-form";

export default function AboutMeField() {
	const { t } = useTranslation("common");
	const idify = useIdify();

	return (
		<Field
			name="about_me"
			type="text"
			render={({ input, meta }) => {
				const { error, helperText } = errorHelper(t, t("profile.about_me_field_helper"), meta);
				const inputId = idify("profile_about_me");
				const helperId = idify("profile_about_me_helper");
				const submitErrorId = idify("profile_about_me_submit_error");
				return (
					<FormControl variant="outlined" fullWidth error={error}>
						<TextField
							id={inputId}
							label={t("profile.bio_field_label")}
							error={error}
							aria-describedby={`${helperId} ${submitErrorId}`}
							multiline
							placeholder={t("profile.about_me_field_placeholder")}
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{
								maxLength: "500",
							}}
							{...input}
						/>
						<FormHelperText id={helperId}>{helperText}</FormHelperText>
						<FieldSubmitError id={submitErrorId} meta={meta} />
					</FormControl>
				);
			}}
		/>
	);
}
