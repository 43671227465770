import { SvgIcon, SvgIconProps } from "@mui/material";

export default function CameraIcon(props: SvgIconProps) {
	return (
		<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" data-testid="BKDRCameraIcon" {...props}>
			<path
				d="M10 13C10 11.8954 10.8954 11 12 11C13.1046 11 14 11.8954 14 13C14 14.1046 13.1046 15 12 15C10.8954 15 10 14.1046 10 13Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.9217 3H8.07826L6.96715 5H1V21H23V5H17.0328L15.9217 3ZM12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
