import QuestionSquareIcon from "@components/icons/QuestionSquareIcon";
import useLocale from "@hooks/useLocale";
import getSupportLinks from "@lib/util/getSupportLinks";
import { MenuItem } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";

export default function AppMenuSupportLink({ onClose }: { onClose: () => void }) {
	const locale = useLocale();
	const { t } = useTranslation("common");

	return (
		<MenuItem component="a" href={getSupportLinks("general", locale)} target="_blank" onClick={onClose}>
			<QuestionSquareIcon />
			<Trans t={t} i18nKey={"app_bar.support_link"} components={[<span className="sr-only" key="0" />]} />
		</MenuItem>
	);
}
