import { subscribeToRegionBlockedEmails } from "@api/userRegionSubscriptions";
import { Button } from "@components/Button";
import EmailField from "@components/EmailField";
import PaneHeading from "@components/Pane/PaneHeading";
import { PaneSection } from "@components/Pane/PaneSection";
import { useUpdateAlert } from "@feature/alert/notificationContext";
import { makeHandleFormSubmit } from "@lib/util/makeHandleFormSubmit";
import { track } from "@lib/util/trackAnalytics";
import { FORM_ERROR } from "final-form";
import createDecorator from "final-form-focus";
import { useTranslation } from "next-i18next";
import { Form } from "react-final-form";
import ConfirmAgeField from "./ConfirmAgeField";
type RegionBlockedFormValues = {
	email: string;
};

const focusOnError = createDecorator<RegionBlockedFormValues>();
type RegionBlockedEmailForm = {
	onSuccess: () => void;
};

export default function RegionBlockedEmailForm({ onSuccess }: RegionBlockedEmailForm) {
	const { t } = useTranslation("common");
	const updateAlert = useUpdateAlert();

	const onSubmit = makeHandleFormSubmit<RegionBlockedFormValues>({
		submitter: async (values) => {
			await subscribeToRegionBlockedEmails({ user_region_subscription: { email: values.email } });
			track("region_blocked_email_submitted");
			onSuccess();
			return undefined;
		},
		updateAlert,
		successMessage: null,
		unknownErrorMessage: t("unknown_error"),
	});

	return (
		<>
			<PaneHeading title={t("landing_region_blocked.title")} subtitle={t("landing_region_blocked.subtitle")} />
			<Form
				onSubmit={onSubmit}
				validate={(values) => (values.email == null ? { [FORM_ERROR]: true } : undefined)}
				decorators={[focusOnError]}
				render={({ handleSubmit, hasValidationErrors, submitting }) => {
					return (
						<PaneSection
							as="form"
							onSubmit={handleSubmit}
							sx={{ mt: "24px", gap: "24px", "& > div:first-of-type": { mb: "-10px" } }}
						>
							<EmailField showHelperOnlyOnError />
							<ConfirmAgeField />
							<Button
								type="submit"
								formNoValidate={true}
								disabled={submitting || hasValidationErrors}
								variant="primary"
							>
								{t("landing_region_blocked.get_notified_button")}
							</Button>
						</PaneSection>
					);
				}}
			/>
		</>
	);
}
