import AppLayout from "@components/AppLayout";
import { Box } from "@mui/material";
import { useTranslation } from "next-i18next";
import ProfileForm from "./ProfileForm";
import ProfilePicManager from "./ProfilePicManager";

export default function EditView() {
	const { t } = useTranslation("common");
	return (
		<AppLayout pageHeading={t("profile_edit.page_heading")} homeButtonTarget="map" noBottomNavigation>
			<Box
				component="main"
				sx={{
					flex: "1 1 auto",
					position: "relative",
					display: "flex",
					flexDirection: "column",
					overflow: "auto",
					px: 2.5,
					"> *": {
						flex: "0 0 auto",
						maxWidth: "480px",
						width: "100%",
						mx: "auto",
						marginTop: 2.5,
					},
				}}
			>
				<ProfilePicManager />
				<ProfileForm />
			</Box>
		</AppLayout>
	);
}
