import useIsImperial from "@hooks/useIsImperial";
import { mToFeetIn } from "@lib/util/conversionHelpers";
import { TextField } from "@mui/material";
import { TFunction, useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Field } from "react-final-form";
import { dontDisplay } from ".";

const metricInitialMeters = 0.6;
const metricMaxMeters = 2.4;
const metricMeterIncrement = 0.01;
const imperialInitialMeters = 0.6096;
const imperialMaxMeters = 2.4384;
const imperialMeterIncrement = 0.0254;

interface SizeFieldOption {
	value: number;
	label: string;
}

function range(start: number, stop: number, step: number) {
	return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
}

function heightOptionValues(t: TFunction, imperial: boolean): SizeFieldOption[] {
	let options: SizeFieldOption[] = [];

	if (imperial) {
		options = range(imperialInitialMeters, imperialMaxMeters, imperialMeterIncrement).map((i) => ({
			value: i,
			label: t("profile.height_imperial", mToFeetIn(i)),
		}));
	} else {
		options = range(metricInitialMeters, metricMaxMeters, metricMeterIncrement).map((i) => ({
			value: i,
			label: t("profile.height_metric", { height: Math.round(i * 100) }),
		}));
	}

	return options;
}

export default function HeightField() {
	const { t } = useTranslation("common");
	const isImperial = useIsImperial();

	const options = useMemo(() => {
		return heightOptionValues(t, isImperial);
	}, [t, isImperial]);

	return (
		<Field
			name="height"
			type="select"
			render={({ input }) => {
				const optionValues = options.map((o) => o.value);
				let value = input.value;

				// Find closest value if initial value is not present in list
				// Can happen to a user that changes locales
				if (!optionValues.includes(input.value) && input.value !== dontDisplay) {
					value = optionValues.reduce((prev, curr) =>
						Math.abs(curr - input.value) < Math.abs(prev - input.value) ? curr : prev,
					);
				}

				return (
					<TextField
						select
						label={t("profile.height_label")}
						SelectProps={{ native: true }}
						{...input}
						value={value}
						fullWidth
					>
						<option value={dontDisplay}>{t("profile_edit.dont_display")}</option>
						{options.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</TextField>
				);
			}}
		/>
	);
}
