import useSuggestionLinks, { SuggestionType } from "@components/MultiSelectField/useSuggestionLinks";
import TranslationExternalLink from "@components/TranslationExternalLink";
import { useTranslation } from "next-i18next";

export default function SuggestionLink({ suggestionType }: { suggestionType: SuggestionType }) {
	const { t } = useTranslation("common");
	const suggestionLink = useSuggestionLinks(suggestionType);
	if (suggestionLink == null) {
		return null;
	}
	return (
		<TranslationExternalLink href={suggestionLink}>
			{t("multi_select_dialog.submit_a_suggestion_link_text")}
		</TranslationExternalLink>
	);
}
