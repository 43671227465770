import { backAction } from "@feature/itmc/postModelAction/backAction";
import { setVideoAction } from "@feature/itmc/postModelAction/setVideoAction";
import { ITMCCaptureType } from "@feature/itmc/types";
import useGlobalLoader from "@hooks/useGlobalLoader";
import { CameraDirection } from "@lib/models";
import { Box } from "@mui/material";
import { useState } from "react";
import useEventCallback from "use-event-callback";
import Controls from "./Controls";
import MediaErrors from "./MediaErrors";
import useMediaHandler from "./useMediaHandler";

type VideoState = "querying" | "canplay";

export default function ITMCMediaRecorder() {
	const [videoState, setVideoState] = useState<VideoState>("querying");
	const [cameraDirection, setCameraDirection] = useState<CameraDirection>("user");
	const [captureType, setCaptureType] = useState<ITMCCaptureType>("video");
	const [recording, setRecording] = useState(false);

	useGlobalLoader(videoState === "querying", "requesting-camera-permissions");

	const onRecorded = useEventCallback((file: Blob) => {
		setVideoAction(file);
	});

	const onCanPlay = useEventCallback(() => {
		setVideoState("canplay");
	});

	const { videoRef, error } = useMediaHandler(cameraDirection, recording, onRecorded, onCanPlay);

	if (error != null) {
		return (
			<Box
				sx={{
					position: "absolute",
					inset: 0,
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<MediaErrors error={error} onGoBack={backAction} />
			</Box>
		);
	}

	return (
		<>
			<Box
				component="video"
				ref={videoRef}
				disablePictureInPicture
				muted
				playsInline
				sx={{
					position: "absolute",
					top: "0px",
					bottom: "0px",
					left: "0px",
					right: "0px",
					margin: "auto",
					aspectRatio: "1/1",
					width: "100%",
					zIndex: 0,
				}}
			/>
			{videoState === "canplay" ? (
				<Box
					sx={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-end",
						gap: "26px",
						paddingBottom: "24px",
					}}
				>
					<Controls
						captureType={captureType}
						recording={recording}
						onCaptureClick={() => {
							if (captureType === "video") {
								setRecording((recording) => !recording);
							}
							// TODO photo capture
						}}
						onCaptureTypeChange={setCaptureType}
						onDirectionClick={() => {
							setCameraDirection((prev) => (prev === "user" ? "environment" : "user"));
						}}
					/>
				</Box>
			) : null}
		</>
	);
}
