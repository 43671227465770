import { Button } from "@components/Button";
import CornerUpLeftIcon from "@components/icons/CornerUpLeftIcon";
import DotsVerticalIcon from "@components/icons/DotsVerticalIcon";
import PenIcon from "@components/icons/PenIcon";
import TrashIcon from "@components/icons/TrashIcon";
import useIdify from "@hooks/useIdify";
import { Menu, MenuItem } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { memo, useRef, useState } from "react";
import {
	DefaultStreamChatGenerics,
	MESSAGE_ACTIONS,
	useChannelActionContext,
	useMessageContext,
} from "stream-chat-react";

export default memo(function MessageActions<
	StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics,
>() {
	const { t } = useTranslation("common");

	const {
		// customMessageActions,
		getMessageActions,
		handleDelete,
		// handleFlag,
		// handleMarkUnread,
		// handleMute,
		// handlePin,
		isMyMessage,
		message,
		setEditingState,
	} = useMessageContext<StreamChatGenerics>("MessageActions");

	const messageActions = getMessageActions();
	const isOwnMessage = isMyMessage();

	const { setQuotedMessage } = useChannelActionContext<StreamChatGenerics>("MessageActionsBox");

	const handleReply = () => {
		setQuotedMessage(message);

		const elements = message.parent_id
			? document.querySelectorAll(".str-chat__thread .str-chat__textarea__textarea")
			: document.getElementsByClassName("str-chat__textarea__textarea");
		const textarea = elements.item(0);

		if (textarea instanceof HTMLTextAreaElement) {
			// This is a *massive* hack to skip the focus enforcement for this focus call.
			// MUI's focus trap (Menu<Popover<Modal<FocusTrap) doesn't offer a way to override
			// or disable it for a specific call.
			textarea.addEventListener(
				"focusin",
				(event) => {
					event.stopPropagation();
				},
				{ once: true },
			);

			// This is what the hack allows to happen.
			textarea.focus();
		}
	};

	const idify = useIdify();
	const menuAnchorRef = useRef<HTMLButtonElement>(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const buttonId = idify("button");
	const menuId = idify("menu");

	return (
		<>
			<Button
				icon
				size="xxs"
				variant="secondary ghost"
				aria-label={t("message_actions_button_screen_reader")}
				ref={menuAnchorRef}
				id={buttonId}
				aria-controls={menuId}
				aria-haspopup="true"
				aria-expanded={menuOpen ? "true" : undefined}
				onClick={(event) => {
					// Stream parents also catch click events for other actions that shouldn't
					// happen when this is clicked. Stream also solves this with stopPropagation.
					event.stopPropagation();
					setMenuOpen(true);
				}}
			>
				<DotsVerticalIcon />
			</Button>
			<Menu
				id={menuId}
				anchorEl={menuAnchorRef.current}
				open={menuOpen}
				onClose={() => setMenuOpen(false)}
				MenuListProps={{
					"aria-labelledby": buttonId,
				}}
				anchorOrigin={{
					vertical: "center",
					horizontal: isOwnMessage ? "left" : "right",
				}}
				transformOrigin={{
					vertical: "center",
					horizontal: isOwnMessage ? "right" : "left",
				}}
				disableRestoreFocus
				onClick={(event) => {
					// Stream parents also catch click events for other actions that shouldn't
					// happen when this is clicked. Stream also solves this with stopPropagation.
					event.stopPropagation();
				}}
				onKeyUp={(event) => {
					// Stream parents also catch keyup events for other actions that shouldn't
					// happen when this is interacted with.
					event.stopPropagation();
				}}
			>
				{messageActions.includes(MESSAGE_ACTIONS.quote) ? (
					<MenuItem
						onClick={() => {
							setMenuOpen(false);
							handleReply();
						}}
					>
						<CornerUpLeftIcon />
						{t("chat.message_action_item_reply")}
					</MenuItem>
				) : null}
				{messageActions.includes(MESSAGE_ACTIONS.edit) ? (
					<MenuItem
						onClick={(event) => {
							setMenuOpen(false);
							setEditingState(event);
						}}
					>
						<PenIcon />
						{t("chat.message_action_item_edit")}
					</MenuItem>
				) : null}
				{messageActions.includes(MESSAGE_ACTIONS.delete) ? (
					<MenuItem
						onClick={(event) => {
							setMenuOpen(false);
							handleDelete(event);
						}}
					>
						<TrashIcon sx={{ color: palette.Primary }} />
						{t("chat.message_action_item_delete")}
					</MenuItem>
				) : null}
			</Menu>
		</>
	);
});
