import reportError from "@lib/util/reportError";

const resetPasswordSessionStorageKey = "/auth/reset-password/state";

function parseOrNull(dataString: string) {
	try {
		return JSON.parse(dataString);
	} catch {
		reportError(new Error(`localStorage parse error: ${resetPasswordSessionStorageKey}: '${dataString}'`));
		return null;
	}
}

export function statusFromPasswordSessionStorage(): "success" | null {
	const stored = sessionStorage.getItem(resetPasswordSessionStorageKey);
	const successFlag: { success?: number } | null = stored == null ? null : parseOrNull(stored);
	const now = Date.now();
	if (successFlag !== null && (successFlag?.success ?? 0) > now) {
		return "success";
	}
	return null;
}

export function saveSuccessToPasswordSessionStorage() {
	sessionStorage.setItem(
		resetPasswordSessionStorageKey,
		JSON.stringify({
			success: Date.now() + 60000, // 60 seconds, enough time for the page to reload
		}),
	);
}

export function clearPasswordSessionStorage() {
	sessionStorage.removeItem(resetPasswordSessionStorageKey);
}
