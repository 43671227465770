import { Button } from "@components/Button";
import PlusSquareIcon from "@components/icons/PlusSquareIcon";
import useIdify from "@hooks/useIdify";
import useObservable from "@hooks/useObservable";
import { Box, FormHelperText, TextField } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { textObservable } from "../itmcPostModel";
import { saveAction } from "../postModelAction/saveAction";
import { setTextAction } from "../postModelAction/setTextAction";
import useVisualViewportHeight from "./useVisualViewportHeight";

const charLimit = 200;

function getRefHeight(...args: RefObject<HTMLElement>[]) {
	return args
		.map((ref) => {
			if (ref.current == null) {
				return 0;
			}
			const { height } = ref.current.getBoundingClientRect();
			return Math.floor(height);
		})
		.reduce((accumulator, current) => {
			return accumulator + current;
		}, 0);
}

export default function ITMCTextEntry() {
	const { t } = useTranslation("common");

	const [isFocused, setIsFocused] = useState(false);
	const [wrapperHeight, setWrapperHeight] = useState<number | null>(null);
	const wrapperRef = useRef<HTMLDivElement>();
	const formHelperRef = useRef<HTMLParagraphElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);
	const previousVisualViewportHeight = useRef<number | null>(null);
	const visualViewportHeight = useVisualViewportHeight();

	const idify = useIdify();
	const inputId = idify("itmc_text_entry");

	const textInput = useObservable(textObservable) ?? "";
	const isDisabled = !textInput.trim() || textInput.length > charLimit;

	const maxRows: number | undefined = useMemo(() => {
		if (wrapperRef.current == null || wrapperHeight == null) {
			return undefined;
		}
		const lineHeight = window.getComputedStyle(wrapperRef.current).lineHeight;
		const buttonAndFormHelperHeight = getRefHeight(buttonRef, formHelperRef);
		return Math.floor((wrapperHeight - buttonAndFormHelperHeight) / parseInt(lineHeight));
	}, [wrapperHeight]);

	useEffect(() => {
		if (
			wrapperRef.current == null ||
			visualViewportHeight == null ||
			previousVisualViewportHeight.current === visualViewportHeight
		) {
			return;
		}

		const { top: wrapperTop } = wrapperRef.current.getBoundingClientRect();
		setWrapperHeight(visualViewportHeight - wrapperTop);
		previousVisualViewportHeight.current = visualViewportHeight;
	}, [maxRows, visualViewportHeight]);

	return (
		<Box
			ref={wrapperRef}
			sx={{
				gap: "8px",
				height: wrapperHeight ?? "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				alignItems: "stretch",
			}}
		>
			<Box
				sx={{
					flex: "1 1 auto",
					display: "relative",
					justifyContent: "flex-start",
					height: "100%",
				}}
			>
				<TextField
					id={inputId}
					data-testid="ITMCTextEntry"
					placeholder={
						isFocused ? t("in_the_moment.text_input_focused_placeholder") : t("in_the_moment.text_input_placeholder")
					}
					onChange={(event) => {
						setTextAction(event.target.value);
					}}
					multiline
					maxRows={maxRows}
					slotProps={{
						input: {
							onFocus() {
								setIsFocused(true);
							},
							onBlur() {
								setIsFocused(false);
							},
							sx: { padding: 0, caretColor: palette.Primary },
						},
					}}
					sx={{
						backgroundColor: "transparent",
						border: "none",
						color: palette.onPrimary,
						width: "100%",
						padding: 0,
						fontSize: "18px",
						"& fieldset": {
							border: "none",
							padding: 0,
						},
						"::placeholder": {
							color: palette.OnSurfaceSecondary,
						},
					}}
				/>
				<FormHelperText
					ref={formHelperRef}
					sx={{
						color: textInput.length > charLimit ? palette.Primary : palette.OnSurfaceSecondary,
						fontSize: "10px",
						marginTop: "12px",
					}}
				>
					{t("in_the_moment.remaining_character_ratio", {
						remainingCharacterCount: textInput.length,
						totalCharactersAllowed: charLimit,
					})}
				</FormHelperText>
			</Box>
			<Button
				ref={buttonRef}
				size="lg"
				variant="primary"
				onClick={() => saveAction()}
				disabled={isDisabled}
				sx={{ my: "8px" }}
			>
				{t("in_the_moment.post_update")}
				<PlusSquareIcon />
			</Button>
		</Box>
	);
}
