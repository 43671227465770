import { styled } from "@mui/material";

export default styled("ul")({
	margin: 0,
	padding: 0,
	display: "flex",
	flexWrap: "wrap",
	alignContent: "flex-start",
	gap: "16px 8px",
	"> li": {
		listStyle: "none",
	},
	".MuiChip-root": {
		borderWidth: "1px",
		borderStyle: "solid",
	},
});
