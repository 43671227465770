import { Button } from "@components/Button";
import CameraIcon from "@components/icons/CameraIcon";
import RefreshIcon from "@components/icons/RefreshIcon";
import VideoIcon from "@components/icons/VideoIcon";
import { ITMCCaptureType } from "@feature/itmc/types";
import useIdify from "@hooks/useIdify";
import { Box } from "@mui/material";
import { useTranslation } from "next-i18next";
import CaptureButton from "./CaptureButton";

type ControlsProps = {
	captureType: ITMCCaptureType;
	onCaptureClick: () => void;
	onCaptureTypeChange: (captureType: ITMCCaptureType) => void;
	onDirectionClick: () => void;
	recording: boolean;
};

export default function Controls({
	captureType,
	onCaptureClick,
	onCaptureTypeChange,
	onDirectionClick,
	recording,
}: ControlsProps) {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const captureTypeDescriptionId = idify("capture-type-description");
	return (
		<>
			<Box
				sx={{
					zIndex: 0,
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-end",
				}}
			>
				<p id={captureTypeDescriptionId} className="sr-only">
					{t("video_capture.media_type_buttons_screen_reader_helper")}
				</p>
				<Button
					icon
					variant="tertiary"
					size="md"
					aria-pressed={captureType === "video"}
					active={captureType === "video"}
					aria-describedby={captureTypeDescriptionId}
					aria-label={t("video_capture.video_button")}
					onClick={() => {
						onCaptureTypeChange("video");
					}}
				>
					<VideoIcon />
				</Button>
				<Button
					icon
					variant="tertiary"
					size="md"
					aria-pressed={captureType === "camera"}
					active={captureType === "camera"}
					aria-describedby={captureTypeDescriptionId}
					aria-label={t("video_capture.camera_button")}
					onClick={() => {
						onCaptureTypeChange("camera");
					}}
				>
					<CameraIcon />
				</Button>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
				<CaptureButton recording={recording} captureType={captureType} onClick={onCaptureClick} />
				<Button
					icon
					variant="secondary ghost"
					size="md"
					aria-label={t("video_capture.flip_camera")}
					onClick={onDirectionClick}
					sx={{
						position: "absolute",
						right: 0,
					}}
				>
					<RefreshIcon />
				</Button>
			</Box>
		</>
	);
}
