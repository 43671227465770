import SnackbarProvider from "@lib/feature/alert/SnackbarProvider";
import { UserShort } from "@lib/models";
import { List } from "@mui/material";
import BlockedUser from "./BlockedUser";

type BlockedUsersProps = {
	blockedUsers: UserShort[];
};

export default function BlockedUsers({ blockedUsers }: BlockedUsersProps) {
	return (
		<SnackbarProvider>
			<List sx={{ overflowX: "auto" }}>
				{blockedUsers.map((userShort) => {
					return <BlockedUser key={userShort.uuid} userShort={userShort} />;
				})}
			</List>
		</SnackbarProvider>
	);
}
