import { ServerFormError } from "@api/commonErrors";
import { uploadMedia } from "@api/media";
import { addProfilePicture } from "@api/profile";
import { refreshOwnUser } from "@feature/accountRegisterModel";
import { updateAlertType } from "@lib/feature/alert/types";
import { UploadProfilePhotoDataType } from "@lib/models";
import { makeHandleFormSubmit } from "@lib/util/makeHandleFormSubmit";
import { track } from "@lib/util/trackAnalytics";
import { useTranslation } from "next-i18next";
import { useState } from "react";

export default function useProfilePhotoUpload(updateAlert: updateAlertType) {
	const { t } = useTranslation("common");

	const handleSubmit = makeHandleFormSubmit<UploadProfilePhotoDataType>({
		submitter: async (photoUploadData: UploadProfilePhotoDataType) => {
			const mediaKey = await uploadMedia(photoUploadData.file);

			await addProfilePicture({
				profile_photo: {
					image: {
						id: mediaKey,
						metadata: {
							crop: {
								x: photoUploadData.x,
								y: photoUploadData.y,
								width: photoUploadData.width,
								height: photoUploadData.height,
							},
						},
					},
					pinned: photoUploadData.pinned,
				},
			});

			track("profile_photo_uploaded", { pinned: photoUploadData.pinned });

			return undefined;
		},
		updateAlert,
		successMessage: null, // success message sent by server over realtime
		unknownErrorMessage: t("unknown_error"),
		errorHandlers: [
			{
				errorType: ServerFormError,
				result: (error) => {
					// TODO find better errorHandlers type
					return (error as ServerFormError).data.error;
				},
			},
		],
	});

	const [saving, setSaving] = useState(false);

	async function handleSubmitWithLoading(photoUploadData: UploadProfilePhotoDataType) {
		setSaving(true);
		return handleSubmit(photoUploadData)
			.then(
				async (formErrors) => {
					// if the update didn't crash, then we also want to capture the user mutate time
					await refreshOwnUser();
					return formErrors;
				},
				(error) => {
					// if the update crashes, we want to update the user, but timing doesn't really matter
					// and we can't really handle an error if it happened anyway
					refreshOwnUser();
					throw error;
				},
			)
			.finally(() => {
				setSaving(false);
			});
	}

	return {
		saving,
		handleSubmit: handleSubmitWithLoading,
	};
}
