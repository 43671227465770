import { getAccountRegister } from "@api/account";
import { indicateLoading } from "@components/GlobalLoader";
import { AccountRegister, FeatureFlags, UserAccount } from "@lib/models";
import OptimisticUpdate from "@lib/util/optimisticUpdate";
import reportError from "@lib/util/reportError";
import {
	BehaviorSubject,
	ReplaySubject,
	Subject,
	filter,
	firstValueFrom,
	from,
	share,
	startWith,
	switchMap,
} from "rxjs";

const stopLoading = indicateLoading("accountRegister request");

const refreshObservable = new Subject<void | AccountRegister>();

export const accountRegisterErrorObservable = new Subject<Error>();

export const accountRegisterObservable = refreshObservable
	.pipe(startWith(null)) // must be passed a value to do anything
	.pipe(
		switchMap((maybeAccountRegister) => {
			const requestStream = from(
				getAccountRegister()
					.catch((error) => {
						reportError(error);
						accountRegisterErrorObservable.next(
							error instanceof Error ? error : Object.assign(new Error(String(error)), { error }),
						);
						return new Error();
					})
					.finally(() => {
						stopLoading();
					}),
			).pipe(filter((value): value is AccountRegister => !(value instanceof Error)));
			if (maybeAccountRegister != null) {
				return requestStream.pipe(startWith(maybeAccountRegister));
			}
			return requestStream;
		}),
	)
	.pipe(share({ connector: () => new ReplaySubject<AccountRegister>(1) }));

export const accountRegisterBehavior = new BehaviorSubject<AccountRegister | null>(null);

export class OptimisticAccountRegister extends OptimisticUpdate implements AccountRegister {
	user?: UserAccount;
	flags: FeatureFlags;
	constructor(accountRegister: AccountRegister) {
		super();
		this.user = accountRegister.user;
		this.flags = accountRegister.flags;
	}
}

/**
 * refresh for full account register. If you only care about the user, use refreshOwnUser instead
 * If you pass in an update function, the accountRegister will update optimistically with it applied
 * and then again once loaded from the server
 */
export async function refreshAccountRegister(
	updateAccountRegister?: (accountRegister: AccountRegister) => AccountRegister,
) {
	// this will always be immediate if a user is already loaded
	let currentAccountRegister: null | AccountRegister = null;
	const subscription = accountRegisterObservable.subscribe((accountRegister) => {
		currentAccountRegister = accountRegister;
	});
	// immediately unsubscribing because we only care about a synchronous value
	subscription.unsubscribe();
	if (updateAccountRegister != null) {
		if (currentAccountRegister == null) {
			throw new Error("No current account register to optimistically update!");
		}
		refreshObservable.next(new OptimisticAccountRegister(updateAccountRegister(currentAccountRegister)));
	} else {
		refreshObservable.next();
	}

	return firstValueFrom(
		accountRegisterObservable.pipe(
			// skip if same as current and if optimisticallyUpdated is set -> wait for actual update
			filter(
				(accountRegister) =>
					currentAccountRegister !== accountRegister && !(accountRegister instanceof OptimisticAccountRegister),
			),
		),
	);
}

/**
 * refresh for own user. If you want to update the full account register, use
 * refreshAccountRegister instead.
 *
 * If you pass in an update function, the user (via accountRegister) will update optimistically with it applied
 * and then again once loaded from the server
 */
export async function refreshOwnUser(updateOwnUserOptimistically?: (ownUser: UserAccount) => UserAccount) {
	const accountRegister = await refreshAccountRegister(
		updateOwnUserOptimistically == null
			? undefined
			: (accountRegister) => {
					if (accountRegister.user == null) {
						throw new Error("No current user!");
					}
					return {
						...accountRegister,
						user: updateOwnUserOptimistically(accountRegister.user),
					};
				},
	);
	return accountRegister.user;
}

// don't auto-run in tests or on the server

if (process.env.NODE_ENV !== "test" && typeof window !== "undefined") {
	accountRegisterObservable.subscribe(accountRegisterBehavior);
}
