import { SvgIcon, SvgIconProps } from "@mui/material";

export default function MessageSquareDotsIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRMessageSquareDotsIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 0H22V18H10.2868L5 21.3042V18H0V0ZM6 8H8.01V10H6V8ZM12.01 8H10V10H12.01V8ZM16.01 8H14V10H16.01V8Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
