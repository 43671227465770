import { SvgIcon, SvgIconProps } from "@mui/material";

export default function QuestionSquareIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRQuestionSquareIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20 0H0V20H20V0ZM8.24363 7.32561C8.44 6.56264 9.13378 6 9.95644 6C10.9331 6 11.7249 6.79177 11.7249 7.76847C11.7249 8.15054 11.5857 8.42462 11.1788 8.82048C10.9788 9.01501 10.7454 9.20868 10.4534 9.45103L10.3646 9.5248C10.0425 9.79235 9.67257 10.1037 9.29737 10.4742L8.58586 11.1769L9.9912 12.6L10.7027 11.8973C11.0123 11.5915 11.3254 11.3267 11.6426 11.0631L11.7374 10.9845C12.0161 10.7535 12.3129 10.5074 12.5733 10.2541C13.1865 9.65765 13.7249 8.89935 13.7249 7.76847C13.7249 5.6872 12.0377 4 9.95644 4C8.19912 4 6.72504 5.20194 6.30675 6.8271L6.0575 7.79553L7.99437 8.29405L8.24363 7.32561ZM10.98 14H8.97001V16H10.98V14Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
