import useIsBlurred from "@hooks/useIsBlurred";
import { ProfilePictureType } from "@lib/util/getProfilePictures";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material";
import { useTranslation } from "next-i18next";
import PhotoUpdateMenu, { usePhotoUpdateMenuState } from "./PhotoUpdateMenu";
import ProfilePhotoLoader from "./ProfilePhotoLoader";

const ImageButton = styled("button")(({ theme }) => ({
	background: "none",
	border: "none",
	borderRadius: theme.spacing(0.5),
	overflow: "hidden",
	padding: 0,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	aspectRatio: "1 / 1",
	flex: "1 1 25%",
	position: "relative",
}));

const StyledEditIcon = styled(EditIcon)(({ theme }) => ({
	position: "absolute",
	top: 0,
	right: 0,
	color: theme.palette.common.white,
	filter: "drop-shadow(0px 0px 2px rgb(0 0 0 / 1))",
}));

type GalleryPhotoButtonProps = {
	photo: ProfilePictureType;
};

export default function GalleryPhotoButton({ photo }: GalleryPhotoButtonProps) {
	const { t } = useTranslation("common");
	const { menuState, onOpenMenu, rootRef } = usePhotoUpdateMenuState();
	const isBlurred = useIsBlurred();
	return (
		<>
			<ImageButton ref={rootRef} onClick={onOpenMenu}>
				{
					// this should get photo caption
					photo.render(t("profile.photo_picker_label"), undefined, isBlurred)
				}
				{photo.processing ? <ProfilePhotoLoader /> : <StyledEditIcon />}
			</ImageButton>
			<PhotoUpdateMenu isPinned={photo.pinned} imageUuid={photo.uuid} menuState={menuState} />
		</>
	);
}
