import Dialog from "@components/Dialog";
import { useTranslation } from "next-i18next";
import AnonFormLinks from "../../AnonFormLinks";
import BirthdateForm from "./BirthdateForm";

export default function AnonBirthdateForm() {
	const { t } = useTranslation("common");

	return (
		<Dialog
			title={t("lurker_wizard.request_birthdate_title")}
			subtitle={t("lurker_wizard.request_birthdate_helper")}
			open={true}
		>
			<BirthdateForm />
			<AnonFormLinks />
		</Dialog>
	);
}
