import useIdify from "@hooks/useIdify";
import { alpha, Box, Button, Typography } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { FieldInputProps, FieldMetaState } from "react-final-form";
import { MultiSelectConfig } from "./configTypes";
import MultiSelectDialog from "./MultiSelectDialog";

type MultiSelectFieldProps<T> = {
	input: FieldInputProps<T>;
	meta: FieldMetaState<T>;
	config: MultiSelectConfig;
};

export default function MultiSelectField({ input, config }: MultiSelectFieldProps<string[]>) {
	const { label, sectionLabel, empty_value, options, disableFilter = false, maxSelectionSize = null } = config;
	const { t } = useTranslation("common");
	const idify = useIdify();

	const [open, setOpen] = useState(false);
	const [currentValueSet, setCurrentValueSet] = useState(() => new Set(input.value));
	const [filter, setFilter] = useState("");

	const fieldLabelId = idify("field_label");
	const sectionId = idify("section");

	function onOpen() {
		setOpen(true);
		setCurrentValueSet(new Set(input.value));
		setFilter("");
	}
	function onClose() {
		setOpen(false);
	}
	return (
		<>
			{sectionLabel == null ? null : (
				<p hidden id={sectionId}>
					{sectionLabel}
				</p>
			)}
			<Box
				onClick={onOpen}
				sx={{
					margin: 0,
					display: "flex",
					justifyContent: "space-between",
					padding: "0 0 0 14px",
					cursor: "pointer",
					"&:hover": {
						backgroundColor: (theme) => alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
					},
				}}
			>
				<Box>
					<Typography
						id={fieldLabelId}
						aria-describedby={sectionId}
						variant="caption"
						sx={{
							color: (theme) => theme.palette.text.secondary,
						}}
					>
						{label}
					</Typography>
					{input.value.length === 0 ? (
						<Typography
							aria-describedby={clsx(sectionId, fieldLabelId)}
							data-testid="multi-select-value"
							fontStyle="italic"
						>
							{empty_value ?? t("multi_select_dialog.empty_value")}
						</Typography>
					) : (
						<div data-testid="multi-select-value">
							{config.renderValue(
								input.value.map((value: string) => ({ tag: value, translatedValue: options[value], isMatch: false })),
								clsx(sectionId, fieldLabelId),
							)}
						</div>
					)}
				</Box>
				<Button
					onFocus={input.onFocus}
					onBlur={input.onBlur}
					onClick={onOpen}
					aria-describedby={clsx(sectionId, fieldLabelId)}
					sx={{
						"&:hover": {
							backgroundColor: "transparent",
						},
					}}
				>
					{t("edit_button")}
				</Button>
			</Box>
			<MultiSelectDialog
				open={open}
				onClose={onClose}
				onChange={input.onChange}
				config={config}
				currentValueSet={currentValueSet}
				setCurrentValueSet={setCurrentValueSet}
				disableFilter={disableFilter}
				maxSelectionSize={maxSelectionSize}
				filter={filter}
				setFilter={setFilter}
			/>
		</>
	);
}
