import registerForPushNotifications from "@lib/util/registerForPushNotifications";
import { Button, ListItem } from "@mui/material";
import { useTranslation } from "next-i18next";

export default function RegisterForPushNotificationsListItem() {
	const { t } = useTranslation("common");
	return (
		<ListItem>
			<Button variant="contained" onClick={registerForPushNotifications} fullWidth>
				{t("account_settings.allow_push_notifications_button")}
			</Button>
		</ListItem>
	);
}
