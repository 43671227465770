import { postUserSettings, userSettingsPath } from "@api/profile";
import { refreshOwnUser } from "@feature/accountRegisterModel";
import useAccount from "@hooks/useAccount";
import useGlobalLoader from "@hooks/useGlobalLoader";
import useIdify from "@hooks/useIdify";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import { LocaleCode } from "@lib/models";
import assertType from "@lib/util/assertType";
import languages from "@lib/util/languages";
import { getLocationHref, locationAssign } from "@lib/util/locationWrapper";
import reportError from "@lib/util/reportError";
import { Button, Menu, MenuItem } from "@mui/material";
import { useTranslation } from "next-i18next";
import i18next from "next-i18next.config.mjs";
import { useRef, useState } from "react";
import { useSWRConfig } from "swr";
import SettingsListItem from "../SettingsComponents/SettingsListItem";

const locales = i18next.i18n.locales.slice(1);

export default function UpdateLanguageSettingsListItem() {
	const { t } = useTranslation("common");
	const { mutate } = useSWRConfig();
	const updateAlert = useUpdateAlert();
	const idify = useIdify();
	const localeCode = assertType(useAccount()).locale;
	const currentUrl = getLocationHref();
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const [updating, setUpdating] = useState(false);
	useGlobalLoader(updating, "UpdateLanguageSettingsListItem updating");

	async function updateLocale(newLocaleCode: LocaleCode) {
		setUpdating(true);

		try {
			await postUserSettings({ account_settings: { locale: newLocaleCode } });

			mutate(userSettingsPath);
			await refreshOwnUser((ownUser) => ({ ...ownUser, locale: newLocaleCode }));
		} finally {
			setUpdating(false);
		}
	}

	async function handleClickMenuItem(newLocaleCode: LocaleCode) {
		setMenuOpen(false);

		if (newLocaleCode !== localeCode) {
			try {
				await updateLocale(newLocaleCode);
			} catch (error) {
				reportError(error as Error);
				updateAlert(t("unknown_error"));
				return;
			}
		}

		const currentPathname = new URL(currentUrl).pathname;
		const newPathname = currentPathname.replace(new RegExp(`^/(${languages.join("|")})(?=(/|$))`), `/${newLocaleCode}`);
		if (newPathname !== currentPathname) {
			locationAssign(newPathname);
		}
	}

	const menuButtonLabelId = idify("language-menu-button-label");
	const languageMenuButtonId = idify("language-menu-button");
	const languageMenuId = idify("langauage-menu");
	return (
		<>
			<SettingsListItem
				title={t("account_settings.language_label")}
				subtitle={t(`languages.${localeCode}`)}
				labelId={menuButtonLabelId}
				divider
			>
				<Button
					ref={buttonRef}
					id={languageMenuButtonId}
					aria-controls={menuOpen ? languageMenuId : undefined}
					aria-haspopup="true"
					aria-expanded={menuOpen ? "true" : undefined}
					aria-describedby={menuButtonLabelId}
					onClick={() => {
						setMenuOpen(true);
					}}
					disabled={updating}
					size="small"
				>
					{t("update_button")}
				</Button>
			</SettingsListItem>
			<Menu
				id={languageMenuId}
				anchorEl={buttonRef.current}
				open={menuOpen}
				onClose={() => {
					setMenuOpen(false);
				}}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
			>
				{locales.map((localeOption) => {
					return (
						<MenuItem
							key={localeOption}
							selected={localeOption === localeCode}
							onClick={() => handleClickMenuItem(localeOption as LocaleCode)}
						>
							{t(`languages_label.${localeOption}`)}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
}
