import AppLayout from "@components/AppLayout";
import AlertContainer from "@components/AppLayout/AlertContainer";
import useIsAnon from "@hooks/useIsAnon";
import useRegionBlocked from "@hooks/useRegionBlocked";
import { Box } from "@mui/material";
import { useMatch } from "react-router-dom";
import AnonMapToast from "../anon/AnonMapToast";
import NotificationViewer from "../notification/NotificationViewer";
import MapView from "./MapView";
import PreciseLocation from "./PreciseLocation";
import RegionBlockedMapView from "./RegionBlockedMapView";
import useMapStatus from "./useMapStatus";

export default function MapLayout() {
	const mapRendered = useMapStatus();
	const isAnon = useIsAnon();
	const isMapRoute = useMatch("/map") != null;
	const isRegionBlocked = useRegionBlocked();

	return (
		<AppLayout>
			<Box
				component="main"
				sx={{
					flex: "1 1 auto",
					display: "flex",
					flexDirection: "column",
					alignItems: "stretch",
					justifyContent: "stretch",
				}}
			>
				<Box sx={{ position: "absolute", inset: 0, bottom: "56px" }}>
					{isRegionBlocked ? <RegionBlockedMapView /> : <MapView />}
				</Box>
				{(() => {
					if (isAnon) {
						return (
							<AlertContainer>
								<AnonMapToast />
							</AlertContainer>
						);
					}
					if (mapRendered && isAnon === false) {
						return <NotificationViewer />;
					}
					return null;
				})()}
				{isMapRoute && !isRegionBlocked ? <PreciseLocation /> : null}
			</Box>
		</AppLayout>
	);
}
