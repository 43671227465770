import { ListItem, ListItemSecondaryAction, ListItemText, SxProps, Theme, Typography } from "@mui/material";
import { ReactNode } from "react";
type SettingsListItemProps = {
	title?: string;
	subtitle?: string | ReactNode;
	labelId?: string;
	divider?: boolean;
	listItemSx?: SxProps<Theme>;
	children?: ReactNode;
};
export default function SettingsListItem({
	title,
	subtitle,
	labelId,
	divider = false,
	children,
	listItemSx,
}: SettingsListItemProps) {
	return (
		<ListItem divider={divider} sx={listItemSx}>
			<ListItemText
				primary={
					<Typography id={labelId} variant="body2" sx={{ color: "text.secondary" }}>
						{title}
					</Typography>
				}
				secondary={subtitle ? <Typography variant="body1">{subtitle}</Typography> : null}
			/>
			<ListItemSecondaryAction>{children}</ListItemSecondaryAction>
		</ListItem>
	);
}
