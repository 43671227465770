import useIdify from "@hooks/useIdify";
import addToSet from "@lib/util/addToSet";
import deleteFromSet from "@lib/util/deleteFromSet";
import { Chip, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import ChipList from "./ChipList";
import { MultiSelectConfig } from "./configTypes";

type SelectOptionsListProps = {
	config: MultiSelectConfig;
	currentValueSet: Set<string>;
	setCurrentValueSet: (update: (prev: Set<string>) => Set<string>) => void;
	maxSelectionSize: number | null;
	filter: string;
};

export default function SelectOptionsList({
	config,
	currentValueSet,
	setCurrentValueSet,
	maxSelectionSize,
	filter,
}: SelectOptionsListProps) {
	const { t } = useTranslation("common");
	const idify = useIdify();

	const hasMax = maxSelectionSize != null;
	const limitReached = hasMax ? currentValueSet.size >= maxSelectionSize : false;

	const optionsList = useMemo(() => {
		return Object.entries(config.options).sort((a, b) => a[1].localeCompare(b[1]));
	}, [config.options]);

	return (
		<>
			<span className="sr-only" id={idify("select_list_description")}>
				{t("multi_select_dialog.options_list_screen_reader_description")}
			</span>
			{limitReached ? (
				<Typography variant="body2" sx={{ color: "text.secondary" }} id={idify("limit_reached_helper")}>
					{t("multi_select_dialog.max_selected_helper", { maximum: maxSelectionSize })}
				</Typography>
			) : null}
			<ChipList aria-describedby={idify("select_list_description")}>
				{optionsList.map(([value, label]) => {
					let included = true;
					if (filter !== "") {
						included = label.toLocaleLowerCase().includes(filter);
					}
					const active = currentValueSet.has(value);
					return (
						<li key={value}>
							<Chip
								sx={
									included === false
										? {
												"&.Mui-disabled": {
													opacity: ".1",
												},
											}
										: undefined
								}
								aria-hidden={!included}
								component="button"
								label={label}
								variant={active ? "filled" : "outlined"}
								color={active ? "primary" : "default"}
								size="small"
								aria-pressed={active}
								aria-describedby={idify("limit_reached_helper")}
								disabled={!included || (limitReached && !active)}
								onClick={() => {
									setCurrentValueSet((current) => {
										if (active) {
											return deleteFromSet(current, value);
										}
										return addToSet(current, value);
									});
								}}
							/>
						</li>
					);
				})}
			</ChipList>
		</>
	);
}
