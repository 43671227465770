import useAccount from "@hooks/useAccount";
import useLocale from "@hooks/useLocale";
import { LocaleCode, UserAccount } from "@lib/models";
import { useEffect, useState } from "react";
import { getChatClient, subscribe } from "./chatClientSingleton";

function getChatClientWithUser(user: UserAccount | undefined, locale: LocaleCode) {
	if (user == null) {
		return null;
	}
	return getChatClient(user.uuid, locale);
}

export default function useChatClient() {
	const user = useAccount();
	const locale = useLocale();
	const [chatClientState, setChatClientState] = useState(getChatClientWithUser(user, locale));

	useEffect(() => {
		return subscribe(() => {
			setChatClientState(getChatClientWithUser(user, locale));
		});
	});

	return chatClientState;
}
