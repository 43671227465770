import DialogButton from "@components/DialogButton";
import useIdify from "@hooks/useIdify";
import useUserSettings from "@hooks/useUserSettings";
import { useTranslation } from "next-i18next";
import SettingsListItem from "../SettingsComponents/SettingsListItem";
import EditEmailDialog from "./EditEmailDialog";

export default function EditEmailSettingsListItem() {
	const { t } = useTranslation("common");
	const userSettings = useUserSettings();
	const idify = useIdify();
	const emailLabelId = idify("email_address");
	return (
		<SettingsListItem
			title={t("account_settings.email_address")}
			subtitle={userSettings?.email}
			labelId={emailLabelId}
			divider
		>
			<DialogButton
				aria-describedby={emailLabelId}
				buttonProps={{ size: "sm", variant: "primary" }}
				renderDialog={(open, onClose) => {
					return <EditEmailDialog open={open} onClose={onClose} currentEmail={userSettings?.email} />;
				}}
				dialogRoutingName="edit-email"
			>
				{t("edit_button")}
			</DialogButton>
		</SettingsListItem>
	);
}
