import i18n from "i18next";
import ICU from "i18next-icu";
import { ReactNode } from "react";
// eslint-disable-next-line no-restricted-imports
import { I18nextProvider, initReactI18next } from "react-i18next";
import common from "../../public/locales/en/common.json";

// Type failures: Unknown type failure when importing i18next, TS is finding
// the i18next.d.ts file, and instead of extendeding i18next, it's replacing it
// with the types there. So in this file, `i18n.use` is undefined, as is the import
// and it is missing properties expected by I18nextProvider.
// The types don't leak out of this file, so overriding them is isolated to here.

i18n
	.use(initReactI18next)
	.use(ICU)
	.init({
		lng: "en",
		fallbackLng: "en",
		ns: ["common"],
		defaultNS: "common",
		debug: false,
		interpolation: {
			escapeValue: false, // not needed for react!!
		},
		resources: { en: { common } },
	});

export default function TranslationWrapper({ children }: { children: ReactNode }) {
	return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}
