import { SvgIcon, SvgIconProps } from "@mui/material";

export default function UserBlockIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRUserBlockIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 21V20V18V17H11H13H14V18V20V21H13H11H10ZM10 14V13V11V10H11H13H14V11V13V14H13H11H10ZM10 7L10 6V4V3H11H13H14V4V6V7H13H11H10Z"
				fill="currentColor"
			/>
			<path
				d="M7 6C7 3.23858 9.23858 1 12 1C14.7614 1 17 3.23858 17 6C17 8.76142 14.7614 11 12 11C9.23858 11 7 8.76142 7 6Z"
				fill="currentColor"
			/>
			<path
				d="M2 22C2 16.4772 6.47715 12 12 12C13.823 12 15.5321 12.4878 17.0039 13.3401C14.6715 14.1626 13 16.3861 13 19C13 20.5367 13.5777 21.9385 14.5278 23H2V22Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19 15C16.7909 15 15 16.7909 15 19C15 21.2091 16.7909 23 19 23C21.2091 23 23 21.2091 23 19C23 16.7909 21.2091 15 19 15ZM17 19C17 17.8954 17.8954 17 19 17C19.1792 17 19.3528 17.0236 19.518 17.0677L17.0677 19.518C17.0236 19.3528 17 19.1792 17 19ZM18.482 20.9323L20.9323 18.482C20.9764 18.6472 21 18.8208 21 19C21 20.1046 20.1046 21 19 21C18.8208 21 18.6472 20.9764 18.482 20.9323Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
