import { BadResetToken, resetPassword } from "@api/authentication";
import CardContent from "@components/CardContent";
import Link from "@components/Link";
import FormCard from "@components/card/FormCard";
import FormCardHeader from "@components/card/FormCardHeader";
import { RenderableAlertType, updateAlertType } from "@lib/feature/alert/types";
import { getLocationHash, locationReload } from "@lib/util/locationWrapper";
import { makeHandleFormSubmit } from "@lib/util/makeHandleFormSubmit";
import { Box, Button } from "@mui/material";
import createDecorator from "final-form-focus";
import { useTranslation } from "next-i18next";
import { Form } from "react-final-form";
import PasswordField from "../../components/PasswordField";
import { saveSuccessToPasswordSessionStorage } from "./passwordStatusStorage";

type ResetPasswordFormValues = {
	password: string;
};

type ResetPasswordFormProps = {
	updateAlert: updateAlertType;
	onExpired: () => void;
	expired: boolean;
	alert: RenderableAlertType | null;
};

const focusOnError = createDecorator<ResetPasswordFormValues>();

export default function ResetPasswordForm({ expired, updateAlert, onExpired, alert }: ResetPasswordFormProps) {
	const { t } = useTranslation("common");
	const onSubmit = makeHandleFormSubmit<ResetPasswordFormValues>({
		submitter: async (values: ResetPasswordFormValues) => {
			await resetPassword({
				key: decodeURIComponent(getLocationHash().slice(1)),
				password: values.password,
			});
			saveSuccessToPasswordSessionStorage();
			locationReload();
			return undefined;
		},
		updateAlert,
		successMessage: null,
		unknownErrorMessage: t("reset_password.unknown_error"),
		errorHandlers: [
			{
				errorType: BadResetToken,
				result: () => {
					onExpired();
					return t("reset_password.bad_reset_token");
				},
			},
		],
	});

	return (
		<Form
			onSubmit={onSubmit}
			decorators={[focusOnError]}
			render={({ handleSubmit, hasValidationErrors, submitting }) => {
				return (
					<FormCard component="form" noValidate={true} onSubmit={handleSubmit} alert={alert}>
						<FormCardHeader title={t("reset_password.form_title")} subheader={t("reset_password.form_subtitle")} />
						<CardContent>
							<PasswordField autoComplete="off" />
							<Button
								type="submit"
								disabled={submitting}
								fullWidth
								variant={hasValidationErrors ? "outlined" : "contained"}
							>
								{t("reset_password.reset_password_button")}
							</Button>
							<Box
								sx={{
									display: "flex",
									flexWrap: "wrap",
									gap: 1,
								}}
							>
								<Link to="/login" variant="body2">
									{t("reset_password.return_to_login_link")}
								</Link>
								{expired ? (
									<Link to="/forgot-password" variant="body2">
										{t("reset_password.forgot_password_link")}
									</Link>
								) : null}
							</Box>
						</CardContent>
					</FormCard>
				);
			}}
		/>
	);
}
