import { useEventCallback } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function useNavModalState(queryPropertyName: string) {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const open = searchParams.get(queryPropertyName) === "true";

	const setOpen = useEventCallback(function setOpen(nextState: boolean) {
		if (!open && nextState) {
			setSearchParams((prev) => {
				const params = new URLSearchParams(prev);
				params.append(queryPropertyName, "true");
				return params;
			});
		} else if (open && !nextState) {
			navigate(-1);
		}
	});

	return {
		open,
		setOpen,
	};
}
