import { styled } from "@mui/material";
import { ReactNode } from "react";

const PaneMediaContainer = styled("div")({
	width: "calc(100% + 48px)",
	margin: "-16px -24px 0px",
	"> *": {
		width: "100%",
	},
});

export type PaneMediaProps = {
	children: ReactNode;
};

export default function PaneMedia({ children }: PaneMediaProps) {
	return <PaneMediaContainer>{children}</PaneMediaContainer>;
}
