import { Button } from "@components/Button";
import Dialog from "@components/Dialog";
import useObservable from "@hooks/useObservable";
import { DialogActions } from "@mui/material";
import { useTranslation } from "next-i18next";
import { distinctUntilChanged, map } from "rxjs";
import { itmcPostModelObservable } from "./itmcPostModel";
import { backAction } from "./postModelAction/backAction";
import { closeAction } from "./postModelAction/closeAction";

const sliceObservable = itmcPostModelObservable
	.pipe(
		map((model) => ({
			isAddTextWithMediaBackAction: model.confirmation === "back" && model.view === "text input" && model.media != null,
			showConfirmation: model.confirmation !== false,
		})),
	)
	.pipe(
		distinctUntilChanged(
			(prev, next) =>
				prev.isAddTextWithMediaBackAction === next.isAddTextWithMediaBackAction &&
				prev.showConfirmation === next.showConfirmation,
		),
	);

export default function PostCloseConfirmation() {
	const { t } = useTranslation("common");

	const slice = useObservable(sliceObservable);

	if (slice == null) {
		return null;
	}

	const { isAddTextWithMediaBackAction, showConfirmation } = slice;

	return (
		<Dialog
			open={showConfirmation}
			title={t("in_the_moment.destructive_confirmation_dialog_header")}
			subtitle={
				isAddTextWithMediaBackAction
					? t("in_the_moment.confirm_back_dialog_message")
					: t("in_the_moment.confirm_close_dialog_message")
			}
		>
			<DialogActions>
				<Button
					size="lg"
					variant="primary ghost"
					onClick={() => {
						backAction();
					}}
				>
					{t("cancel_button")}
				</Button>
				<Button
					size="lg"
					variant="primary"
					onClick={() => {
						closeAction();
					}}
				>
					{isAddTextWithMediaBackAction ? t("in_the_moment.go_back_button") : t("close_button")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
