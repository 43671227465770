import useIdify from "@hooks/useIdify";
import useUserSettings from "@hooks/useUserSettings";
import { ListItem, ListItemText } from "@mui/material";
import { useTranslation } from "next-i18next";
import NotificationsInput from "./NotificationsInput";

export default function EditNotificationsSettingsListItem() {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const userSettings = useUserSettings();
	const notificationsLabelId = idify("account_settings");
	return (
		<ListItem
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<ListItemText id={notificationsLabelId} primary={t("email_notifications.notification_frequency_label")} />
			{/* ListItemSecondaryAction is what MUI expects you to use here, but the
											implementation for it uses absolute positioning + transform to position it
											centered on the right, and the transform breaks fixed positioning for any children,
											which we need for the Loader component. Instead we're using flex on the parent with
											space-between. */}
			<NotificationsInput id={notificationsLabelId} checked={userSettings?.email_notifications} />
		</ListItem>
	);
}
