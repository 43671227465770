import BlockReportMenu from "@components/BlockReportMenu";
import { LinkButton } from "@components/Button";
import { OtherUser } from "@lib/models";
import { Box } from "@mui/material";
import { useTranslation } from "next-i18next";
import MessageSquareLinesIcon from "../icons/MessageSquareLinesIcon";

type FriendBottomActionsProps = {
	profile: OtherUser;
	hideChatButton: boolean;
	onBlock: (successMessage: string) => void;
};

export default function FriendBottomActions({ profile, hideChatButton, onBlock }: FriendBottomActionsProps) {
	const { t } = useTranslation("common");
	return (
		<>
			<BlockReportMenu
				profile={profile}
				onBlock={onBlock}
				source="profile"
				popinDirectionX="right"
				menuDirection="up"
			/>
			<Box sx={{ flex: "1 1 auto" }} />
			{hideChatButton ? null : (
				<LinkButton variant="primary" size="md" aria-label={t("chat_button")} to={`/chat/${profile.uuid}`}>
					<MessageSquareLinesIcon />
					{t("chat_button")}
				</LinkButton>
			)}
		</>
	);
}
