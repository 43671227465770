import MessageSquareDotsIcon from "@components/icons/MessageSquareDotsIcon";
import useLocale from "@hooks/useLocale";
import getSupportLinks from "@lib/util/getSupportLinks";
import { MenuItem } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";

export default function AppMenuFeedbackLink({ onClose }: { onClose: () => void }) {
	const locale = useLocale();
	const { t } = useTranslation("common");

	return (
		<MenuItem component="a" href={getSupportLinks("feedback", locale)} target="_blank" onClick={onClose}>
			<MessageSquareDotsIcon />
			<Trans t={t} i18nKey={"app_bar.feedback_link"} components={[<span className="sr-only" key="0" />]} />
		</MenuItem>
	);
}
