import { logout } from "@api/authentication";
import Direct from "@components/Direct";
import { accountRegisterBehavior } from "@feature/accountRegisterModel";
import useLocale from "@hooks/useLocale";
import { clearLocationShownStorage } from "@hooks/useLocation/locationShownStorage";
import useObservable from "@hooks/useObservable";
import { AuthProvider } from "@lib/auth/authContext";
import { clearNotificationStorage } from "@lib/feature/notification/storage";
import { Authentication, UserAccount } from "@lib/models";
import { identify, setUserProperties, unIdentify } from "@lib/util/trackAnalytics";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";

const defaultLoginLanding = "/";

function AuthenticationProvider({ children }: { children: ReactNode }) {
	const accountRegister = useObservable(accountRegisterBehavior);
	const locale = useLocale();
	const loginRedirectedFrom = useRef<string | undefined>(undefined);
	const [directing, setDirecting] = useState<{ href: string; replace: boolean } | null>(null);
	const userRef = useRef<UserAccount | undefined>();

	useEffect(() => {
		if (accountRegister?.user != null) {
			identify(accountRegister.user);
		} else if (userRef.current != null) {
			// User was authenticated but no longer is
			setDirecting({ href: "/", replace: false });
		}

		userRef.current = accountRegister?.user;
	}, [accountRegister]);

	useEffect(() => {
		setUserProperties(locale);
	}, [locale]);

	const auth: Authentication = useMemo(() => {
		return {
			isUser: accountRegister?.user != null,
			onLogin() {
				clearNotificationStorage();
				// previously called swr mutate, but since the page is refreshing we don't need to.
				setDirecting({
					href: loginRedirectedFrom.current ?? defaultLoginLanding,
					replace: false,
				});
			},
			onRedirectToLogin(path?: string) {
				loginRedirectedFrom.current = path;
			},
			async onLogout() {
				await logout();
				clearLocationShownStorage();
				unIdentify();
				setDirecting({ href: "/", replace: false });
			},
		};
	}, [accountRegister]);
	if (directing != null) {
		return <Direct {...directing} />;
	}
	return <AuthProvider value={auth}>{children}</AuthProvider>;
}

export default AuthenticationProvider;
