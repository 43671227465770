import { deleteInTheMomentStatus } from "@api/profile";
import Alert from "@components/Alert";
import { Button } from "@components/Button";
import { refreshOwnUser } from "@feature/accountRegisterModel";
import useGlobalLoader from "@hooks/useGlobalLoader";
import useUser from "@hooks/useUser";
import * as feedModel from "@lib/feature/Feed/feedModel";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import useAlertState from "@lib/feature/alert/useAlertState";
import { UserAccount } from "@lib/models";
import assertType from "@lib/util/assertType";
import { DialogActions } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import CurrentStatus from "./CurrentStatus";
import InTheMomentForm from "./InTheMomentForm";

type ManagerProps = {
	onClose: () => void;
	inTheMoment: NonNullable<UserAccount["in_the_moment_status"]>;
	onOpenAnonRegister: () => void;
};

export default function Manager({ onClose, inTheMoment, onOpenAnonRegister }: ManagerProps) {
	const { t } = useTranslation("common");
	const user = assertType(useUser());
	const [replaceActive, setReplaceActive] = useState(false);
	const [alert, localUpdateAlert] = useAlertState();
	const updateAlert = useUpdateAlert();
	const [deleting, setDeleting] = useState(false);
	useGlobalLoader(deleting, "ITMManager deleting");
	if (replaceActive) {
		return (
			<InTheMomentForm
				onClose={(reason) => {
					setReplaceActive(false);
					if (reason === "success") {
						onClose();
					}
				}}
				onOpenAnonRegister={onOpenAnonRegister}
			/>
		);
	}
	return (
		<>
			<p>{t("in_the_moment.current_dialog_subtitle")}</p>
			<CurrentStatus
				inTheMoment={inTheMoment}
				deleting={deleting}
				onDelete={async () => {
					setDeleting(true);
					try {
						await deleteInTheMomentStatus();
						await refreshOwnUser();
						updateAlert(t("in_the_moment.status_deleted_message"), "success");
						onClose();
						feedModel.removeFeedItem(user.uuid);
					} catch {
						localUpdateAlert(t("unknown_error"));
					} finally {
						setDeleting(false);
					}
				}}
			/>
			{alert == null ? null : (
				<Alert
					key={alert.key}
					severity={alert.severity}
					body={alert.children}
					onClose={() => {
						localUpdateAlert();
					}}
				/>
			)}
			<DialogActions>
				<Button
					variant="primary"
					onClick={() => {
						setReplaceActive(true);
					}}
				>
					{t("replace_button")}
				</Button>
			</DialogActions>
		</>
	);
}
