import FormCardHeader from "@components/card/FormCardHeader";
import CardContent from "@components/CardContent";
import { Card } from "@mui/material";
import { ReactNode } from "react";

type ProfileFormSectionProps = {
	heading: string;
	children: ReactNode;
	headingId?: string;
};

export default function ProfileFormSection({ heading, children, headingId }: ProfileFormSectionProps) {
	return (
		<Card variant="outlined" sx={{ overflow: "unset" }}>
			<FormCardHeader headingId={headingId} title={heading} />
			<CardContent>{children}</CardContent>
		</Card>
	);
}
