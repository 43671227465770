import { CardHeader, CardHeaderProps } from "@mui/material";

export default function FormCardHeader({ headingId, ...props }: CardHeaderProps & { headingId?: string }) {
	return (
		<CardHeader
			{...props}
			titleTypographyProps={{
				id: headingId,
				component: "h2",
				...props.titleTypographyProps,
			}}
			subheaderTypographyProps={{
				variant: "body2",
				...props.subheaderTypographyProps,
			}}
		/>
	);
}
