import { AxiosError } from "axios";
import { post } from "../httpClient";
import { ServerFormError } from "./commonErrors";

export const userRegionSubscriptionsPath = "/user_region_subscriptions";

type UserRegionSubscriptionRequest = {
	user_region_subscription: { email: string };
};

export async function subscribeToRegionBlockedEmails(body: UserRegionSubscriptionRequest): Promise<void> {
	return post<UserRegionSubscriptionRequest, void>(userRegionSubscriptionsPath, body).catch((error) => {
		if (error instanceof AxiosError && error.response?.status === 422) {
			if (typeof error.response.data === "object") {
				throw new ServerFormError(error.response.data);
			}
		}
		throw error;
	});
}
